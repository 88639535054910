import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import Trix from 'trix';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { merge, Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, skip, skipUntil, take, throttle } from 'rxjs/operators';

@Component({
  selector: 'app-trix-editor',
  templateUrl: './trix-editor.component.html',
  styleUrls: ['./trix-editor.component.scss'],
  animations: [
    trigger('FadeOut', [
      state('void', style({opacity: 0})),
      state('*', style({opacity: 1})),
      transition(':leave', animate('250ms ease-out')),
      transition(':enter', animate('100ms ease-in'))
    ])
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TrixEditorComponent,
      multi: true
    }
  ]
})
export class TrixEditorComponent implements ControlValueAccessor, OnDestroy {
  _content: string;
  public trixEditor: any;

  showSave = false;
  showSaveTimeout;
  onBlur = new Subject();

  formControlOnChange;
  formControlOnTouched;
  isDisabled = false;

  trixContentChange = new Subject();
  trixContentTouched = new Subject();

  get trixElement() {
    return this._trixElement != null ? this._trixElement.nativeElement : null;
  }

  @ViewChild('trixElement', {static: true, read: ElementRef})
  public _trixElement: ElementRef;

  @Output()
  public changed = new EventEmitter<void>();

  @Input()
  public saveIndicator = true;

  skipOnChange = false;

  private destroyed = false;

  constructor() {
    this.trixContentChange.pipe(filter(_ => {
      if (this.skipOnChange) {
        this.skipOnChange = false;
        return false;
      }
      return true;
    })).subscribe(value => this.formControlOnChange(value));
    this.trixContentChange.pipe(skip(1), distinctUntilChanged()).subscribe(_ => {
      this.trixContentTouched.next();
      this.formControlOnTouched();
    });

    merge(
      this.trixContentChange.pipe(
        skip(1), // skip first, initial value
        distinctUntilChanged(),
        debounceTime(5000)
      ),
      this.onBlur.pipe(
        skipUntil(this.trixContentTouched) // do not trigger onBlur when the content hasn't changed yet
      )
    ).pipe(
      throttle(_ => this.trixContentChange.pipe(take(1)))  // do not trigger again until another change is detected
    ).subscribe(_ => this.changed.emit());

    this.changed.subscribe(_ => {
      this.showSave = true;
      if (this.showSaveTimeout) {
        clearTimeout(this.showSaveTimeout);
      }
      this.showSaveTimeout = setTimeout(() => this.showSave = false, 2000);
    });
  }

  ngOnDestroy() {
    this.destroyed = true;
  }

  onTrixBeforeInitialize($event) {
    this.configureTextAttributes();
  }

  onTrixInitialize($event) {
    this.trixEditor = (this.trixElement as any).editor;
    this.updateEditorContent();
  }

  onTrixChange($event) {
    this.trixContentChange.next(($event.target as any).value);
  }

  onTrixBlur($event) {
    this.onBlur.next();
  }

  onTrixFileAccept($event) {
    $event.preventDefault();
  }

  private updateEditorContent() {
    if (!this.trixEditor || this._content == null || (this.trixElement as any).value === this._content) {
      return;
    }
    const selectionRange = this.trixEditor.getSelectedRange();
    const document = (Trix as any).HTMLParser.parse(this._content, {referenceElement: this.trixElement}).getDocument();
    return this.trixEditor.loadSnapshot({document, selectedRange: selectionRange});
  }

  private configureTextAttributes() {
    Trix.config.blockAttributes.heading3 = {
      tagName: 'h3',
      terminal: true,
      breakOnReturn: true,
      group: false,
    };
    Trix.config.textAttributes.underline = {
      tagName: 'u',
      inheritable: true,
      parser(element) {
        const computedStyle = window.getComputedStyle(element);
        return computedStyle.textDecoration === 'underline';
      },
    };
  }

  registerOnChange(fn: (value: string) => void): void {
    this.formControlOnChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.formControlOnTouched = fn;
  }

  writeValue(value: string): void {
    if (this.destroyed) {
      return;
    }
    this._content = value;
    this.skipOnChange = true;
    this.updateEditorContent();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (!this.trixElement) {
      console.log('TrixElement is not initialized yet');
      return;
    }
    this.trixElement.contentEditable = !isDisabled;
  }
}
