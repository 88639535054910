import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Client} from '../clients/client';
import {Contract} from '../contracts/contract';
import {ClientDocument} from '../client_documents/client_document';

export class SepaMandate {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Mandatsreferenz', prop: 'mandate_reference', type: ColumnType.String, standard: true, order: 0, flexGrow: .4}),
    new Column({name: 'Kontoinhaber', prop: 'bankaccount_owner', type: ColumnType.String, standard: true, order: 2, flexGrow: .7}),
    new Column({name: 'Adresse', prop: 'address', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String}),
    new Column({name: 'IBAN', prop: 'iban', type: ColumnType.String, standard: true, order: 3}),
    new Column({name: 'BIC', prop: 'bic', type: ColumnType.String, standard: true, order: 4, flexGrow: .6}),
    new Column({name: 'signee', prop: 'signee', type: ColumnType.String}),
    new Column({name: 'date_of_signature', prop: 'date_of_signature', type: ColumnType.Date, standard: true, order: 5, flexGrow: .5}),
    new Column({name: 'place_of_signature', prop: 'place_of_signature', type: ColumnType.String}),
    new Column({name: 'expired_at', prop: 'expired_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({
      name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns, standard: true,
      order: 1, standardProps: 'company'
    }),
    new Column({name: 'Vertrag', prop: 'contract', association: 'belongs_to', columns: Contract.allColumns}),
    new Column({name: 'Dokument', prop: 'client_document', association: 'has_one', columns: ClientDocument.allColumns}),
  ];

  // Simple Columns
  id: number;
  client_id: number;
  contract_id: number;
  mandate_reference: string;
  bankaccount_owner: string;
  address: string;
  zip: string;
  city: string;
  iban: string;
  bic: string;
  signee: string;
  date_of_signature: Date;
  place_of_signature: string;
  expired_at: Date;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  // Associations
  client: Client;
  contract: Contract;
  client_document: ClientDocument;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.mandate_reference;
  }
}

export class SepaMandateList {
  count: number;
  data: SepaMandate[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
