import { Injectable } from '@angular/core';
import {Categorization, CategorizationList} from './categorization';
import {BaseService} from '../base/base.service';
import {CategorizationSerializer} from 'src/app/categorizations/categorization.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategorizationService extends BaseService<Categorization, CategorizationList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'categorizations' ;
    this.serializer = new CategorizationSerializer();
  }
}
