import {BaseSerializer} from '../base/base.serializer';
import {Automation} from './automation';
import {UserSerializer} from '../users/user.serializer';
import {DealAutomationSerializer} from '../deal_automations/deal_automation.serializer';
import {MembershipSerializer} from '../memberships/membership.serializer';

export class AutomationSerializer extends BaseSerializer<Automation> {
  fromJson(json: any): Automation {
    const serialized = new Automation(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    if (json.deal_automations) {
      serialized.deal_automations = json.deal_automations.map(x => new DealAutomationSerializer().fromJson(x));
    }
    if (json.membership) {
      serialized.membership = new MembershipSerializer().fromJson(json.membership);
    }
    return serialized;
  }

  toJson(obj: Automation): string {
    let params: any = {automation: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
