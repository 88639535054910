import { Injectable } from '@angular/core';
import {AffiliateCategory, AffiliateCategoryList} from './affiliate_category';
import {BaseService} from '../base/base.service';
import {AffiliateCategorySerializer} from 'src/app/affiliate_categories/affiliate_category.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AffiliateCategoryService extends BaseService<AffiliateCategory, AffiliateCategoryList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'affiliate_categories' ;
    this.serializer = new AffiliateCategorySerializer();
  }
}
