import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Device} from '../devices/device';
import {Card} from '../cards/card';
import {Membership} from '../memberships/membership';
import {Partner} from '../partners/partner';
import {Offer} from '../offers/offer';
import {Transaction} from '../transactions/transaction';
import {Activity} from '../activities/activity';
import {PosSession} from '../pos_sessions/pos_session';
import {MobileSession} from '../mobile_sessions/mobile_session';
import {Message} from '../messages/message';
import {MobileMessage} from '../mobile_messages/mobile_message';
import {AutomationEvent} from '../automation_events/automation_event';

export class User {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .15}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String}),
    new Column({name: 'E-Mail', prop: 'email', type: ColumnType.String, standard: true, order: 1, flexGrow: .7}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 6, flexGrow: .4}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'avatar', prop: 'avatar', type: ColumnType.String}),
    new Column({name: 'birthday', prop: 'birthday', type: ColumnType.Date}),
    new Column({
      name: 'EB (E-Mail bestätigt)', prop: 'email_confirmed_at', type: ColumnType.Boolean, standard: true, order: 4,
      flexGrow: .05
    }),
    new Column({name: 'email_confirmation_token', prop: 'email_confirmation_token', type: ColumnType.String}),
    new Column({name: 'otc', prop: 'otc', type: ColumnType.String}),
    new Column({name: 'otc_created_at', prop: 'otc_created_at', type: ColumnType.DateTime}),
    new Column({name: 'gender', prop: 'gender', type: ColumnType.Number}),
    new Column({name: 'role', prop: 'role', type: ColumnType.String}),
    new Column({name: 'App-Nutzung', prop: 'uses_app?', type: ColumnType.Boolean}),
    new Column({name: 'Origin', prop: 'origin_data', type: ColumnType.Polymorphic, standard: true, order: 5, flexGrow: .4}),
    new Column({name: 'OT', prop: 'origin_type', type: ColumnType.Custom, flexGrow: 0}),
    new Column({name: 'O#', prop: 'origin_id', type: ColumnType.Custom, flexGrow: 0.4}),
    new Column({name: 'T', prop: 'transactions_count', type: ColumnType.Number, standard: true, order: 3, flexGrow: .15}),
    new Column({name: 'M', prop: 'memberships_count', type: ColumnType.Number, standard: true, order: 2, flexGrow: .15}),
    new Column({name: 'anonymous', prop: 'anonymous', type: ColumnType.Boolean}),
    new Column({name: 'deanonymized_at', prop: 'deanonymized_at', type: ColumnType.DateTime}),
    new Column({name: 'emails_unsubscribed_at', prop: 'emails_unsubscribed_at', type: ColumnType.DateTime})
    // Associated Columns
  ];

  // Simple Columns
  id: number;
  name: string;
  email: string; // Post-processor change, before: email: string;
  password: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  avatar: {
    url: string;
    hw200: { url: string; };
  };
  birthday: Date;
  email_confirmed_at: boolean;
  email_confirmation_token: string;
  otc: string;
  otc_created_at: boolean;
  gender: number;
  role: string;
  uses_app: boolean;
  transactions_count: number;
  memberships_count: number;
  anonymous: boolean;
  deanonymized_at: boolean;
  merged_into_id: number;
  emails_unsubscribed_at: Date;
  // Associations
  device: Device[];
  card: Card[];
  membership: Membership[];
  partner: Partner[];
  offer: Offer[];
  transaction: Transaction[];
  activity: Activity[];
  pos_session: PosSession[];
  mobile_session: MobileSession[];
  message: Message[];
  // email: Email[];
  mobile_message: MobileMessage[];
  automation_event: AutomationEvent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.email;
  }
}

export class UserList {
  count: number;
  data: User[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
