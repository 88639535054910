<app-nav-item [name]="'🚀 Dashboard'" [target]="'dashboard'" tooltip="Dashboard" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['dashboard']" [editMode]="editMode"></app-nav-item>
<app-nav-item [name]="'📍 Kundenbetreuung'" [target]="'business_care'" tooltip="Kundenbetreuung" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['business_care']" [editMode]="editMode" *can="'business_care.show.list'"></app-nav-item>
<app-nav-item [name]="'📍 Standorte'" [target]="'businesses'" tooltip="Standorte" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['businesses']" [editMode]="editMode" *can="'businesses.show.list'"></app-nav-item>
<app-nav-item [name]="'🖥️ Webshops'" [target]="'webshops'" tooltip="Webshops" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['webshops']" [editMode]="editMode" *can="'webshops.show.list'"></app-nav-item>
<app-nav-item [name]="'📄 Rechnungen'"  [target]="'invoices'"   tooltip="Rechnungen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['invoices']" [editMode]="editMode" *can="'invoices.show.list'"></app-nav-item>
<app-nav-item [name]="'⚔️ Mahnungs Prozesse'" [target]="'dunning_processes'" tooltip="Mahnungs Prozesse" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['dunning_processes']" [editMode]="editMode" *can="'dunning_processes.show.list'"></app-nav-item>
<app-nav-item [name]="'💼 Kunden'" [target]="'clients'" tooltip="Kunden" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['clients']" [editMode]="editMode" *can="'clients.show.list'"></app-nav-item>
<app-nav-item [name]="'👤 Benutzer'" [target]="'users'" tooltip="Benutzer" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['users']" [editMode]="editMode" *can="'users.show.list'"></app-nav-item>
<app-nav-item [name]="'🤖 Automations'" [target]="'automations'" tooltip="Dealautomatisierungen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['automations']" [editMode]="editMode" *can="'automations.show.list'"></app-nav-item>
<app-nav-item [name]="'👾 Aktivitäten'" [target]="'activities'" tooltip="Aktivitäten" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['activities']" [editMode]="editMode" *can="'activities.show.list'"></app-nav-item>
<app-nav-item [name]="'🗾 Gebiete'" [target]="'areas'" tooltip="Gebiete" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['areas']" [editMode]="editMode" *can="'areas.show.list'"></app-nav-item>
<app-nav-item [name]="'📢 Kampagnen'" [target]="'campaigns'" tooltip="Kampagnen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['campaigns']" [editMode]="editMode" *can="'campaigns.show.list'"></app-nav-item>
<app-nav-item [name]="'💳 Karten'" [target]="'cards'" tooltip="Karten" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['cards']" [editMode]="editMode" *can="'cards.show.list'"></app-nav-item>
<app-nav-item [name]="'💳 Perso Karten'" [target]="'personalized_card_orders'" tooltip="Perso Karten" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['personalized_card_orders']" [editMode]="editMode" *can="'personalized_card_orders.show.list'"></app-nav-item>
<app-nav-item [name]="'🔣 Kategorien'" [target]="'categories'" tooltip="Kategorien" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['categories']" [editMode]="editMode" *can="'categories.show.list'"></app-nav-item>
<app-nav-item [name]="'📄 Dokumente'" [target]="'client_documents'" tooltip="Dokumente" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['client_documents']" [editMode]="editMode" *can="'client_documents.show.list'"></app-nav-item>
<app-nav-item [name]="'📑 Verträge'" [target]="'contracts'" tooltip="Verträge" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['contracts']" [editMode]="editMode" *can="'contracts.show.list'"></app-nav-item>
<app-nav-item [name]="'🤝 Deals'" [target]="'deals'" tooltip="Deals" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['deals']" [editMode]="editMode" *can="'deals.show.list'"></app-nav-item>
<app-nav-item [name]="'🧧 Gutscheine'" [target]="'coupons'" tooltip="Gutscheine" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['coupons']" [editMode]="editMode" *can="'coupons.show.list'"></app-nav-item>
<app-nav-item [name]="'🤖 Dealautomatisierungen'" [target]="'deal_automations'" tooltip="Dealautomatisierungen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['deal_automations']" [editMode]="editMode" *can="'deal_automations.show.list'"></app-nav-item>
<app-nav-item [name]="'🔌 Geräte'" [target]="'devices'" tooltip="Geräte" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['devices']" [editMode]="editMode" *can="'devices.show.list'"></app-nav-item>
<app-nav-item [name]="'📧 Emails'" [target]="'emails'" tooltip="Emails" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['emails']" [editMode]="editMode" *can="'emails.show.list'"></app-nav-item>
<!--<app-nav-item [name]="'📬 Email-Aktionen'" [target]="'email_events'" tooltip="Email-Aktionen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['email_events']" [editMode]="editMode" *can="'email_events.show.list'"></app-nav-item>-->
<!--<app-nav-item [name]="'💌 Einladungen'" [target]="'invitations'" tooltip="Einladungen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['invitations']" [editMode]="editMode" *can="'invitations.show.list'"></app-nav-item>-->
<app-nav-item [name]="'👑 Mitgliedschaften'" [target]="'memberships'" tooltip="Mitgliedschaften" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['memberships']" [editMode]="editMode" *can="'memberships.show.list'"></app-nav-item>
<app-nav-item [name]="'👔 Händler'" [target]="'merchants'" tooltip="Händler" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['merchants']" [editMode]="editMode" *can="'merchants.show.list'"></app-nav-item>
<app-nav-item [name]="'📰 Nachrichten'" [target]="'messages'" tooltip="Nachrichten" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['messages']" [editMode]="editMode" *can="'messages.show.list'"></app-nav-item>
<app-nav-item [name]="'📲 Mobile Nachrichten'" [target]="'mobile_messages'" tooltip="Mobile Nachrichten" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['mobile_messages']" [editMode]="editMode" *can="'mobile_messages.show.list'"></app-nav-item>
<app-nav-item [name]="'📱 Mobile Sessions'" [target]="'mobile_sessions'" tooltip="Mobile Sessions" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['mobile_sessions']" [editMode]="editMode" *can="'mobile_sessions.show.list'"></app-nav-item>
<app-nav-item [name]="'💝 Angebote'" [target]="'offers'" tooltip="Angebote" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['offers']" [editMode]="editMode" *can="'offers.show.list'"></app-nav-item>
<app-nav-item [name]="'🏢 Partner'" [target]="'partners'" tooltip="Partner" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['partners']" [editMode]="editMode" *can="'partners.show.list'"></app-nav-item>
<!--<app-nav-item [name]="'💬 POS Nachrichten'" [target]="'pos_messages'" tooltip="POS Nachrichten" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['pos_messages']" [editMode]="editMode" *can="'pos_messages.show.list'"></app-nav-item>-->
<app-nav-item [name]="'🛒 POS Sessions'" [target]="'pos_sessions'" tooltip="POS Sessions" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['pos_sessions']" [editMode]="editMode" *can="'pos_sessions.show.list'"></app-nav-item>
<app-nav-item [name]="'🎁 Belohnungen'" [target]="'rewards'" tooltip="Belohnungen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['rewards']" [editMode]="editMode" *can="'rewards.show.list'"></app-nav-item>
<app-nav-item [name]="'🏦 Mandate'" [target]="'sepa_mandates'" tooltip="Mandate" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['sepa_mandates']" [editMode]="editMode" *can="'sepa_mandates.show.list'"></app-nav-item>
<app-nav-item [name]="'🖥 Tablets'" [target]="'tablets'" tooltip="Tablets" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['tablets']" [editMode]="editMode" *can="'tablets.show.list'"></app-nav-item>
<!--<app-nav-item [name]="'🎉 Neukundenangebote'" [target]="'teasers'" tooltip="Neukundenangebote" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['teasers']" [editMode]="editMode" *can="'teasers.show.list'"></app-nav-item>-->
<app-nav-item [name]="'📟 Terminals'" [target]="'terminals'" tooltip="Terminals" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['terminals']" [editMode]="editMode" *can="'terminals.show.list'"></app-nav-item>
<app-nav-item [name]="'🔑 Token'" [target]="'tokens'" tooltip="Token" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['tokens']" [editMode]="editMode" *can="'tokens.show.list'"></app-nav-item>
<app-nav-item [name]="'🗃 Transaktionen'" [target]="'transactions'" tooltip="Transaktionen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['transactions']" [editMode]="editMode" *can="'transactions.show.list'"></app-nav-item>
<!--<app-nav-item [name]="'🚩 Markierte Transaktionen'" [target]="'transaction_flags'" tooltip="Markierte Transaktionen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['transaction_flags']" [editMode]="editMode" *can="'transaction_flags.show.list'"></app-nav-item>-->
<app-nav-item [name]="'📦 Sendungen'" [target]="'shipments'" tooltip="Sendungen" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['shipments']" [editMode]="editMode" *can="'shipments.show'"></app-nav-item>
<app-nav-item [name]="'🎊 POS Experiences'" [target]="'pos_experiences'" tooltip="POS Experiences" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['pos_experiences']" [editMode]="editMode" *can="'pos_experiences.show'"></app-nav-item>
<!-- Affiliate Stuff -->
<app-nav-item [name]="'🛍️ Affiliate Deals'" [target]="'affiliate_deals'" tooltip="Affiliate Deals" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['affiliate_deals']" [editMode]="editMode" *can="'affilaite_deals.show'"></app-nav-item>
<app-nav-item [name]="'🛍️ Affiliate Categories'" [target]="'affiliate_categories'" tooltip="Affiliate Categories" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['affiliate_categories']" [editMode]="editMode" *can="'affiliate_categories.show'"></app-nav-item>
<app-nav-item [name]="'🛍️ Affiliate Merchants'" [target]="'affiliate_merchants'" tooltip="Affiliate Merchants" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['affiliate_merchants']" [editMode]="editMode" *can="'affiliate_merchants.show'"></app-nav-item>
<app-nav-item [name]="'➕ Addon Subs'" [target]="'addon_subscriptions'" tooltip="Addon Subs" placement="right" [base-path]="basePath" [bookmarks]="bookmarks['addon_subscriptions']" [editMode]="editMode" *can="'addon_subscriptions.show'"></app-nav-item>
<!-- GENERATOR-NAV !-->
<app-nav-item [name]="'️🔺 ACP'" [target]="'admins'" tooltip="ACP" placement="right" [base-path]="basePath" *can="'root'"></app-nav-item>
