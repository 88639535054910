import { Injectable } from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {filter, map, take} from 'rxjs/operators';
import {WebshopSerializer} from './webshop.serializer';
import {Webshop, WebshopList} from './webshop';

@Injectable({
  providedIn: 'root'
})
export class WebshopService extends BaseService<Webshop, WebshopList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'webshops' ;
    this.serializer = new WebshopSerializer();
  }

  create(obj: Webshop): Observable<Webshop> {
    return this.http.post(environment.base_url + this.endpoint, this.serializer.toJson(obj)).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  generateNewApiKey(obj: Webshop): Observable<Webshop> {
    return this.http.post(`${environment.base_url}${this.endpoint}/${obj.id}/generate_api_key`, {}).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }
}
