import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';
import {Webshop} from './webshop';
import {BusinessSerializer} from '../businesses/business.serializer';

export class WebshopSerializer extends BaseSerializer<Webshop> {
  fromJson(json: any): Webshop {
    const serialized = new Webshop(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    if (json.business) {
      serialized.business = new BusinessSerializer().fromJson(json.business);
    }
    return serialized;
  }

  toJson(obj: Webshop): string {
    let params: any = {webshop: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
