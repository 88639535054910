/**
 * Helper class to decode and find JWT expiration.
 */

declare let escape: any;

export class JwtHelper {

  public static urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }

    return decodeURIComponent(escape(typeof window === 'undefined' ? atob(output) : window.atob(output)));
  }

  public static decodeToken(token: string) {
    if (!token || token.length === 0) {
      throw new Error('Cannot decode the token');
    }
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    const decoded = JwtHelper.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }

  public static getTokenExpirationDate(token: string) {
    let decoded: any;
    decoded = JwtHelper.decodeToken(token);

    if (typeof decoded.exp === 'undefined') {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public static isTokenExpired(token: string, offsetSeconds?: number) {
    const date = JwtHelper.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }
}
