import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { DunningLevel, Invoice, InvoiceList, InvoiceState } from './invoice';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InvoiceSerializer } from './invoice.serializer';
import { environment } from '../../environments/environment';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Group } from '../filter/group';
import { Sort } from '../shared/models/sort';
import { CustomHttpParamEncoder } from '../base/custom-http-params-encoder';
import { InvoicePreview } from '../clients/invoice-preview';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService<Invoice, InvoiceList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'invoices';
    this.serializer = new InvoiceSerializer();
  }

  fetchAll(fields: string = '', group: Group[] = [], sort: Sort[] = []): Observable<InvoiceList> {
    const grp = JSON.parse(JSON.stringify(group));
    grp.forEach(g => {
      g.filter.forEach(f => {
        if (f.subject === 'state') {
          if (f.object1) {
            const mapping = {other: 0, next: 1, sent: 2, paid: 3, unpaid: 4, lost: 5};
            if (mapping[f.object1]) {
              f.object1 = mapping[f.object1];
            }
          }
        }
        if (f.subject === 'dunning_level') {
          if (f.object1) {
            const mapping = {zero: 0, reminder: 1, first_dunning: 2, second_dunning: 3, collection: 4};
            if (mapping[f.object1]) {
              f.object1 = mapping[f.object1];
            }
          }
        }
      });
    });
    const q = JSON.stringify(this.toRansackPage(grp, sort));
    let params = new HttpParams({encoder: new CustomHttpParamEncoder()})
      .set('per', '999999')
      .set('q', q);
    if (fields) {
      params = params.set('fields', fields);
    }

    return this.http
      .get<InvoiceList>(`${environment.base_url + this.endpoint}`, {params})
      .pipe(
        filter(x => !!x),
        take(1),
        map(res => {
          res.data = res.data.map(obj => this.serializer.fromJson(obj));
          return res;
        }));
  }

  create(invoice: Invoice): Observable<Invoice> {
    return super.create(this.prepareData(invoice));
  }

  update(id: number, invoice: Invoice): Observable<Invoice> {
    return super.update(id, this.prepareData(invoice));
  }

  collectionSendToClient(invoice_ids: number[]): Observable<any> {
    return this.http.post(`${environment.base_url + this.endpoint}/send_to_client`, {ids: invoice_ids.join(',')});
  }

  collectionPDF(invoice_ids: number[]): Observable<Blob> {
    return this.http.get(`${environment.base_url + this.endpoint}/download?ids=${invoice_ids.join(',')}`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/zip'}))
    );
  }

  downloadPDF(invoice_id: number): Observable<Blob> {
    return this.http.get(`${environment.base_url + this.endpoint}/${invoice_id}/download`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/zip'}))
    );
  }

  collectionXML(invoice_ids: number[]): Observable<Blob> {
    return this.http.get(`${environment.base_url + this.endpoint}/sepa?ids=${invoice_ids.join(',')}`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/xml'}))
    );
  }

  downloadXML(invoice_id: number): Observable<Blob> {
    return this.http.get(`${environment.base_url + this.endpoint}/${invoice_id}/sepa`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/xml'}))
    );
  }

  collectionCSV(invoice_ids: number[]): Observable<Blob> {
    return this.http.get(`${environment.base_url + this.endpoint}/csv?ids=${invoice_ids.join(',')}`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'text/csv'}))
    );
  }

  downloadCSV(invoice_id: number): Observable<Blob> {
    return this.http.get(`${environment.base_url + this.endpoint}/${invoice_id}/csv`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'text/csv'}))
    );
  }

  copyOver(invoice: Invoice) {
    return this.http.post(`${environment.base_url + this.endpoint}/${invoice.id}/copy`, {}).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  sendMail(invoice_id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${invoice_id}/send_to_client`, {})
      .pipe(
        filter(x => !!x),
        take(1)
      );
  }

  preview(invoice: Invoice) {
    return this.http.post<{
      invoice_preview: InvoicePreview[]
    }>(`${environment.base_url + this.endpoint}/preview`, this.prepareData(invoice), {
      responseType: 'json'
    }).pipe(
      map(res => res.invoice_preview)
    );
  }

  private prepareData(invoice: Invoice) {
    const params = JSON.parse(JSON.stringify(invoice));
    params.line_items_attributes = params.line_items;
    if (params.seller && params.seller.name) {
      params.seller = params.seller.name;
    }
    delete params.line_items;
    delete params.client;
    delete params.mandate;
    return params;
  }

  dunningLevelToHuman(dunning_level: string) {
    return DunningLevel[dunning_level];
  }

  stateToHuman(state: string) {
    return InvoiceState[state];
  }
}
