import { PersonalizedCardOrder } from '../personalized-card-order';

export class TextGenerator {

  static MARKERS = {
    'quantity': '#QUANTITY#',
    'print_finish': '#PRINT_FINISH#',
    'shipping_details': '#SHIPPING_DETAILS#',
    'comment': '#COMMENT#'
  };

  static EMAIL_TEMPLATE =
    'Hi zusammen,<br><br>' +
    'wir würden gerne eine neue Bestellung in Auftrag geben.<br><br>' +
    `${TextGenerator.MARKERS.comment}` +
    'Anzahl:<br>' +
    `<strong>${TextGenerator.MARKERS.quantity} Stück, ${TextGenerator.MARKERS.print_finish}</strong><br><br>` +
    'Versand an:<br><br>' +
    `<strong>${TextGenerator.MARKERS.shipping_details}</strong><br><br>` +
    'Links zu den Druckdateien befinden sich am Ende der E-Mail, die QR-Code-Datei ist im Anhang.<br><br>' +
    'Viele Grüße<br>' +
    'Mankido Team';

  constructor() {
  }

  static generate(personalizedCardOrder: PersonalizedCardOrder, comment: string): string {
    const markers = [
      {marker: this.MARKERS.quantity, value: personalizedCardOrder.quantity ? personalizedCardOrder.quantity.toString() : ''},
      {marker: this.MARKERS.print_finish, value: personalizedCardOrder.print_finish},
      {marker: this.MARKERS.shipping_details, value: this.createShippingDetails(personalizedCardOrder)},
      {marker: this.MARKERS.comment, value: comment ? `${comment}<br><br>` : ''}
    ];
    return this.replaceMarker(this.EMAIL_TEMPLATE, markers);
  }

  private static replaceMarker(text: string, markers: { marker: string, value: string }[]) {
    markers.forEach(marker => {
      text = text.replace(marker.marker, marker.value);
    });
    return text;
  }

  private static createShippingDetails(order: PersonalizedCardOrder) {
    let shippingDetails = '';
    if (order.company_name) {
      shippingDetails += `${order.company_name || '[Firmenname]'}<br>`;
    }
    shippingDetails += `${order.contact_person || '[Kontaktperson]'}<br>` +
      `${order.address || '[Adresse]'}<br>` +
      `${order.zip || '[PLZ]'} ${order.city || '[Stadt]'}`;

    if (order.country) {
      shippingDetails += `<br>${order.country}`;
    }
    return shippingDetails;
  }
}
