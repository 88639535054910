import { Injectable } from '@angular/core';
import {ClientDocument, ClientDocumentList} from './client_document';
import {BaseService} from '../base/base.service';
import {ClientDocumentSerializer} from 'src/app/client_documents/client_document.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientDocumentService extends BaseService<ClientDocument, ClientDocumentList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'client_documents' ;
    this.serializer = new ClientDocumentSerializer();
  }
}
