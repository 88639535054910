import {Injectable, Injector} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as rison from 'rison';
import {Group} from '../filter/group';
import {BusinessService} from '../businesses/business.service';
import {FilterService} from '../filter/filter.service';
import {ActivityService} from '../activities/activity.service';
import {AreaService} from '../areas/area.service';
import {AssetService} from '../assets/asset.service';
import {AudienceDefinitionService} from '../audience_definitions/audience_definition.service';
import {AutomationEventService} from '../automation_events/automation_event.service';
import {BusinessPhotoService} from '../business_photos/business_photo.service';
import {CampaignService} from '../campaigns/campaign.service';
import {CardService} from '../cards/card.service';
import {CategorizationService} from '../categorizations/categorization.service';
import {CategoryService} from '../categories/category.service';
import {ClientService} from '../clients/client.service';
import {ClientDocumentService} from '../client_documents/client_document.service';
import {ContentElementService} from '../content_elements/content_element.service';
import {ContractService} from '../contracts/contract.service';
import {DealService} from '../deals/deal.service';
import {DunningProcessService} from '../dunning_processes/dunning_process.service';
import {CouponService} from '../coupons/coupon.service';
import {DealAutomationService} from '../deal_automations/deal_automation.service';
import {DeviceLocationService} from '../device_locations/device_location.service';
import {DeviceService} from '../devices/device.service';
import {EmailService} from '../emails/email.service';
import {EmailEventService} from '../email_events/email_event.service';
import {InvitationService} from '../invitations/invitation.service';
import {MembershipService} from '../memberships/membership.service';
import {MerchantService} from '../merchants/merchant.service';
import {MerchantAccessService} from '../merchant-accesses/merchant-access.service';
import {MessageService} from '../messages/message.service';
import {MessageAttachmentService} from '../message_attachments/message_attachment.service';
import {MobileActivityService} from '../mobile_activities/mobile_activity.service';
import {MobileMessageService} from '../mobile_messages/mobile_message.service';
import {MobileSessionService} from '../mobile_sessions/mobile_session.service';
import {OfferService} from '../offers/offer.service';
import {PartnerService} from '../partners/partner.service';
import {PosActivityService} from '../pos_activities/pos_activity.service';
import {PosMessageService} from '../pos_messages/pos_message.service';
import {PosSessionService} from '../pos_sessions/pos_session.service';
import {RewardService} from '../rewards/reward.service';
import {SepaMandateService} from '../sepa_mandates/sepa_mandate.service';
import {TabletService} from '../tablets/tablet.service';
import {TabletDatumService} from '../tablet_data/tablet_datum.service';
import {TeaserService} from '../teasers/teaser.service';
import {TerminalService} from '../terminals/terminal.service';
import {TokenService} from '../tokens/token.service';
import {TransactionService} from '../transactions/transaction.service';
import {TransactionFlagService} from '../transaction_flags/transaction_flag.service';
import {UserService} from '../users/user.service';
import {InvoiceService} from '../invoices/invoice.service';
import {ShipmentService} from '../shipments/shipment.service';
import {PosExperienceService} from '../pos_experiences/pos_experience.service';
import {WebshopService} from '../webshops/webshop.service';
import {WebshopRequestService} from '../webshop_requests/webshop_request.service';
import {AffiliateDealService} from '../affiliate_deals/affiliate_deal.service';
import {AffiliateMerchantService} from '../affiliate_merchants/affiliate_merchant.service';
import {AffiliateCategoryService} from '../affiliate_categories/affiliate_category.service';

import {AddonSubscriptionService} from '../addon_subscriptions/addon_subscription.service';
import { AdministratorService } from '../administrators/administrator.service';
import { PersonalizedCardOrderService } from '../personalized-card-orders/personalized-card-order.service';
// GENERATOR-IMPORT

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private injector: Injector,
              private filterService: FilterService) { }

  getServiceEndpoint(dataName: string) {
    switch (dataName) {
      case 'administrators':        return this.injector.get<AdministratorService>(AdministratorService);
      case 'businesses':            return this.injector.get<BusinessService>(BusinessService);
      case 'webshops':              return this.injector.get<WebshopService>(WebshopService);
      case 'webshop_requests':      return this.injector.get<WebshopRequestService>(WebshopRequestService);
      case 'activities':            return this.injector.get<ActivityService>(ActivityService);
      case 'areas':                 return this.injector.get<AreaService>(AreaService);
      case 'assets':                return this.injector.get<AssetService>(AssetService);
      case 'audience_definitions':  return this.injector.get<AudienceDefinitionService>(AudienceDefinitionService);
      case 'automation_events':     return this.injector.get<AutomationEventService>(AutomationEventService);
      case 'business_photos':       return this.injector.get<BusinessPhotoService>(BusinessPhotoService);
      case 'campaigns':             return this.injector.get<CampaignService>(CampaignService);
      case 'cards':                 return this.injector.get<CardService>(CardService);
      case 'categorizations':       return this.injector.get<CategorizationService>(CategorizationService);
      case 'categories':            return this.injector.get<CategoryService>(CategoryService);
      case 'clients':               return this.injector.get<ClientService>(ClientService);
      case 'client_documents':      return this.injector.get<ClientDocumentService>(ClientDocumentService);
      case 'content_elements':      return this.injector.get<ContentElementService>(ContentElementService);
      case 'contracts':             return this.injector.get<ContractService>(ContractService);
      case 'deals':                 return this.injector.get<DealService>(DealService);
      case 'dunning_processes':     return this.injector.get<DunningProcessService>(DunningProcessService);
      case 'coupons':               return this.injector.get<CouponService>(CouponService);
      case 'deal_automations':      return this.injector.get<DealAutomationService>(DealAutomationService);
      case 'device_locations':      return this.injector.get<DeviceLocationService>(DeviceLocationService);
      case 'devices':               return this.injector.get<DeviceService>(DeviceService);
      case 'emails':                return this.injector.get<EmailService>(EmailService);
      case 'email_events':          return this.injector.get<EmailEventService>(EmailEventService);
      case 'invitations':           return this.injector.get<InvitationService>(InvitationService);
      case 'memberships':           return this.injector.get<MembershipService>(MembershipService);
      case 'merchants':             return this.injector.get<MerchantService>(MerchantService);
      case 'merchant_accesses':     return this.injector.get<MerchantAccessService>(MerchantAccessService);
      case 'messages':              return this.injector.get<MessageService>(MessageService);
      case 'message_attachments':   return this.injector.get<MessageAttachmentService>(MessageAttachmentService);
      case 'mobile_activities':     return this.injector.get<MobileActivityService>(MobileActivityService);
      case 'mobile_messages':       return this.injector.get<MobileMessageService>(MobileMessageService);
      case 'mobile_sessions':       return this.injector.get<MobileSessionService>(MobileSessionService);
      case 'offers':                return this.injector.get<OfferService>(OfferService);
      case 'partners':              return this.injector.get<PartnerService>(PartnerService);
      case 'pos_activities':        return this.injector.get<PosActivityService>(PosActivityService);
      case 'pos_messages':          return this.injector.get<PosMessageService>(PosMessageService);
      case 'pos_sessions':          return this.injector.get<PosSessionService>(PosSessionService);
      case 'rewards':               return this.injector.get<RewardService>(RewardService);
      case 'sepa_mandates':         return this.injector.get<SepaMandateService>(SepaMandateService);
      case 'tablets':               return this.injector.get<TabletService>(TabletService);
      case 'tablet_data':           return this.injector.get<TabletDatumService>(TabletDatumService);
      case 'teasers':               return this.injector.get<TeaserService>(TeaserService);
      case 'terminals':             return this.injector.get<TerminalService>(TerminalService);
      case 'tokens':                return this.injector.get<TokenService>(TokenService);
      case 'transactions':          return this.injector.get<TransactionService>(TransactionService);
      case 'transaction_flags':     return this.injector.get<TransactionFlagService>(TransactionFlagService);
      case 'users':                 return this.injector.get<UserService>(UserService);
      case 'invoices':              return this.injector.get<InvoiceService>(InvoiceService);
      case 'shipments':             return this.injector.get<ShipmentService>(ShipmentService);
      case 'pos_experiences':       return this.injector.get<PosExperienceService>(PosExperienceService);
      case 'affiliate_deals':       return this.injector.get<AffiliateDealService>(AffiliateDealService);
      case 'affiliate_merchants':   return this.injector.get<AffiliateMerchantService>(AffiliateMerchantService);
      case 'affiliate_categories':   return this.injector.get<AffiliateCategoryService>(AffiliateCategoryService);
      case 'addon_subscriptions':     return this.injector.get<AddonSubscriptionService>(AddonSubscriptionService);
      case 'personalized_card_orders': return this.injector.get<PersonalizedCardOrderService>(PersonalizedCardOrderService);
  // GENERATOR-ENDPOINT
    }
  }

  onFilter(group: Group[]): void {
    this.toURL('q', this.filterService.filterToUrl(group), 0);
  }

  getSelections(selected, data) {
    const filtered = data.filter((x) => selected.map(el => el.id).includes(x.id));
    const remainder = selected.filter((x) => !filtered.map(el => el.id).includes(x.id));
    return [...filtered, ...remainder];
  }

  toURL(param: string, value, chop?: number, page: number = 1): void {
    let obj;
    switch (chop) {
      case 0:
        obj = {[param]: rison.encode(value), page: page};
        break;
      case 1:
        obj = {[param]: rison.encode(value).substr(2, rison.encode(value).length - 3), page: page};
        break;
      default:
        obj = value;
        break;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: obj,
      queryParamsHandling: 'merge'
    });
  }

  getParam(params, parameter: string, resolved?: Function, rejected?: Function): any {
    if (params[parameter] !== undefined && params[parameter] !== '') {
      if (resolved) {
        return resolved(params[parameter]);
      }
    } else {
      if (rejected) {
        return rejected(params[parameter]);
      }
    }
  }
}
