import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { User } from '../users/user';
import { Partner } from '../partners/partner';
import { Transaction } from '../transactions/transaction';
import { Offer } from '../offers/offer';
import { Deal } from '../deals/deal';
import { AutomationEvent } from '../automation_events/automation_event';
// import { MembershipStat } from '../membership_stats/membership_stat';

export class Membership {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: -1, flexGrow: .17}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Angelegt', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 8, flexGrow: .4}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Transaktionen', prop: 'transactions_count', type: ColumnType.Number, standard: true, order: 3, flexGrow: .1}),
    new Column({name: 'Punkte', prop: 'points', type: ColumnType.Number, standard: true, order: 4, flexGrow: .1}),
    new Column({name: 'average_transaction_gap', prop: 'average_transaction_gap', type: ColumnType.Number}),
    new Column({name: 'segment', prop: 'segment', type: ColumnType.Number, standard: true, order: 5, flexGrow: .4}),
    new Column({name: 'E-Mails abonniert', prop: 'emails_unsubscribed_at', type: ColumnType.Boolean_Inverted, standard: true,
      order: 7, flexGrow: .08}),
    new Column({name: 'AVG', prop: 'average_transaction_day_gap', type: ColumnType.Number, standard: true, order: 6, flexGrow: .2}),
    new Column({name: 'Origin', prop: 'origin_data', type: ColumnType.Polymorphic, order: 2}),
    new Column({name: 'Origin Type', prop: 'origin_type', type: ColumnType.String}),
    new Column({name: 'Origin ID', prop: 'origin_id', type: ColumnType.Number}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns,
      standard: true, order: 1, standardProps: 'email'}),
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns,
    standard: true, order: 0, standardProps: 'logo,name'}),
    // new Column({name: 'membership_stat', prop: 'membership_stat', association: 'has_one', columns: MembershipStat.allColumns}),
  ];

  // Simple Columns
  id: number;
  user_id: number;
  partner_id: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  origin_type: string;
  origin_id: number;
  transactions_count: number;
  average_transaction_gap: number;
  segment: number;
  emails_unsubscribed_at: Date;
  average_transaction_day_gap: number;
  // Associations
  user: User;
  partner: Partner;
  transaction: Transaction[];
  offer: Offer[];
  deal: Deal[];
  automation_event: AutomationEvent[];
  // membership_stat: MembershipStat;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.user && this.user.email ? this.user.email : this.user_id.toString();
  }
}

export class MembershipList {
  count: number;
  data: Membership[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
