<trix-toolbar id="trix-custom-toolbar">
  <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button fa fa-bold" data-trix-attribute="bold" data-trix-key="b" title="Fett" tabindex="-1"></button>
        <button type="button" class="trix-button fa fa-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1"></button>
        <button type="button" class="trix-button fa fa-strikethrough" data-trix-attribute="strike" title="Durchgestrichen" tabindex="-1"></button>
        <button type="button" class="trix-button fa fa-underline" data-trix-attribute="underline" data-trix-key="u" title="Unterstrichen" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabindex="-1"></button>
        <!-- <button type="button" class="trix-button fa fa-file" title="File" data-trix-action="x-button-insert" tabindex="-1"></button>-->
      </span>

    <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button fas fa-heading" data-trix-attribute="heading3" title="Überschrift" tabindex="-1"></button>
        <button type="button" class="trix-button fa fa-list-ul" data-trix-attribute="bullet" title="Liste" tabindex="-1"></button>
        <button type="button" class="trix-button fa fa-list-ol" data-trix-attribute="number" title="Nummerierte Liste" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="" tabindex="-1"></button>
      </span>
  </div>

  <div class="trix-dialogs" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields">
        <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="URL hier einfügen" aria-label="URL hier einfügen" required data-trix-input>
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog" value="Link" data-trix-method="setAttribute">
          <input type="button" class="trix-button trix-button--dialog" value="Unlink" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>
  </div>
</trix-toolbar>
<trix-editor class="trix-content trix-text" toolbar="trix-custom-toolbar"
             #trixElement
             [class.trix-editor-disabled]="isDisabled"
             (trix-before-initialize)="onTrixBeforeInitialize($event)"
             (trix-initialize)="onTrixInitialize($event)"
             (trix-file-file-accept)="onTrixFileAccept($event)"
             (trix-change)="onTrixChange($event)"
             (trix-blur)="onTrixBlur($event)"></trix-editor>
<span class="text-muted save-text" *ngIf="saveIndicator && showSave" @FadeOut>Speichern...</span>
