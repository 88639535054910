import { TreeviewItem } from 'ngx-treeview';

export class Permission {
  static root = {
    activities: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    administrators: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    areas: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    assets: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    audience_definitions: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    automation_events: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    businesses: {
      create: false,
      show: {associated: false, list: false, singleview: false, blacklist: false, shipments: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    business_photos: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    campaigns: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    cards: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      mass_request: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    categorizations: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    categories: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    clients: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        send_welcome_mail: false,
        mass: {
          edit: false, replace: false, delete: false
        }
      }
    },
    business_care: {
      show: {list: false}
    },
    client_documents: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    content_elements: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    contracts: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        mass: {edit: false, replace: false, delete: false},
        download: false,
        email: false
      }
    },
    coupons: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    deals: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    deal_automations: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    devices: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    device_locations: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    emails: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    email_events: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    invitations: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    invoices: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {return_debit: false, mass: {edit: false, replace: false, delete: false}}
    },
    line_items: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    memberships: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        mass: {edit: false, replace: false, delete: false},
        unsubscribe_emails: false,
        resubscribe_emails: false
      }
    },
    membership_stats: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    merchants: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {change_password: false, mass: {edit: false, replace: false, delete: false}}
    },
    merchant_accesses: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        mass: {edit: false, replace: false, delete: false},
        decrypt_password: false
      }
    },
    messages: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    message_attachments: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    message_stats: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    mobile_activities: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    mobile_messages: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    mobile_sessions: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    offers: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    partners: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    partner_stats: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    pos_activities: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    pos_experiences: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    pos_messages: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    pos_sessions: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    rewards: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    sepa_mandates: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        mass: {edit: false, replace: false, delete: false},
        download: false,
        email: false
      }
    },
    tablets: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        assign: false,
        unlock_setup: false,
        mass: {
          edit: false, replace: false, delete: false
        }
      }
    },
    tablet_data: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    teasers: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    terminals: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    tokens: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    transactions: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    transaction_flags: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {mass: {edit: false, replace: false, delete: false}}
    },
    users: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        unlock: false,
        resend_confirmation_email: false,
        resend_welcome_email: false,
        resend_app_code: false,
        reset_password: false,
        merge: false,
        unsubscribe_emails: false,
        resubscribe_emails: false,
        mass: {
          edit: false,
          replace: false,
          delete: false
        }
      }
    },
    shipments: {
      create: false,
      show: {associated: false, list: false, singleview: false},
      edit: false,
      delete: false,
      actions: {
        ship: false,
        print: false,
        mail: false,
        mass: {
          edit: false,
          replace: false,
          delete: false
        }
      }
    },
    webshops: {
      show: {list: false}
    },
    affiliate_deals: {
      show: {
        singleview: false,
        list: false,
        edit: false
      }
    },
    affiliate_merchants: {
      show: {
        singleview: false,
        list: false
      }
    },
    affiliate_categories: {
      show: {
        singleview: false,
        list: false
      }
    },
    addon_subscriptions: {
      show: {
        singleview: false,
        list: false
      },
      edit: false,
      new: false
    },
    dunning_processes: {
      show: {
        list: true
      }
    },
    personalized_card_orders: {
      show: {
        list: true
      }
    }
  };

  static allowed(permissions: Object, permission: string): boolean {
    const v = ('root.' + permission).split('.');
    let obj = permissions;
    let index = 0;

    while (obj !== null && obj !== undefined && index < v.length) {
      obj = obj[v[index++]];
      if (typeof obj === 'boolean') {
        return obj;
      } else if (typeof obj === 'symbol') {
        return false;
      }
    }
  }

  // returns a new object with the values at each key mapped using mapFn(value)
  static reducedTree(object): any {

    if (Array.isArray(object)) {
      return object.map(obj => Permission.reducedTree(obj));
    }

    let response;
    if (object.internalChecked === true) {
      response = {};
      response[object.text] = true;
    } else if (object.internalChildren && object.internalChildren.length > 0) {
      const res = object.internalChildren.map(child => Permission.reducedTree(child)).filter(n => n);
      response = null;
      if (res.length > 0) {
        response = {};
        response[object.text] = res.reduce(function (r, a) {
          return Object.assign(r, a);
        }, {});
      }
    }

    return response;
  }

  static asTree(permissions): [TreeviewItem] {
    const la = new Permission().objectMap({root: Permission.root}, permissions, null);
    const res: TreeviewItem = new TreeviewItem(la.root);
    return [res];
  }

  // returns a new object with the values at each key mapped using mapFn(value)
  objectMap(object, permissions, recursive): any {
    return Object.keys(object).reduce((result, key) => {
      const recursive2 = [recursive, key].filter(n => n).join('.').replace('root.', '');
      const allowed = !!Permission.allowed(permissions, recursive2);
      if (typeof object[key] === 'boolean') {
        result[key] = {
          checked: allowed,
          value: key,
          text: key
        };
      } else {
        result[key] = {
          checked: allowed,
          text: key,
          value: key,
          children: Object.values(this.objectMap(object[key], permissions, recursive2))
        };
      }
      return result;
    }, {});
  }

}
