import { Column } from '../shared/models/column';
import { ColumnType } from '../shared/enums/column-type.enum';
import { AffiliateMerchant } from '../affiliate_merchants/affiliate_merchant';
import { AffiliateCategory } from '../affiliate_categories/affiliate_category';
import { Predicate } from '../shared/enums/predicate.enum';
import { Filter } from '../filter/filter';

export class AffiliateDeal {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: 'id', prop: 'id', type: ColumnType.Number, standard: true, flexGrow: 1}),
    new Column({name: 'unique_id', prop: 'unique_id', type: ColumnType.Number, standard: false}),
    new Column({name: 'approved', prop: 'approved', type: ColumnType.Boolean, standard: true, flexGrow: 1}),
    new Column({name: 'favorite', prop: 'favorite', type: ColumnType.Boolean, standard: true, flexGrow: 1}),
    new Column({name: 'rank', prop: 'rank', type: ColumnType.Number, standard: true, flexGrow: 1}),
    new Column({name: 'external_rank', prop: 'external_rank', type: ColumnType.String, standard: true, flexGrow: 2}),
    new Column({name: 'detected_voucher_type', prop: 'detected_voucher_type', type: ColumnType.String, standard: false}),

    new Column({name: 'affiliate_merchant', prop: 'affiliate_merchant', association: 'belongs_to', standardProps: 'name', standard: true, columns: AffiliateMerchant.allColumns}),

    new Column({name: 'title', prop: 'title', type: ColumnType.String, standard: true, flexGrow: 10}),
    new Column({name: 'description', prop: 'description', type: ColumnType.String, standard: false}),
    new Column({name: 'voucher_code', prop: 'voucher_code', type: ColumnType.String}),
    new Column({name: 'price_pill', prop: 'price_pill', type: ColumnType.String}),
    new Column({name: 'terms', prop: 'terms', type: ColumnType.String}),
    new Column({name: 'referral_url', prop: 'referral_url', type: ColumnType.String}),
    new Column({name: 'deal_type', prop: 'deal_type', type: ColumnType.String}),
    new Column({name: 'value_type', prop: 'value_type', type: ColumnType.String}),
    new Column({name: 'monetized', prop: 'monetized', type: ColumnType.Boolean}),
    new Column({name: 'valid_customers', prop: 'valid_customers', type: ColumnType.Number}),
    new Column({name: 'min_order_value', prop: 'min_order_value', type: ColumnType.Number}),
    new Column({name: 'has_unique_voucher_codes', prop: 'has_unique_voucher_codes', type: ColumnType.Boolean}),
    new Column({name: 'starts_at', prop: 'starts_at', type: ColumnType.DateTime}),
    new Column({name: 'ends_at', prop: 'ends_at', type: ColumnType.DateTime, standard: true, flexGrow: 2}),
    new Column({name: 'data', prop: 'data', type: ColumnType.JSON}),
    new Column({name: 'reviewed_at', prop: 'reviewed_at', type: ColumnType.DateTime}),
    new Column({name: 'deleted_at', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'created_at', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'updated_at', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'affiliate_merchant_id', prob: 'affiliate_merchant_id', type: ColumnType.Number}),
    new Column({name: 'Cover', prob: 'cover_url', type: ColumnType.String}),
    // Associated Columns
    new Column({name: 'affiliate_category', prop: 'affiliate_category', association: 'has_one', columns: AffiliateCategory.allColumns})
  ];

  static reviewableFilters = [
    new Filter({subject: 'detected_voucher_type', predicate: Predicate.NOT_EQUAL, object1: 'per_user'}),
    new Filter({subject: 'monetized', predicate: Predicate.EQUAL, object1: true})
  ];

  static UnreviewedFilters = AffiliateDeal.reviewableFilters.concat([
    new Filter({subject: 'reviewed_at', predicate: Predicate.EMPTY, object1: ''})
  ]);

  // Simple Columns
  id: number;
  unique_id: string;
  approved: boolean;
  favorite: boolean;
  detected_voucher_type: string;
  title: string;
  description: string;
  voucher_code: string;
  price_pill: string;
  terms: string;
  referral_url: string;
  rank: number;
  deal_type: string;
  value_type: string;
  monetized: boolean;
  valid_customers: number;
  min_order_value: number;
  external_rank: string;
  starts_at: boolean;
  ends_at: boolean;
  data: string;
  affiliate_merchant_id: number;
  reviewed_at: Date;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  cover: any;
  cover_url: string;
  // Associations
  affiliate_merchant: AffiliateMerchant;
  affiliate_category: AffiliateCategory;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.unique_id;
  }
}

export class AffiliateDealList {
  count: number;
  data: AffiliateDeal[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
