import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { User } from '../users/user';
import { Token } from '../tokens/token';
import { MobileSession } from '../mobile_sessions/mobile_session';
import { DeviceLocation } from '../device_locations/device_location';
// import { User } from '../signups/signup';

export class Device {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Hersteller', prop: 'manufacturer', type: ColumnType.String, standard: true, order: 1, flexGrow: .4}),
    new Column({name: 'Modell', prop: 'model', type: ColumnType.String, standard: true, order: 2, flexGrow: .2}),
    new Column({name: 'App Version', prop: 'app_version', type: ColumnType.String, standard: true, order: 3, flexGrow: .2}),
    new Column({name: 'location_services_enabled_at', prop: 'location_services_enabled_at', type: ColumnType.DateTime}),
    new Column({name: 'Benachrichtigungen', prop: 'notifications_enabled_at', type: ColumnType.Boolean,
      standard: true, order: 1, flexGrow: .1}),
    new Column({name: 'notification_token', prop: 'notification_token', type: ColumnType.String}),
    new Column({name: 'Zuletzt benutzt am', prop: 'last_used_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 4, flexGrow: .4}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'UUID', prop: 'uuid', type: ColumnType.String}),
    new Column({name: 'platform', prop: 'platform', type: ColumnType.String}),
    new Column({name: 'os_version', prop: 'os_version', type: ColumnType.String}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns,
    standard: true, standardProps: 'email', order: 0}),
    new Column({name: 'Token', prop: 'token', association: 'has_one', columns: Token.allColumns}),
  ];

  // Simple Columns
  id: number;
  user_id: number;
  manufacturer: string;
  model: string;
  app_version: string;
  location_services_enabled_at: boolean;
  notifications_enabled_at: boolean;
  notification_token: string;
  last_used_at: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  uuid: string;
  platform: string;
  os_version: string;
  // Associations
  user: User;
  token: Token;
  mobile_session: MobileSession[];
  device_location: DeviceLocation[];
  signup: User[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.manufacturer + ' ' + this.model;
  }
}

export class DeviceList {
  count: number;
  data: Device[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
