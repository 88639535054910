import {Invoice} from './invoice';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';
import {DunningProcessSerializer} from '../dunning_processes/dunning_process.serializer';
import {Injectable} from '@angular/core';
import { LineItem } from '../line_items/line_item';

@Injectable({
  providedIn: 'root'
})
export class InvoiceSerializer extends BaseSerializer<Invoice> {
  fromJson(json: any): Invoice {
    const serialized = new Invoice(json);
    if (json.seller) {
      serialized.seller = json.seller;
    }
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    if (json.dunning_process) {
      serialized.dunning_process = new DunningProcessSerializer().fromJson(json.dunning_process);
    }
    if (json.line_items) {
      serialized.line_items = json.line_items.map((lineItem) => new LineItem(lineItem));
    }
    return serialized;
  }

  toJson(obj: Invoice): string {
    let params: any = {invoice: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
