import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PermissionGuard} from '../shared/permissions/permission.guard';
import { PersonalizedCardOrdersComponent } from './personalized-card-orders/personalized-card-orders.component';
import { PersonalizedCardOrderFormComponent } from './personalized-card-order-form/personalized-card-order-form.component';
import { PersonalizedCardOrderShowComponent } from './personalized-card-order-show/personalized-card-order-show.component';


const routes: Routes = [
  {
    path: '',
    component: PersonalizedCardOrdersComponent,
    canActivate: [PermissionGuard],
    data: { permission: 'personalized_card_orders.show.list' },
    children: [
      {
        path: '',
        redirectTo: 'new',
        pathMatch: 'full'
      },
      {
        path: 'new',
        component: PersonalizedCardOrderFormComponent,
      },
      {
        path: ':id',
        component: PersonalizedCardOrderShowComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonalizedCardOrdersRoutingModule { }
