import { Injectable } from '@angular/core';
import {EmailEvent, EmailEventList} from './email_event';
import {BaseService} from '../base/base.service';
import {EmailEventSerializer} from 'src/app/email_events/email_event.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailEventService extends BaseService<EmailEvent, EmailEventList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'email_events' ;
    this.serializer = new EmailEventSerializer();
  }
}
