import {TransactionFlag} from './transaction_flag';
import {BaseSerializer} from '../base/base.serializer';

export class TransactionFlagSerializer extends BaseSerializer<TransactionFlag> {
  fromJson(json: any): TransactionFlag {
    const serialized = new TransactionFlag(json);
    return serialized;
  }

  toJson(obj: TransactionFlag): string {
    let params: any = {transaction_flag: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
