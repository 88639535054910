import {Teaser} from './teaser';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';
import {AssetSerializer} from '../assets/asset.serializer';

export class TeaserSerializer extends BaseSerializer<Teaser> {
  fromJson(json: any): Teaser {
    const serialized = new Teaser(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    if (json.asset) {
      serialized.asset = new AssetSerializer().fromJson(json.asset);
    }
    return serialized;
  }

  toJson(obj: Teaser): string {
    let params: any = {teaser: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
