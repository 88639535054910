import {Card} from './card';
import {BaseSerializer} from '../base/base.serializer';
import {UserSerializer} from '../users/user.serializer';

export class CardSerializer extends BaseSerializer<Card> {
  fromJson(json: any): Card {
    const serialized = new Card(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    return serialized;
  }

  toJson(obj: Card): string {
    let params: any = {card: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
