import { Injectable } from '@angular/core';
import {PosExperience, PosExperienceList} from './pos_experience';
import {BaseService} from '../base/base.service';
import {PosExperienceSerializer} from 'src/app/pos_experiences/pos_experience.serializer';
import {HttpClient} from '@angular/common/http';
import {Page} from '../shared/models/page';
import {Group} from '../filter/group';
import {Sort} from '../shared/models/sort';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PosExperienceService extends BaseService<PosExperience, PosExperienceList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'pos_experiences';
    this.serializer = new PosExperienceSerializer();
  }

  fetchPage(page: Page, group: Group[], sort: Sort[], fields: string): Observable<PosExperienceList> {
    return super.fetchPage(page, group, sort, fields + ',config');
  }
}
