<div class="input-group">
  <div class="input-group-prepend" *ngIf="value && value != ''">
    <button class="input-group-text" tooltip="Auswahl entfernen" (click)="onReset()" [disabled]="isDisabled">
      <i class="fal fa-times"></i>
    </button>
    <a class="input-group-text" tooltip="Aktuell ausgewählt" [routerLink]="['/', model, value]" target="_blank" *ngIf="displaySelectedAs === 'name'">
      {{ label }}
    </a>
    <a class="input-group-text" tooltip="Öffne {{ label }}" [routerLink]="['/', model, value]" target="_blank" *ngIf="displaySelectedAs === 'icon'">
      <i class="fal fa-external-link"></i>
    </a>
  </div>

  <input class="form-control"
         autocomplete="off"
         [placeholder]="inputPlaceholder()"
         [ngClass]="bootstrapValidationClass"
         [ngModel]="searchTerm"
         [disabled]="isDisabled"
         (ngModelChange)="updateSearchTerm($event)"
         [typeahead]="createTypeaheadObservable()"
         [typeaheadItemTemplate]="customTypeahead"
         [typeaheadMinLength]="this.scopeQuery.length > 0 ? 0 : 1"
         [typeaheadWaitMs]="200"
         (typeaheadOnSelect)="onSelect($event)">

  <ng-template let-model="item" let-index="index" #customTypeahead>
    {{ createTypeaheadLine(model) }}
  </ng-template>
</div>
