import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {Deal} from '../deals/deal';
import {MessageStat} from '../message_stats/message_stat';
import {Membership} from '../memberships/membership';
import {User} from '../users/user';

export class DealAutomation {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .2}),
    new Column({name: 'type', prop: 'type', type: ColumnType.String, standard: true, order: 2, flexGrow: .8}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({
      name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns,
      editable: false, standard: true, standardProps: 'logo,name', order: 1
    }),
    new Column({
      name: 'Deal', prop: 'deal', association: 'belongs_to', columns: Deal.allColumns,
      editable: false, standard: true, standardProps: 'title', order: 3
    }),
    new Column({
      name: 'message_stat', prop: 'message_stat', association: 'belongs_to', columns: MessageStat.allColumns, order: 4,
      editable: false, standard: true, standardProps: 'messages_count,email_delivered_count,email_open_count,email_click_count'
    }),
  ];

  // Simple Columns
  id: number;
  type: string;
  partner_id: number;
  deal_id: number;
  message_stat_id: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  partner: Partner;
  deal: Deal;
  message_stat: MessageStat;
  membership: Membership[];
  user: User[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.type;
  }
}

export class DealAutomationList {
  count: number;
  data: DealAutomation[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
