import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import * as rison from 'rison';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {AdministratorService} from '../../../../administrators/administrator.service';
import {Search} from './search';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild('search', {static: true}) search: ElementRef;
  private searchSubject = new Subject<string>();
  recentSearch = '';

  shortcuts = {
    'b': 'businesses',
    'i': 'invoices',
    'c': 'clients',
    'u': 'users',
    'ac': 'activities',
    'a': 'areas',
    'cp': 'campaigns',
    'cd': 'cards',
    'ct': 'categories',
    'd': 'client_documents',
    'cn': 'contracts',
    'de': 'deals',
    'co': 'coupons',
    'm': 'merchants',
    'ms': 'mobile_sessisons',
    'p': 'partners',
    'ps': 'pos_sessions',
    'r': 'rewards',
    'sm': 'sepa_mandates',
    'ta': 'tablets',
    'ts': 'teasers',
    'te': 'terminals',
    'tk': 'tokens',
    't': 'transactions'
  };

  constructor(private router: Router,
              private adminService: AdministratorService) {
  }

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(500)).subscribe(searchString => {
      if (this.recentSearch.length > 1 && searchString.length === 0) {
        this.recentSearch = '';
        this.router.navigate([], {
          queryParams: {
            page: 1,
            q: rison.encode({})
          }
        });
      }
      if (this.search.nativeElement.value.length > 1 && document.activeElement === this.search.nativeElement) {
        this.recentSearch = searchString;
        const shrtct = this.search.nativeElement.value.substr(0, 2);
        const lngshrtct = this.search.nativeElement.value.substr(0, 3);
        const type = shrtct.split(' ').length > 1;
        const searchSwitch = (type) ? shrtct : lngshrtct;
        const noSpacesSearchSwitch = searchSwitch.replace(' ', '');
        switch (searchSwitch) {
          case 'g ':
            this.router.navigate(['/search', this.search.nativeElement.value.substr(2)]);
            break;
          case 'wo ':
            this.router.navigate(this.isMap ? [] : ['map'], {
              queryParams: {
                mapsearch: this.search.nativeElement.value.substr(2)
              },
              queryParamsHandling: 'merge'
            });
            break;
          default:
            if (this.shortcuts[noSpacesSearchSwitch]) {
              this.searchFor(this.shortcuts[noSpacesSearchSwitch], false, searchSwitch.length);
            } else if (this.router.url.includes('/map') || this.router.url.split('/').length === 2) {
              // prevent search in edit/show/new etc.
              const c = this.router.url.split('/')[1].split('?')[0];
              this.searchFor(c, true);
            } else if (this.router.url.includes('invoices')) { // prevent search in edit/show/new etc.
              this.searchFor('invoices', true);
            }
            break;
        }
      }
    });
  }

  get isMap() {
    return this.router.url.includes('/map') || this.router.url.includes('/area-map');
  }

  @HostListener('window:keydown', ['$event.keyCode', '$event'])
  keyEvent(keyCode: number, ev: KeyboardEvent): void {
    if (keyCode === 38 || keyCode === 40 || ev.metaKey || ev.ctrlKey || ev.altKey) {
      return;
    }

    const document = this.search.nativeElement.ownerDocument;
    if (keyCode === 27 || keyCode === 17 || keyCode === 91 || keyCode === 93 || keyCode === 18 || keyCode === 17 || keyCode === 16) {
      this.search.nativeElement.blur = true;
    } else if (document.activeElement === document.body || document.activeElement instanceof HTMLAnchorElement) {
      this.search.nativeElement.focus();
    }
  }

  @HostListener('window:keyup')
  keyUpEvent(): void {
    this.searchSubject.next(this.search.nativeElement.value);
  }

  searchFor(endpoint: string, soft = false, cutLen = 2): void {
    let q = [];
    const target_route = (soft) ? [] : [`./${endpoint}`];
    if (this.search.nativeElement.value.substr(cutLen).length >= 1 && this.adminService.allowed(endpoint + '.show')) {
      q = [{m: 'or'}];
      Search.getSearchProps(endpoint).forEach((val) => {
        q[0][val + '_cont'] = (soft) ? this.search.nativeElement.value : this.search.nativeElement.value.substr(cutLen);
      });

      this.router.navigate(target_route, {
        queryParams: this.toURL('q', q),
        queryParamsHandling: (soft) ? 'merge' : null
      });
    } else {
      this.router.navigate([target_route], {
        queryParams: this.toURL('page', 1),
        queryParamsHandling: (soft) ? 'merge' : null
      });
    }
  }

  toURL(param: string, value): Object {
    return {[param]: rison.encode(value), page: 1};
  }

}
