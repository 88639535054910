import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {Business} from '../businesses/business';
import {WebshopRequest} from '../webshop_requests/webshop_request';

export class Webshop {

  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, editable: false}),


    // Associated Columns
    new Column({
      name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns,
      editable: false, standard: true, standardProps: 'logo,name', order: 0
    }),
    new Column({
      name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns,
      editable: false, standard: true, standardProps: 'name', order: 0
    }),

    // Simple Columns
    new Column({name: 'URL', prop: 'url', type: ColumnType.String, editable: true, standard: true}),
    new Column({name: 'Api Key', prop: 'api_key', type: ColumnType.String, editable: false, standard: true}),
    new Column({name: 'Deaktiviert am', prop: 'disabled_at:', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, editable: false, flexGrow: 0.35})
  ];

  // Simple Columns
  id: number;
  partner_id: number;
  business_id: number;
  api_key: string;
  url: string;
  flavor: string;
  disabled_at: Date;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  // Associations
  partner: Partner;
  business: Business;
  requests: WebshopRequest[];


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return (this.business && this.business.name) ? `${this.business.name}` : this.url;
  }
}
export class WebshopList {
  count: number;
  data: Webshop[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
