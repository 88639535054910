import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {mail: '', pass: ''};
  loading = false;

  constructor(private auth: AuthService, private router: Router, private toast: ToastrService) { }

  ngOnInit() {

  }

  login() {
    this.loading = true;
    this.auth.login({email: this.user.mail, password: this.user.pass})
      .subscribe(success => {
        this.loading = false;
        this.router.navigate(['/']);
      }, error => {
        this.toast.error('Login fehlgeschlagen.');
        this.loading = false;
      });
  }

}
