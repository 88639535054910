import { Column } from '../shared/models/column';
import { ColumnType } from '../shared/enums/column-type.enum';
import { Invoice } from '../invoices/invoice';
// import { SepaMandate } from '../sepa_mandates/sepa_mandate';
import { ClientDocument } from '../client_documents/client_document';
import { Partner } from '../partners/partner';
import { Business } from '../businesses/business';
import { Merchant } from '../merchants/merchant';
import { MerchantAccess } from '../merchant-accesses/merchant-access';
import { Contract } from '../contracts/contract';
import { InvoicePreview } from './invoice-preview';

export class Client {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, flexGrow: 0.15}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true, order: 1, flexGrow: 0.8}),
    new Column({name: 'slug', prop: 'slug', type: ColumnType.String, standard: true, order: 2, flexGrow: 0.8}),
    new Column({name: 'Firma', prop: 'company', type: ColumnType.String}),
    new Column({name: 'Kontakt', prop: 'contact', type: ColumnType.String, standard: true, order: 3, flexGrow: 0.7}),
    new Column({name: 'Telefon', prop: 'phone', type: ColumnType.String, standard: true, order: 4, flexGrow: 0.4}),
    new Column({name: 'Mobil', prop: 'mobile', type: ColumnType.String, standard: true, order: 5, flexGrow: 0.4}),
    new Column({name: 'E-Mail', prop: 'email', type: ColumnType.String, standard: true, order: 6, flexGrow: 0.7}),
    new Column({name: 'Adresse', prop: 'address', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Rechnungen', prop: 'invoices_count', type: ColumnType.Number}),
    // Associated Columns
    // new Column({name: 'mandate', prop: 'mandate', association: 'has_one', columns: SepaMandate.allColumns}),
  ];

  // Simple Columns
  id: number;
  name: string;
  slug: string;
  client_number: string;
  company: string;
  contact: string;
  phone: string;
  mobile: string;
  email: string;
  address: string;
  zip: string;
  city: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  invoice: Invoice[];
  // sepa_mandate: SepaMandate[];
  client_document: ClientDocument[];
  partner: Partner[];
  business: Business[];
  merchant: Merchant[];
  merchant_access: MerchantAccess[];
  contracts: Contract[];
  invoice_preview: InvoicePreview[];

  // mandate: SepaMandate;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.name;
  }
}

export class ClientList {
  count: number;
  data: Client[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
