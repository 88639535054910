import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Group, QuickFilter } from '../../filter/group';
import { ListService } from '../../base/list.service';

@Component({
  selector: 'app-right-panel-content',
  templateUrl: './right-panel-content.component.html',
  styleUrls: ['./right-panel-content.component.scss']
})
export class RightPanelContentComponent implements OnInit, OnDestroy {
  @ViewChild('rightPanel', {static: true}) dynamicContent: TemplateRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    const dynamicElement = document.querySelector('#dynamicContainer');
    const embeddedView = this.viewContainerRef.createEmbeddedView(this.dynamicContent);
    embeddedView.rootNodes.forEach(rootNode => dynamicElement.appendChild(rootNode));
  }

  ngOnDestroy() {
    this.viewContainerRef.clear();
  }
}
