<div class="card" style="margin-top: 5px;" *ngIf="selected && selected.length > 0">
  <div class="card-header bg-primary text-white font-weight-bold" style="padding: 2%; text-align: center;">
    Auswahl
    <small>({{ selected.length }} ausgewählt)</small>
  </div>

  <div class="card-body" style="padding: 2%;">

    <select class="form-control" [(ngModel)]="selectionOption.column" (ngModelChange)="checkColumn()">
      <option *ngFor="let col of columns" [ngValue]="col"><span *ngIf="col.editable">{{ col.name }}</span></option>
    </select>


    <select *ngIf="selectionOption.column && selectionOption.column.editable" class="form-control"
            style="margin-top: 2%;" [(ngModel)]="selectionOption.todo">
      <option [ngValue]="Todo.CHANGE" *can="model + '.actions.mass.replace'">wert ändern durch</option>
      <ng-container *ngIf="!isColumnAssociation">
        <option [ngValue]="Todo.EXTEND" *can="model + '.actions.mass.edit'">wert erweitern mit</option>
      </ng-container>
      <ng-container *ngIf="!isColumnAssociation">
        <option [ngValue]="Todo.REPLACE" *can="model + '.actions.mass.replace'">werte ersetzen</option>
      </ng-container>
    </select>


    <input *ngIf="(selectionOption.todo || selectionOption.todo === Todo.CHANGE) && !isColumnAssociation"
           class="form-control" type="text"
           placeholder="{{ (selectionOption.todo === Todo.REPLACE) ? 'Alter Wert' : 'Wert' }}"
           style="margin-top: 2%;" [(ngModel)]="selectionOption.value1">
    <input *ngIf="(selectionOption.todo || selectionOption.todo === Todo.CHANGE) && isColumnAssociation"
           class="form-control mt-2"
           id="prop_clientAssoc"
           placeholder="Association"
           [(ngModel)]="setAssoc"
           [typeahead]="asyncAssoc"
           [typeaheadOptionField]="'name'"
           [typeaheadItemTemplate]="itm"
           typeaheadWaitMs="200">
    <ng-template let-model="item" let-index="index" #itm>
      {{ model.humanize() }}
    </ng-template>

    <div style="text-align: center; width: 100%;" *ngIf="selectionOption.todo === Todo.REPLACE">mit</div>

    <input *ngIf="selectionOption.todo === Todo.REPLACE" class="form-control" type="text"
           placeholder="{{ (selectionOption.todo === Todo.REPLACE) ? 'Neuer Wert' : 'Wert' }}"
           [(ngModel)]="selectionOption.value2">


    <button class="btn btn-block btn-primary text-left" style="margin-top: 3%;" *ngIf="selectionOption.value1 || targetAssoc"
            (click)="onEditSelections()">Änderung durchführen
    </button>

    <button class="btn btn-block btn-dark text-left" style="margin-top: 3%;" (click)="toggleShow()">
      <span *ngIf="!selectionsOnly">
        <i class="fa fa-eye-slash"></i> Nur Auswahl anzeigen
      </span>
      <span *ngIf="selectionsOnly">
        <i class="fa fa-eye"></i> Alles anzeigen
      </span>
    </button>
    <button class="btn btn-block btn-danger text-left"
            (click)="deleteInfo()" (dblclick)="deleteSelections()" *can="model + '.actions.mass.delete'">
      <i class="fa fa-trash-o"></i> Einträge löschen
    </button>
    <button class="btn btn-block text-danger" (click)="clearSelection()">
      <i class="fa fa-times"></i> Auswahl aufheben
    </button>
  </div>
</div>
