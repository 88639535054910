import { Injectable } from '@angular/core';
import {Partner, PartnerList} from './partner';
import {BaseService} from '../base/base.service';
import {PartnerSerializer} from 'src/app/partners/partner.serializer';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends BaseService<Partner, PartnerList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'partners' ;
    this.serializer = new PartnerSerializer();
  }

  create(obj: Partner): Observable<Partner> {
    return this.http.post(environment.base_url + this.endpoint, this.serializer.toJson(obj)).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  updateFile(id: number, attribute: 'cover' | 'logo', file: File): Observable<Partner> {
    const url = `${environment.base_url}${this.endpoint}/${id}`;

    const formData = new FormData();
    formData.append(`partner[${attribute}]`, file);

    return this.http.put<Partner>(url, formData).pipe(
      map(res => this.serializer.fromJson(res))
    );
  }

  updateTerminals(id: number): Observable<{terminals_informed: number}> {
    return this.http.post<{terminals_informed: number}>(`${environment.base_url + this.endpoint}/${id}/update_terminals`, null);
  }

  kpiExport(id: number): Observable<void> {
    return this.http.post<void>(`${environment.base_url}${this.endpoint}/${id}/kpi_export`, null);
  }
}
