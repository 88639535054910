import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Tablet } from '../tablets/tablet';

export class PosMessage {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'status', prop: 'status', type: ColumnType.Number, standard: true}),
    new Column({name: 'not_before', prop: 'not_before', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'not_after', prop: 'not_after', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'command', prop: 'command', type: ColumnType.String, standard: true}),
    new Column({name: 'payload', prop: 'payload', type: ColumnType.String}),
    new Column({name: 'result', prop: 'result', type: ColumnType.String}),
    new Column({name: 'error', prop: 'error', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Tablet', prop: 'tablet', association: 'belongs_to', columns: Tablet.allColumns}),
  ];

  // Simple Columns
  id: number;
  tablet_id: number;
  status: number;
  not_before: boolean;
  not_after: boolean;
  command: string;
  payload: string;
  result: string;
  error: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  // Associations
  tablet: Tablet;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return `PosMessage #${this.id}`;
  }
}

export class PosMessageList {
  count: number;
  data: PosMessage[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
