import {Component, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {PanelStateService} from './shared/ui/panel-state.service';
import { ReleaseMonitorService } from './release-monitor/release-monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  authenticated: boolean;
  panelStates: any;

  constructor (public authService: AuthService,
               public panelState: PanelStateService,
               private releaseMonitorService: ReleaseMonitorService) { }

  ngOnInit(): void {
    this.authService.authenticated().subscribe(auth => {
      this.authenticated = auth;
    });

    this.panelState.panelStates.subscribe(panelStates => {
      this.panelStates = panelStates;
    });
  }
}
