import {Administrator} from './administrator';
import {BaseSerializer} from '../base/base.serializer';

export class AdministratorSerializer extends BaseSerializer<Administrator> {
  fromJson(json: any): Administrator {
    const serialized = new Administrator(json);
    return serialized;
  }

  toJson(obj: Administrator): string {
    let params: any = {administrator: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
