import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Email} from '../emails/email';
import {AudienceDefinition} from '../audience_definitions/audience_definition';
import {AutomationEvent} from '../automation_events/automation_event';
import {MessageStat} from '../message_stats/message_stat';
import {Partner} from '../partners/partner';
import {SpecialPointsEvent} from '../pos_experiences/models/special-points-event';
import {Deal} from '../deals/deal';
import {ContentElement} from '../content_elements/content_element';

export enum CampaignStatus {
  pristine,
  reviewing,
  reviewed,
  sending,
  sent,
  draft
}

export class Campaign {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'G', prop: 'campaign_goal', type: ColumnType.Custom, standard: true, order: 2, flexGrow: .1}),
    new Column({name: 'Status', prop: 'status', type: ColumnType.Custom, standard: true, order: 3, flexGrow: .15, options: CampaignStatus}),
    new Column({name: 'Betreff', prop: 'subject_line', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'Titel', prop: 'title', type: ColumnType.String}),
    new Column({name: 'delivery_period', prop: 'delivery_period', type: ColumnType.Number}),
    new Column({name: 'intellideliver_start', prop: 'intellideliver_start', type: ColumnType.DateTime}),
    new Column({name: 'intellideliver_end', prop: 'intellideliver_end', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.Date, standard: true, order: 5, flexGrow: .4}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Untertitel', prop: 'subtitle', type: ColumnType.String}),
    new Column({name: 'force_delivery_to_unconfirmed_emails', prop: 'force_delivery_to_unconfirmed_emails', type: ColumnType.Boolean}),
    new Column({name: 'last_run_on', prop: 'last_run_on', type: ColumnType.Date}),
    new Column({name: 'Sender', prop: 'sender_data', type: ColumnType.Polymorphic, standard: true, order: 0, flexGrow: .6}),
    // Associated Columns
    new Column({name: 'Zielgruppe', prop: 'audience_definition', association: 'belongs_to', columns: AudienceDefinition.allColumns}),
    new Column({
      name: 'MessageStat', prop: 'message_stat', association: 'belongs_to', columns: MessageStat.allColumns,
      standardProps: 'email_open_count,email_click_count', editable: false, standard: true, order: 4, showAssoc: false
    })
  ];

  // Simple Columns
  id: number;
  campaign_goal: string;
  campaign_type: string;
  status: CampaignStatus;
  subject_line: string;
  title: string;
  delivery_period: string;
  intellideliver_start: boolean;
  intellideliver_end: boolean;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  audience_definition_id: number;
  subtitle: string;
  force_delivery_to_unconfirmed_emails: boolean;
  last_run_on: Date;
  message_stat_id: number;
  redeemers: number;
  reservers: number;
  revenues: { email: number, mobile: number, total: number, email_count: number, mobile_count: number };
  scheduledsend_at: Date;
  sent_at: Date;
  stats: any;
// Associations
  email: Email[];
  audience_definition: AudienceDefinition;
  content_elements: ContentElement[];
  automation_event: AutomationEvent[];
  message_stat: MessageStat;
  deal: Deal | Partial<Deal>;
  sender: Partner;
  special_points_event: SpecialPointsEvent | Partial<SpecialPointsEvent>;

  constructor(v: Partial<Campaign> = {}) {
    Object.assign(this, v);
    this.content_elements = v.content_elements ? v.content_elements.map(contentElement => new ContentElement(contentElement)) : [];
    this.created_at = v.created_at ? new Date(v.created_at) : new Date();
    this.audience_definition = new AudienceDefinition(v.audience_definition);
    if (v.content_elements != null) {
      this.content_elements = v.content_elements.map((ce) => {
        return new ContentElement(ce);
      });
      this.content_elements.sort((a, b) => a.position - b.position);

      for (const ce of this.content_elements) {
        if (ce.element_type === 'deal') {
          this.deal = ce.deal;
          if (!!ce.special_points_event) {
            this.special_points_event = ce.special_points_event;
          }
        }
        if (!!ce.special_points_event) {
          ce.special_points_event = new SpecialPointsEvent(ce.special_points_event);
          this.special_points_event = ce.special_points_event;
        }
      }
    }

    if (!!v.scheduledsend_at) {
      this.scheduledsend_at = new Date(v.scheduledsend_at);
    }
    if (!!v.sent_at) {
      this.sent_at = new Date(v.sent_at);
    }
  }

  get emailOpenUniq(): number {
    return this.revenues.email_count;
  }

  get mobileReadUniq(): number {
    return this.revenues.mobile_count;
  }

  humanize() {
    return this.subject_line;
  }
}

export class CampaignList {
  count: number;
  data: Campaign[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
