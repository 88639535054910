import {AffiliateCategory} from './affiliate_category';
import {BaseSerializer} from '../base/base.serializer';

export class AffiliateCategorySerializer extends BaseSerializer<AffiliateCategory> {
  fromJson(json: any): AffiliateCategory {
    const serialized = new AffiliateCategory(json);
    return serialized;
  }

  toJson(obj: AffiliateCategory): string {
    let params: any = {affiliate_category: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
