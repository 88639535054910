export class Bookmark {
  id: number;
  owner_id: number;
  visibility_id: number;
  name: string;
  base_path: string;
  favorite: boolean;
  path: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class BookmarkList {
  count: number;
  data: Bookmark[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
