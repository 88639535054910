import { Injectable } from '@angular/core';
import { AffiliateMerchant, AffiliateMerchantList } from './affiliate_merchant';
import { BaseService } from '../base/base.service';
import { AffiliateMerchantSerializer } from 'src/app/affiliate_merchants/affiliate_merchant.serializer';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AffiliateMerchantService extends BaseService<AffiliateMerchant, AffiliateMerchantList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'affiliate_merchants';
    this.serializer = new AffiliateMerchantSerializer();
  }

  public uploadCustomLogo(id: number, file: File): Observable<AffiliateMerchant> {
    const formData = new FormData();
    formData.append('affiliate_merchant[custom_logo]', file);
    return this.http.put<AffiliateMerchant>(`${environment.base_url + this.endpoint}/${id}`, formData);
  }

  public removeCustomLogo(id: number): Observable<AffiliateMerchant> {
    return this.http.put<AffiliateMerchant>(`${environment.base_url + this.endpoint}/${id}`, {
      affiliate_merchant: {remove_custom_logo: true}
    });
  }
}
