import { Injectable } from '@angular/core';
import {Card, CardList} from './card';
import {BaseService} from '../base/base.service';
import {CardSerializer} from 'src/app/cards/card.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CardService extends BaseService<Card, CardList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'cards' ;
    this.serializer = new CardSerializer();
  }
}
