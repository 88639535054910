import {Message} from './message';
import {BaseSerializer} from '../base/base.serializer';
import {CampaignSerializer} from '../campaigns/campaign.serializer';
import {DealAutomationSerializer} from '../deal_automations/deal_automation.serializer';
import {MessageStatSerializer} from '../message_stats/message_stat.serializer';

export class MessageSerializer extends BaseSerializer<Message> {
  fromJson(json: any): Message {
    const serialized = new Message(json);
    if (json.campaign) {
      serialized.campaign = new CampaignSerializer().fromJson(json.campaign);
    }
    if (json.deal_automation) {
      serialized.deal_automation = new DealAutomationSerializer().fromJson(json.deal_automation);
    }
    if (json.message_stat) {
      serialized.message_stat = new MessageStatSerializer().fromJson(json.message_stat);
    }
    return serialized;
  }

  toJson(obj: Message): string {
    let params: any = {message: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
