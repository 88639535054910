import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Client} from '../clients/client';
import {MerchantAccess} from '../merchant-accesses/merchant-access';

export class Merchant {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .1}),
    new Column({name: 'Benutzername', prop: 'username', type: ColumnType.String, standard: true, order: 2}),
    new Column({name: 'Deaktiviert', prop: 'is_disabled', type: ColumnType.Boolean}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns,
    standard: true, standardProps: 'name', order: 1}),
  ];

  // Simple Columns
  id: number;
  username: string;
  decrypted_password: string;
  is_disabled: boolean;
  client_id: number;
  password: string;
  password_confirmation: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  client: Client;
  merchant_accesses: MerchantAccess[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.username;
  }
}

export class MerchantList {
  count: number;
  data: Merchant[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
