import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
// import {Campaign} from '../campaigns/campaign';
import {Deal} from '../deals/deal';
import {Asset} from '../assets/asset';
import {SpecialPointsEvent} from '../pos_experiences/models/special-points-event';

export class ContentElement {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'position', prop: 'position', type: ColumnType.Number, standard: true}),
    new Column({name: 'element_type', prop: 'element_type', type: ColumnType.Number, standard: true}),
    new Column({name: 'Daten', prop: 'data', type: ColumnType.String}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    // new Column({name: 'Kampagne', prop: 'campaign', association: 'belongs_to', columns: Campaign.allColumns}),
    new Column({name: 'Deal', prop: 'deal', association: 'belongs_to', columns: Deal.allColumns}),
    new Column({name: 'asset', prop: 'asset', association: 'belongs_to', columns: Asset.allColumns})
  ];

  static element_types: Object = {
    heading1: 'Überschrift 1',
    heading2: 'Überschrift 2',
    heading3: 'Überschrift 3',
    text: 'Text',
    asset: 'Bild',
    button: 'Button',
    deal: 'Deal',
    special_points_event: 'Extra-Punkte Event'
  };

  // Simple Columns
  id: number;
  campaign_id: number;
  position: number;
  element_type: string;
  deal_id: number;
  asset_id: number;
  data: any;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  _destroy: boolean;
  // Associations
  // campaign: Campaign;
  deal: Deal;
  asset: Asset;
  special_points_event: Partial<SpecialPointsEvent>;


  constructor(v: Partial<ContentElement> = {}) {
    Object.assign(this, v);
    this.data = new ContentElementData(v.data);
    this.deal = v.deal ? new Deal(v.deal) : undefined;
  }

  valid(): boolean {
    switch (this.element_type) {
      case 'heading1':
      case 'heading2':
      case 'heading3':
      case 'text':
        return (this.data && this.data.text && this.data.text.length > 0);
      case 'asset':
        return (this.asset && this.asset.id > 0);
      case 'button':
        return (this.data && this.data.text && this.data.text.length > 0 && this.data.link && this.data.link.length > 5);
        // case 'deal':
        //   return this.deal.valid();
      default:
        return true;
    }
  }

  humanize() {
    return this.campaign_id;
  }
}

export class ContentElementList {
  count: number;
  data: ContentElement[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ContentElementData {
  text: string;
  link: string;
  deal_id: number;
  size: number;
  methodReference: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
