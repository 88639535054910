import {Transaction} from './transaction';
import {BaseSerializer} from '../base/base.serializer';
import {MembershipSerializer} from '../memberships/membership.serializer';
import {TerminalSerializer} from '../terminals/terminal.serializer';
import {BusinessSerializer} from '../businesses/business.serializer';
import {TokenSerializer} from '../tokens/token.serializer';
import {RewardSerializer} from '../rewards/reward.serializer';
// import {RewardSerializer} from '../reward_with_deleteds/reward_with_deleted.serializer';
import {CouponSerializer} from '../coupons/coupon.serializer';

export class TransactionSerializer extends BaseSerializer<Transaction> {
  fromJson(json: any): Transaction {
    const serialized = new Transaction(json);
    if (json.membership) {
      serialized.membership = new MembershipSerializer().fromJson(json.membership);
    }
    if (json.terminal) {
      serialized.terminal = new TerminalSerializer().fromJson(json.terminal);
    }
    if (json.business) {
      serialized.business = new BusinessSerializer().fromJson(json.business);
    }
    if (json.token) {
      serialized.token = new TokenSerializer().fromJson(json.token);
    }
    if (json.reward) {
      serialized.reward = new RewardSerializer().fromJson(json.reward);
    }
    if (json.reward_with_deleted) {
      serialized.reward_with_deleted = new RewardSerializer().fromJson(json.reward_with_deleted);
    }
    if (json.coupon) {
      serialized.coupon = new CouponSerializer().fromJson(json.coupon);
    }
    return serialized;
  }

  toJson(obj: Transaction): string {
    let params: any = {transaction: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
