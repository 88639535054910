import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ColumnType } from '../../shared/enums/column-type.enum';
import { Page } from '../../shared/models/page';
import { Sort } from '../../shared/models/sort';
import { Group, QuickFilter } from '../../filter/group';
import { Column } from '../../shared/models/column';
import { ColumnMode, DatatableComponent, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ListService } from '../list.service';
import { FilterService } from '../../filter/filter.service';
import { UrlService } from '../url/url.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseList } from '../base.list';
import { BaseService } from '../base.service';

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseListComponent implements OnInit, AfterViewChecked, OnDestroy {

  private ngUnsubscribe = new Subject<void>();
  public refreshEvent$ = new Subject<void>();
  public refreshed = new EventEmitter<void>(true);

  cssClasses = {
    sortAscending: 'fa fa-sort-up',
    sortDescending: 'fa fa-sort-down',
    pagerLeftArrow: 'fa fa-angle-left',
    pagerRightArrow: 'fa fa-angle-right',
    pagerPrevious: 'fa fa-angle-double-left',
    pagerNext: 'fa fa-angle-double-right'
  };

  selected = [];
  data = [];
  page: Page = {size: 99999} as Page; // Page Object
  sort: Sort[] = [new Sort()]; // Sort Array
  group: Group[] = []; // Filter Groups
  columns: Column[] = []; // Current shown columns

  // Options for ngx-datatable
  @Input()
  selectionType = SelectionType.multiClick;

  @Input()
  sortType = SortType.multi;

  @Input()
  columnMode = ColumnMode.flex;

  @Input()
  fieldsToQuery: string[] = [];

  // Input only
  @Input()
  all: Column[]; // All possible columns

  @Input()
  model: string; // Name of the model ('businesses', 'areas' etc)

  @Input()
  quick?: QuickFilter[]; // Pre-defined QuickFilters

  @Input()
  q?: string; // Ransack q param as string

  @Input()
  customCellTemplate: TemplateRef<any>;

  @Input()
  customFooterTemplate: TemplateRef<any>;

  // References
  @ViewChild(DatatableComponent, {static: true}) table: DatatableComponent; // Datatable

  // Service injection
  dataService: BaseService<any, any>; // Inject correct service by using model string from above

  // Template Declarations
  ColumnType = ColumnType;

  // Variables
  currentColumnsString = ''; // Currently shown columns as string

  constructor(private route: ActivatedRoute,
              private ref: ChangeDetectorRef,
              private domElement: ElementRef,
              public _list: ListService,
              private _filter: FilterService,
              private _url: UrlService) {
  }

  ngOnInit(): void {
    this.dataService = this._list.getServiceEndpoint(this.model);

    this.refreshEvent$.pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap(_ => this.refreshPage())
    ).subscribe();

    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(params => {
        if (this.quick && !Object.keys(params).includes('q')) {
          this.setDefaultQuickFilters();
          return false;
        }
        return true;
      }),
      tap(params => this.updateOptionsFromParams(params)),
      tap(_ => this.refreshEvent$.next())
    ).subscribe();
  }

  // force immediate table header resize
  ngAfterViewChecked() {
    this.table.recalculate();
    this.ref.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onReorder(e) {
    this._url.reorderColumns(this.currentColumnsString, e.prevValue, e.newValue);
  }

  onSort(sort): void {
    this._list.toURL('sort', sort.sorts.map(s => ({[s.prop]: s.dir})), 1);
  }

  private setDefaultQuickFilters() {
    const standards: Group[] = this.quick
      .filter(qf => qf.standard)
      .map(qf => qf.group);

    const toURL = this._filter.filterToUrl(standards);
    return this._list.toURL('q', toURL, 0);
  }

  private updateOptionsFromParams(params: Params) {
    this.sort = this._url.urlToSort(params);
    this.columns = this._url.urlToColumns(params, this.all);
    this.currentColumnsString = this._url.urlToColumnString(params, this.all);
    if (this.q) {
      this.group = this._filter.urlToFilter(this.q, this.all);
    } else {
      this.group = this._url.urlToFilter(params, this.all);
    }
    this.quick = this._url.manageQuickFilter(params, this.quick);
    this.page.offset = this._url.urlToPage(params);
  }

  private refreshPage(): Observable<any> {
    return this.dataService.fetchPage(this.page, this.group, this.sort, this.getFieldsToFetch())
      .pipe(tap(
        (dataList: any) => {
          this.updateData(dataList);
        },
        (err) => {
          console.error(`ERROR in BaseListComponent:`, err);
        }
      ));
  }

  private getFieldsToFetch(): string {
    const visibleFields = this._url.fieldsToFetch(this.currentColumnsString);
    return this.fieldsToQuery.concat(visibleFields).join(',');
  }

  private updateData(dataList: BaseList<any>) {
    this.data = dataList.data;
    this.selected = this._list.getSelections(this.selected, this.data);
    this.page.totalElements = dataList.count;
    this.ref.markForCheck();
    this.refreshed.next();
  }
}
