import {Component, HostBinding, OnInit} from '@angular/core';
import {PanelStateService} from '../panel-state.service';

@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.scss']
})
export class LeftComponent implements OnInit {

  @HostBinding('class') state = 'full';
  isCollapsed: boolean;

  constructor(public panelState: PanelStateService) { }

  ngOnInit() {
    this.panelState.panelStates.subscribe(states => this.state = states.left);
  }

}
