import {Injectable} from '@angular/core';
import {MerchantAccess, MerchantAccessList} from './merchant-access';
import {BaseService} from '../base/base.service';
import {MerchantAccessSerializer} from 'src/app/merchant-accesses/merchant-access.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MerchantAccessService extends BaseService<MerchantAccess, MerchantAccessList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'merchant_accesses' ;
    this.serializer = new MerchantAccessSerializer();
  }
}
