import {ContentElement} from './content_element';
import {BaseSerializer} from '../base/base.serializer';
// import {CampaignSerializer} from '../campaigns/campaign.serializer';
import {DealSerializer} from '../deals/deal.serializer';
import {AssetSerializer} from '../assets/asset.serializer';

export class ContentElementSerializer extends BaseSerializer<ContentElement> {
  fromJson(json: any): ContentElement {
    const serialized = new ContentElement(json);
    // if (json.campaign) {
    //   serialized.campaign = new CampaignSerializer().fromJson(json.campaign);
    // }
    if (json.deal) {
      serialized.deal = new DealSerializer().fromJson(json.deal);
    }
    if (json.asset) {
      serialized.asset = new AssetSerializer().fromJson(json.asset);
    }
    return serialized;
  }

  toJson(obj: ContentElement): string {
    let params: any = {content_element: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
