import { Injectable } from '@angular/core';
import { AddonSubscription, AddonSubscriptionList } from './addon_subscription';
import { BaseService } from '../base/base.service';
import { AddonSubscriptionSerializer } from 'src/app/addon_subscriptions/addon_subscription.serializer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AddonTypes } from './addon_types';
import { environment } from '../../environments/environment';
import { CustomData } from '../base/custom-list/custom-data';

@Injectable({
  providedIn: 'root'
})
export class AddonSubscriptionService extends BaseService<AddonSubscription, AddonSubscriptionList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'addon_subscriptions';
    this.serializer = new AddonSubscriptionSerializer();
  }

  public customQuery(filters: any) {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(filters)) {
      if (value != null && value !== 'null') {
        params = params.append(key, value.toString());
      }
    }
    return this.http.get<CustomData>(`${environment.base_url}addon_subscriptions_custom`, {params: params});
  }

  public subscribe(partner_id: number, addon_type: AddonTypes) {
    return this.http.put(`${environment.base_url + this.endpoint}/subscribe`, {}, {
        params: {partner_id: partner_id.toString(), addon_type}
      }
    );
  }

  unsubscribe(partner_id: number, addon_type: AddonTypes) {
    return this.http.delete(`${environment.base_url + this.endpoint}/unsubscribe`, {
        params: {partner_id: partner_id.toString(), addon_type}
      }
    );
  }
}
