import {MobileSession} from './mobile_session';
import {BaseSerializer} from '../base/base.serializer';
import {DeviceSerializer} from '../devices/device.serializer';
import {UserSerializer} from '../users/user.serializer';

export class MobileSessionSerializer extends BaseSerializer<MobileSession> {
  fromJson(json: any): MobileSession {
    const serialized = new MobileSession(json);
    if (json.device) {
      serialized.device = new DeviceSerializer().fromJson(json.device);
    }
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    return serialized;
  }

  toJson(obj: MobileSession): string {
    let params: any = {mobile_session: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
