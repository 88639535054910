import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Group, QuickFilter } from '../../filter/group';
import { Column } from '../../shared/models/column';
import { ColumnType } from '../../shared/enums/column-type.enum';
import { Router } from '@angular/router';
import { Sort } from '../../shared/models/sort';

@Component({
  selector: 'app-split-view-table',
  templateUrl: './split-view-table.component.html',
  styleUrls: ['./split-view-table.component.scss']
})
export class SplitViewTableComponent implements OnInit {

  @Input()
  model: string;

  @Input()
  all: Column[] = [];

  @Input()
  quickFilters: QuickFilter[] = [];

  @Input()
  sort = [new Sort()];

  @Input()
  staticProps: string[] = [];

  @Input()
  customCellTemplate: TemplateRef<any>;

  @Input()
  customFooterTemplate: TemplateRef<any>;

  @Input()
  forceFullScreen = true;

  @Output()
  selectedChange = new EventEmitter<any[]>();

  columns: Column[] = [];
  selected: any[] = [];
  data: any[] = [];
  group: Group[] = [];

  ColumnType = ColumnType;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onSelectedChange(selected: any[]) {
    this.selected = selected;
    this.selectedChange.emit(this.selected);
    this.router.navigate([], {queryParams: {selected: selected.map(s => s.id).join(',')}, queryParamsHandling: 'merge'});
  }

}
