import {AdventCalendar, PosExperience, WheelOfLuck} from './pos_experience';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';
import {BusinessSerializer} from '../businesses/business.serializer';

export class PosExperienceSerializer extends BaseSerializer<PosExperience> {
  fromJson(json: any): PosExperience {
    if (json.partner) {
      json.partner = new PartnerSerializer().fromJson(json.partner);
    }
    if (json.business) {
      json.business = new BusinessSerializer().fromJson(json.business);
    }

    switch (json.type) {
      case 'PosExperiences::AdventCalendar':
        return new AdventCalendar(json);
      case 'PosExperiences::WheelOfLuck':
        return new WheelOfLuck(json);
    }
  }

  toJson(obj: PosExperience): string {
    let params: any = {pos_experience: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
