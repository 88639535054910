import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { AffiliateDeal } from '../affiliate_deals/affiliate_deal';
import { AffiliateCategory } from '../affiliate_categories/affiliate_category';

export class AffiliateMerchant {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: 'id', prop: 'id', type: ColumnType.Number, standard: true, flexGrow: 1}),
    new Column({name: 'approved', prop: 'approved', type: ColumnType.Boolean, standard: true, flexGrow: 1}),
    new Column({name: 'favorite', prop: 'favorite', type: ColumnType.Boolean, standard: true, flexGrow: 1}),
    new Column({name: 'rank', prop: 'rank', type: ColumnType.Number, standard: true, flexGrow: 1}),
    new Column({name: 'external_rank', prop: 'external_rank', type: ColumnType.Number, standard: true, flexGrow: 2}),
    new Column({name: 'unique_id', prop: 'unique_id', type: ColumnType.String, standard: false}),
    new Column({name: 'name', prop: 'name', type: ColumnType.String, standard: true, flexGrow: 10}),
    new Column({name: 'logo_url', prop: 'logo_url', type: ColumnType.String, standard: false}),
    new Column({name: 'custom_logo', prop: 'custom_logo', type: ColumnType.String, standard: false}),
    new Column({name: 'website_url', prop: 'website_url', type: ColumnType.String, standard: false}),
    new Column({name: 'published', prop: 'published', type: ColumnType.Boolean, standard: true, flexGrow: 1}),
    new Column({name: 'data', prop: 'data', type: ColumnType.JSON}),
    new Column({name: 'affiliate_category_id', prop: 'affiliate_category_id', type: ColumnType.Number}),
    new Column({name: 'deleted_at', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'created_at', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'updated_at', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'affiliate_category', prop: 'affiliate_category', association: 'belongs_to', columns: AffiliateCategory.allColumns}),
  ];

  // Simple Columns
  id: number;
  approved: boolean;
  favorite: boolean;
  rank: number;
  unique_id: string;
  name: string;
  logo_url: string;
  custom_logo: string;
  custom_logo_url: string;
  website_url: string;
  external_rank: number;
  affiliate_category_id: number;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  published: boolean;
  data: string;
  // Associations
  affiliate_deals: AffiliateDeal[];
  affiliate_category: AffiliateCategory;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return `${this.name} (${this.id})`;
  }
}

export class AffiliateMerchantList {
  count: number;
  data: AffiliateMerchant[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
