import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftComponent } from './ui/left/left.component';
import { TopComponent } from './ui/top/top.component';
import { RightComponent } from './ui/right/right.component';
import { BookmarksComponent } from './ui/left/bookmarks/bookmarks.component';
import { NavigationComponent } from './ui/left/navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterComponent } from '../filter/filter.component';
import { ListComponent } from '../base/list/list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PipesModule } from './pipes/pipes.module';
import { MiniListComponent } from '../base/mini-list/mini-list.component';
import { CanDirective } from './permissions/can.directive';
import { SearchComponent } from './ui/top/search/search.component';
import { NavItemComponent } from './ui/left/navigation/nav-item/nav-item.component';
import { BookmarkComponent } from './ui/left/bookmarks/bookmark/bookmark.component';
import { NotesComponent } from './ui/right/notes/notes.component';
import { TreeviewModule } from 'ngx-treeview';
import { LastVisitComponent } from './ui/left/last-visit/last-visit.component';
import { ConditionComponent } from '../filter/condition/condition.component';
import { DocumentComponent } from './components/document/document.component';
import { CopyDirective } from './directives/copy.directive';
import { ValueComponent } from './components/value/value.component';
import { CustomColumnComponent } from '../base/list/custom-column/custom-column.component';
import { ListControlsComponent } from '../base/list/list-controls/list-controls.component';
import { SelectionControlsComponent } from '../base/list/selection-controls/selection-controls.component';
import { CustomListComponent } from '../base/custom-list/custom-list.component';
import { RightPanelContentComponent } from './right-panel-content/right-panel-content.component';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { TableControlsComponent } from '../base/base-list/table-controls/table-controls.component';
import { PageableDirective } from '../base/base-list/directives/pageable.directive';
import { SidebarTableDirective } from '../base/base-list/directives/sidebar-table.directive';
import { RansackTableDirective } from '../base/base-list/directives/ransack-table.directive';
import { SplitViewTableComponent } from '../base/split-view-table/split-view-table.component';
import { TypeaheadSelectComponent } from './components/typeahead-select/typeahead-select.component';
import { BootstrapFormControlDirective } from './directives/bootstrap-form-control.directive';
import { DateInputDirective } from './directives/date-input.directive';
import { ConfirmClickDirective } from './directives/confirm-click.directive';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownConfig, BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxDatatableModule,
    PipesModule,
    TreeviewModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule,
    ModalModule.forRoot(),
    BsDatepickerModule,
    AccordionModule.forRoot(),
    TypeaheadModule,
    ReactiveFormsModule
  ],
  declarations: [
    CanDirective,
    LeftComponent,
    TopComponent,
    RightComponent,
    BookmarksComponent,
    BookmarkComponent,
    LastVisitComponent,
    NavigationComponent,
    FilterComponent,
    BaseListComponent,
    TableControlsComponent,
    PageableDirective,
    SidebarTableDirective,
    ListComponent,
    MiniListComponent,
    CustomListComponent,
    SearchComponent,
    NavItemComponent,
    NotesComponent,
    ConditionComponent,
    DocumentComponent,
    CopyDirective,
    ValueComponent,
    CustomColumnComponent,
    ListControlsComponent,
    SelectionControlsComponent,
    RightPanelContentComponent,
    RansackTableDirective,
    SplitViewTableComponent,
    SelectionControlsComponent,
    TypeaheadSelectComponent,
    BootstrapFormControlDirective,
    DateInputDirective,
    ConfirmClickDirective
    // BsDropdownDirective
  ],
  providers: [
    BsDropdownConfig
  ],
  exports: [
    LeftComponent,
    TopComponent,
    RightComponent,
    BookmarksComponent,
    BookmarkComponent,
    NavigationComponent,
    FilterComponent,
    ListComponent,
    MiniListComponent,
    SearchComponent,
    NotesComponent,
    CopyDirective,
    CanDirective,
    BsDropdownDirective,
    ValueComponent,
    SelectionControlsComponent,
    CustomListComponent,
    RightPanelContentComponent,
    BaseListComponent,
    TableControlsComponent,
    SidebarTableDirective,
    RansackTableDirective,
    SplitViewTableComponent,
    CustomColumnComponent,
    TypeaheadSelectComponent,
    BootstrapFormControlDirective,
    DateInputDirective,
    ConfirmClickDirective,
  ]
})
export class SharedModule {
}
