import {Token} from './token';
import {BaseSerializer} from '../base/base.serializer';
import {UserSerializer} from '../users/user.serializer';

export class TokenSerializer extends BaseSerializer<Token> {
  fromJson(json: any): Token {
    const serialized = new Token(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    return serialized;
  }

  toJson(obj: Token): string {
    let params: any = {token: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
