<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
    *ngIf="favoritesOnly">
  <span>Favoriten</span>
  <div class="d-flex align-items-center text-muted">
    <a class="btn" (click)="bookmarkService.toggleEdit()">
      <i *ngIf="editMode" class="fa fa-pencil" style="color: orange;"></i>
      <i *ngIf="!editMode" class="fa fa-pencil"></i>
    </a>
    <a class="btn" (click)="bookmarkInput.show()">
      <i class="fa fa-plus-circle" style="color: green;"></i>
    </a>
  </div>
</h6>

<div *ngFor="let bookmark of bookmarks">
  <div class="bookmarks" *ngIf="bookmark.favorite">
    <app-bookmark [bookmark]="bookmark" [editMode]="editMode"></app-bookmark>
  </div>
</div>

<div bsModal #bookmarkInput="bs-modal" class="modal fade">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Lesezeichen benennen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bookmarkInput.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Wie möchtest du das Lesezeichen nennen?
        <input type="text" class="form-control d-inline-flex" placeholder="Lesezeichen benennen"
               (keydown.enter)="onAddBookmark(); bookmarkInput.hide()" [(ngModel)]="newBookmark.name" autofocus>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="bookmarkInput.hide()">Abbrechen</button>
        <button type="button" class="btn btn-primary" (click)="onAddBookmark(); bookmarkInput.hide()">Speichern</button>
      </div>
    </div>
  </div>
</div>


