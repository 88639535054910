import { Injectable } from '@angular/core';
import {AutomationEvent, AutomationEventList} from './automation_event';
import {BaseService} from '../base/base.service';
import {AutomationEventSerializer} from 'src/app/automation_events/automation_event.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutomationEventService extends BaseService<AutomationEvent, AutomationEventList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'automation_events' ;
    this.serializer = new AutomationEventSerializer();
  }
}
