import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Device } from '../devices/device';
import { Area } from '../areas/area';

export class DeviceLocation {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'lat', prop: 'lat', type: ColumnType.Number, standard: true}),
    new Column({name: 'lng', prop: 'lng', type: ColumnType.Number, standard: true}),
    new Column({name: 'altitude', prop: 'altitude', type: ColumnType.Number, standard: true}),
    new Column({name: 'accuracy', prop: 'accuracy', type: ColumnType.Number, standard: true}),
    new Column({name: 'measured_at', prop: 'measured_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Gerät', prop: 'device', association: 'belongs_to', columns: Device.allColumns}),
    new Column({name: 'Gebiet', prop: 'area', association: 'belongs_to', columns: Area.allColumns}),
  ];

  // Simple Columns
  id: number;
  device_id: number;
  lat: number;
  lng: number;
  altitude: number;
  accuracy: number;
  area_id: number;
  measured_at: boolean;
  deleted_at: boolean;
  // Associations
  device: Device;
  area: Area;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.device_id;
  }
}

export class DeviceLocationList {
  count: number;
  data: DeviceLocation[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
