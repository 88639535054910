import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Campaign} from '../campaigns/campaign';
import {DealAutomation} from '../deal_automations/deal_automation';
import {MessageStat} from '../message_stats/message_stat';
// import { Email } from '../emails/email';
import {MobileMessage} from '../mobile_messages/mobile_message';
import {MessageAttachment} from '../message_attachments/message_attachment';

export class Message {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Typ', prop: 'type', type: ColumnType.String, standard: true}),
    new Column({name: 'Status', prop: 'status', type: ColumnType.Number, standard: true}),
    // new Column({name: 'Sender', prop: 'sender_data', type: ColumnType.Polymorphic, standard: true}),
    new Column({name: 'Recipient', prop: 'recipient_data', type: ColumnType.Polymorphic, standard: true, flexGrow: 2}),
    new Column({name: 'Recipient #', prop: 'recipient_id', type: ColumnType.String, standard: true, flexGrow: 0}),
    new Column({name: 'Recipient Typ', prop: 'recipient_type', type: ColumnType.String, standard: true, flexGrow: 0}),
    new Column({name: 'Sender #', prop: 'sender_id', type: ColumnType.String}),
    new Column({name: 'Sender Typ', prop: 'sender_type', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Kampagne', prop: 'campaign', association: 'belongs_to', columns: Campaign.allColumns}),
    new Column({name: 'Dealautomatisierung', prop: 'deal_automation', association: 'belongs_to', columns: DealAutomation.allColumns}),
    new Column({name: 'message_stat', prop: 'message_stat', association: 'belongs_to', columns: MessageStat.allColumns}),
    // new Column({name: 'E-Mail', prop: 'email', association: 'has_one', columns: Email.allColumns}),
  ];

  // Simple Columns
  id: number;
  type: string;
  status: number;
  campaign_id: number;
  deal_automation_id: number;
  message_stat_id: number;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  recipient_email: string;
  // Associations
  campaign: Campaign;
  deal_automation: DealAutomation;
  message_stat: MessageStat;
  // email: Email;
  mobile_message: MobileMessage[];
  message_attachment: MessageAttachment[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.type;
  }
}

export class MessageList {
  count: number;
  data: Message[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
