import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Predicate} from '../shared/enums/predicate.enum';
import {Column} from '../shared/models/column';
import {Filter} from './filter';
import {Group} from './group';
import {TreeviewConfig} from 'ngx-treeview';
import { ListService } from '../base/list.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() columns: Column[];
  @Input() group: Group[];
  predicate = Predicate;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: true,
    maxHeight: 400
  });

  constructor(private listService: ListService) { }

  ngOnInit() { }

  onAddCondition(index: number) {
    if (this.group[index].filter.length === 0 || this.group[index].filter[this.group[index].filter.length - 1].predicate >= 0) {
      this.group[index].filter.push(new Filter());
    }
  }

  onSubmitFilter(e) {
    if (e.filter) {
      this.group[e.groupIndex].filter[e.index] = e.filter;
      this.onChange();
    } else if (e.filter === null) {
      this.onDeleteFilter(e.groupIndex, e.index);
    }
  }

  onDeleteFilter(groupIndex: number, filterIndex: number) {
    this.group[groupIndex].filter.splice(filterIndex, 1);
    this.onChange();
  }

  onDeleteGroup(index: number) {
    this.group.splice(index, 1);
    this.onChange();
  }

  onAddGroup() {
    this.group.push(new Group());
    this.group[this.group.length - 1].m = 'and';
    this.onAddCondition(this.group.length - 1);
  }

  onChange() {
    this.listService.onFilter(this.group);
  }

}
