export enum Predicate {
  EQUAL,
  NOT_EQUAL,
  SMALLER_THAN,
  GREATER_THAN,
  EQUAL_OR_SMALLER_THAN,
  EQUAL_OR_GREATER_THAN,
  EMPTY,
  NOT_EMPTY,
  BEGINS_WITH,
  NOT_BEGINS_WITH,
  CONTAINS,
  NOT_CONTAINS,
  ENDS_WITH,
  NOT_ENDS_WITH,
  IN
}
