import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AdministratorService} from '../../administrators/administrator.service';
import {NgIf} from '@angular/common';

@Directive({
  selector: '[can]'
})
export class CanDirective implements OnInit {

  private ngIfDirective: NgIf;
  @Input('can') can: string;

  constructor(private adminService: AdministratorService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {
    this.adminService.currentAdministrator.subscribe(() => {
      if (!this.ngIfDirective) {
        this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
      }
      this.ngIfDirective.ngIf = this.adminService.allowed(this.can);
    });
  }
}
