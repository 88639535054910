import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { PermissionGuard } from './shared/permissions/permission.guard';
import { GalleryComponent } from './base/gallery/gallery.component';
import { LogoutComponent } from './auth/logout/logout.component';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {
    path: 'search',
    loadChildren: () => import('./global-search/global-search.module').then(m => m.GlobalSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'onboarding-wizard',
    loadChildren: () => import('./onboarding-wizard/onboarding-wizard.module').then(m => m.OnboardingWizardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then(m => m.MapModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'businesses.show'}
  },
  {
    path: 'automations',
    loadChildren: () => import('./automations/automations.module').then(m => m.AutomationsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: ''}
  },
  {
    path: 'area-map',
    loadChildren: () => import('./area-map/area-map.module').then(m => m.AreaMapModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'areas.show'}
  },
  {
    path: 'businesses',
    loadChildren: () => import('./businesses/businesses.module').then(m => m.BusinessesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'businesses.show'}
  },
  {
    path: 'webshops',
    loadChildren: () => import('./webshops/webshops.module').then(m => m.WebshopsModule),
    data: {permission: 'webshops.show'}
  },
  {
    path: 'webshop_requests',
    loadChildren: () => import('./webshop_requests/webshop_requests.module').then(m => m.WebshopRequestsModule),
    data: {permission: 'webshop_requests.show'}
  },
  {
    path: 'invoices',
    loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'invoices.show'}
  },
  {
    path: 'business_care',
    loadChildren: () => import('./business_care/business_care.module').then(m => m.Business_careModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'business_care.show'}
  },
  {
    path: 'admins',
    loadChildren: () => import('./administrators/administrators.module').then(m => m.AdministratorsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: ''}
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'activities.show'}
  },
  {
    path: 'assets',
    component: GalleryComponent
  },
  {
    path: 'areas',
    loadChildren: () => import('./areas/areas.module').then(m => m.AreasModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'areas.show'}
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'campaigns.show'}
  },
  {
    path: 'cards',
    loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'cards.show'}
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'categories.show'}
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'clients.show'}
  },
  {
    path: 'client_documents',
    loadChildren: () => import('./client_documents/client_documents.module').then(m => m.Client_documentsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'client_documents.show'}
  },
  {
    path: 'contracts',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'contracts.show'}
  },
  {
    path: 'deals',
    loadChildren: () => import('./deals/deals.module').then(m => m.DealsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'deals.show'}
  },
  {
    path: 'coupons',
    loadChildren: () => import('./coupons/coupons.module').then(m => m.CouponsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'coupons.show'}
  },
  {
    path: 'deal_automations',
    loadChildren: () => import('./deal_automations/deal_automations.module').then(m => m.Deal_automationsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'deal_automations.show'}
  },
  {
    path: 'devices',
    loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'devices.show'}
  },
  {
    path: 'emails',
    loadChildren: () => import('./emails/emails.module').then(m => m.EmailsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'emails.show'}
  },
  {
    path: 'email_events',
    loadChildren: () => import('./email_events/email_events.module').then(m => m.Email_eventsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'email_events.show'}
  },
  {
    path: 'invitations',
    loadChildren: () => import('./invitations/invitations.module').then(m => m.InvitationsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'invitations.show'}
  },
  {
    path: 'memberships',
    loadChildren: () => import('./memberships/memberships.module').then(m => m.MembershipsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'memberships.show'}
  },
  {
    path: 'merchants',
    loadChildren: () => import('./merchants/merchants.module').then(m => m.MerchantsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'merchants.show'}
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'messages.show'}
  },
  {
    path: 'mobile_messages',
    loadChildren: () => import('./mobile_messages/mobile_messages.module').then(m => m.Mobile_messagesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'mobile_messages.show'}
  },
  {
    path: 'mobile_sessions',
    loadChildren: () => import('./mobile_sessions/mobile_sessions.module').then(m => m.Mobile_sessionsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'mobile_sessions.show'}
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'offers.show'}
  },
  {
    path: 'partners',
    loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'partners.show'}
  },
  {
    path: 'pos_messages',
    loadChildren: () => import('./pos_messages/pos_messages.module').then(m => m.Pos_messagesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'pos_messages.show'}
  },
  {
    path: 'pos_sessions',
    loadChildren: () => import('./pos_sessions/pos_sessions.module').then(m => m.Pos_sessionsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'pos_sessions.show'}
  },
  {
    path: 'rewards',
    loadChildren: () => import('./rewards/rewards.module').then(m => m.RewardsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'rewards.show'}
  },
  {
    path: 'sepa_mandates',
    loadChildren: () => import('./sepa_mandates/sepa_mandates.module').then(m => m.Sepa_mandatesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'sepa_mandates.show'}
  },
  {
    path: 'tablets',
    loadChildren: () => import('./tablets/tablets.module').then(m => m.TabletsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'tablets.show'}
  },
  {
    path: 'teasers',
    loadChildren: () => import('./teasers/teasers.module').then(m => m.TeasersModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'teasers.show'}
  },
  {
    path: 'terminals',
    loadChildren: () => import('./terminals/terminals.module').then(m => m.TerminalsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'terminals.show'}
  },
  {
    path: 'tokens',
    loadChildren: () => import('./tokens/tokens.module').then(m => m.TokensModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'tokens.show'}
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'transactions.show'}
  },
  {
    path: 'transaction_flags',
    loadChildren: () => import('./transaction_flags/transaction_flags.module').then(m => m.Transaction_flagsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'transaction_flags.show'}
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'users.show'}
  },
  {
    path: 'shipments',
    loadChildren: () => import('./shipments/shipments.module').then(m => m.ShipmentsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'shipments.show'}
  },
  {
    path: 'pos_experiences',
    loadChildren: () => import('./pos_experiences/pos_experiences.module').then(m => m.Pos_experiencesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'pos_experiences.show'}
  },
  {
    path: 'serial_number_monitor',
    loadChildren: () => import('./serial_number_monitor/serial_number_monitor.module').then(m => m.SerialNumberMonitorModule),
    canActivate: [AuthGuard],
    data: {}
  },
  {
    path: 'affiliate_deals',
    loadChildren: () => import('./affiliate_deals/affiliate-deals.module').then(m => m.AffiliateDealsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'affiliate_deals.show'}
  },
  {
    path: 'affiliate_merchants',
    loadChildren: () => import('./affiliate_merchants/affiliate-merchants.module').then(m => m.AffiliateMerchantsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'affiliate_merchants.show'}
  },
  {
    path: 'affiliate_categories',
    loadChildren: () => import('./affiliate_categories/affiliate-categories.module').then(m => m.AffiliateCategoriesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'affiliate_categories.show'}
  },
  {
    path: 'addon_subscriptions',
    loadChildren: () => import('./addon_subscriptions/addon_subscriptions.module').then(m => m.AddonSubscriptionsModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'addon_subscriptions.show'}
  },
  {
    path: 'dunning_processes',
    loadChildren: () => import('./dunning_processes/dunning_processes.module').then(m => m.DunningProcessesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'dunning_processes.show'}
  },
  {
    path: 'personalized_card_orders',
    loadChildren: () => import('./personalized-card-orders/personalized-card-orders.module').then(m => m.PersonalizedCardOrdersModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {permission: 'personalized_card_orders.show'}
  }// GENERATOR-ROUTE
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {

}
