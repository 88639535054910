import {Invitation} from './invitation';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';
import {DeviceSerializer} from '../devices/device.serializer';

export class InvitationSerializer extends BaseSerializer<Invitation> {
  fromJson(json: any): Invitation {
    const serialized = new Invitation(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    if (json.device) {
      serialized.device = new DeviceSerializer().fromJson(json.device);
    }
    return serialized;
  }

  toJson(obj: Invitation): string {
    let params: any = {invitation: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
