import {AudienceDefinition} from './audience_definition';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class AudienceDefinitionSerializer extends BaseSerializer<AudienceDefinition> {
  fromJson(json: any): AudienceDefinition {
    const serialized = new AudienceDefinition(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: AudienceDefinition): string {
    let params: any = {audience_definition: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
