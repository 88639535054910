import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Area} from '../areas/area';
import {Client} from '../clients/client';
import {Campaign} from '../campaigns/campaign';
import {Business} from '../businesses/business';
import {Deal} from '../deals/deal';
import {Teaser} from '../teasers/teaser';
import {Reward} from '../rewards/reward';
import {Membership} from '../memberships/membership';
import {User} from '../users/user';
import {Transaction} from '../transactions/transaction';
import {Offer} from '../offers/offer';
import {Asset} from '../assets/asset';
import {Categorization} from '../categorizations/categorization';
import {Category} from '../categories/category';
// import { PartnerStat } from '../partner_stats/partner_stat';
// import {Email} from '../emails/email';
import {MerchantAccess} from '../merchant-accesses/merchant-access';
import {Merchant} from '../merchants/merchant';
import {DealAutomation} from '../deal_automations/deal_automation';

export class Partner {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Benutzername', prop: 'username', type: ColumnType.String}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true, order: 1, flexGrow: 1.2}),
    new Column({name: 'Kontakt', prop: 'contact', type: ColumnType.String}),
    new Column({name: 'Adresse', prop: 'address', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String}),
    new Column({name: 'E-Mail', prop: 'email', type: ColumnType.String}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'logo', prop: 'logo', type: ColumnType.Image, editable: false, standard: true, flexGrow: 0.05, order: 0}),
    new Column({name: 'cover', prop: 'cover', type: ColumnType.String, editable: false}),
    new Column({name: 'Telefon', prop: 'phone', type: ColumnType.String}),
    new Column({name: 'wallpaper', prop: 'wallpaper', type: ColumnType.String}),
    new Column({name: 'Stempel', prop: 'stamp_value', type: ColumnType.Number, standard: true, order: 5, flexGrow: .1}),
    new Column({name: 'Veröffentlicht am', prop: 'published_at', type: ColumnType.DateTime}),
    new Column({name: 'M', prop: 'memberships_count', type: ColumnType.Number, standard: true, order: 2, flexGrow: .1}),
    new Column({name: 'T', prop: 'transactions_count', type: ColumnType.Number, standard: true, order: 3, flexGrow: .1}),
    new Column({name: 'AVG', prop: 'average_transaction_gap', type: ColumnType.Number, standard: true, order: 4, flexGrow: .15}),
    new Column({name: 'visit_mode_points', prop: 'visit_mode_points', type: ColumnType.Number}),
    new Column({name: 'visit_mode_minimum_gap', prop: 'visit_mode_minimum_gap', type: ColumnType.Number}),
    new Column({name: 'flag_purchase_threshold', prop: 'flag_purchase_threshold', type: ColumnType.Number}),
    new Column({name: 'TabakErzG', prop: 'tabakerzg_restricted', type: ColumnType.Boolean, standard: true, order: 6, flexGrow: .1}),
    new Column({name: 'demo', prop: 'demo', type: ColumnType.Boolean}),
    // Associated Columns
    new Column({name: 'Gebiet', prop: 'area', association: 'belongs_to', columns: Area.allColumns}),
    new Column({
      name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns,
      standard: true, standardprops: 'company', order: 1
    }),
    // new Column({name: 'partner_stat', prop: 'partner_stat', association: 'has_one', columns: PartnerStat.allColumns}),
  ];

  // Simple Columns
  id: number;
  username: string;
  name: string;
  contact: string;
  address: string;
  zip: string;
  city: string;
  email: string; // Post-processor change, before: email: string;
  password: string;
  password_confirmation: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  logo?: { url: string };
  cover: {url: string};
  phone: string;
  wallpaper: string;
  stamp_value: number;
  published_at: boolean;
  memberships_count: number;
  transactions_count: number;
  average_transaction_gap: number;
  visit_mode_points: number;
  visit_mode_minimum_gap: number;
  flag_purchase_threshold: number;
  area_id: number;
  client_id: number;
  demo: boolean;
  tabakerzg_restricted: boolean;
  // Associations
  area: Area;
  client: Client;
  campaign: Campaign[];
  business: Business[];
  deal: Deal[];
  teaser: Teaser[];
  reward: Reward[];
  membership: Membership[];
  user: User[];
  transaction: Transaction[];
  offer: Offer[];
  asset: Asset[];
  categorization: Categorization[];
  category: Category[];
  // partner_stat: PartnerStat;
  // email: Email[];
  merchant_access: MerchantAccess[];
  merchant: Merchant[];
  deal_automation: DealAutomation[];
  wallpapers: {id: number, partner_id: number, asset: Asset}[];

  has_logo: boolean;
  has_cover: boolean;
  has_wallpapers: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.name;
  }
}

export class PartnerList {
  count: number;
  data: Partner[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
