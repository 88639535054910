import {EmailEvent} from './email_event';
import {BaseSerializer} from '../base/base.serializer';
import {EmailSerializer} from '../emails/email.serializer';

export class EmailEventSerializer extends BaseSerializer<EmailEvent> {
  fromJson(json: any): EmailEvent {
    const serialized = new EmailEvent(json);
    if (json.email) {
      serialized.email = new EmailSerializer().fromJson(json.email);
    }
    return serialized;
  }

  toJson(obj: EmailEvent): string {
    let params: any = {email_event: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
