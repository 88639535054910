import {Component, HostBinding, OnInit} from '@angular/core';
import {PanelStateService} from '../panel-state.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.scss']
})
export class RightComponent implements OnInit {

  @HostBinding('class') state = 'full';

  constructor(public panelState: PanelStateService,
              private router: Router) { }

  ngOnInit() {
    this.panelState.panelStates.subscribe(states => this.state = states.right);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((ev: NavigationEnd) => {
      const segments = ev.url.split('/');
      if (segments[1] !== 'invoices' && +segments[2] > 0) {
        this.panelState.setState('right', 'full');
      }
    });
  }

}
