import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {BusinessPhoto} from '../business_photos/business_photo';
import {Business} from '../businesses/business';

export class Asset {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Datei', prop: 'file', type: ColumnType.String, standard: true}),
    new Column({name: 'original_filename', prop: 'original_filename', type: ColumnType.String, standard: true}),
    new Column({name: 'content_type', prop: 'content_type', type: ColumnType.String, standard: true}),
    new Column({name: 'file_size', prop: 'file_size', type: ColumnType.Number, standard: true}),
    new Column({name: 'width', prop: 'width', type: ColumnType.Number}),
    new Column({name: 'height', prop: 'height', type: ColumnType.Number}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns}),
  ];

  // Simple Columns
  id: number;
  partner_id: number;
  file: { url: string };
  original_filename: string;
  content_type: string;
  file_size: number;
  width: number;
  height: number;
  url: string;
  url_email: string;
  status: number;
  statusText: string;
  progress: object;
  originalName: string;
  size: number;
  response: string;
  done: boolean;
  error: boolean;
  abort: boolean;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  // Associations
  partner: Partner;
  business_photo: BusinessPhoto[];
  business: Business[];

  constructor(values: Partial<Asset> = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.original_filename;
  }

  setProgres(progress: Object): void {
    this.progress = progress;
  }

  setError(): void {
    this.error = true;
    this.done = true;
  }

  setAbort(): void {
    this.abort = true;
    this.done = true;
  }

  onFinished(status: number, statusText: string, response: string): void {
    this.status = status;
    this.statusText = statusText;
    this.response = response;
    this.done = true;
  }
}

export class AssetList {
  count: number;
  data: Asset[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
