import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BookmarkService} from './bookmark.service';
import {AdministratorService} from '../../../../administrators/administrator.service';
import {Bookmark} from './bookmark';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {

  bookmarks: Bookmark[];
  newBookmark = new Bookmark();
  editMode = false;

  @ViewChild('bookmarkInput', {static: true}) bookmarkInput: BsModalRef;
  @Input('favoritesOnly') favoritesOnly?: boolean;

  constructor(public bookmarkService: BookmarkService,
              public router: Router,
              private route: ActivatedRoute,
              private adminService: AdministratorService,
              private modalService: BsModalService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.newBookmark.name = '';
    this.refetch();
    this.bookmarkService.editMode.subscribe(res => {
      this.editMode = res;
    });
  }

  onAddBookmark() {
    this.bookmarkService.create({
      name: this.newBookmark.name,
      base_path: this.router.url.split('?')[0],
      path: this.router.url
    } as Bookmark).subscribe(() => {}, () => {
      this.toastr.error('Das Lesezeichen konnte nicht gespeichert werden, bitte melde dich bei einem Administrator.', 'Fehler!');
    });
    this.newBookmark.name = '';
  }

  refetch() {
    this.bookmarkService.bookmarks.subscribe(res => {
      this.bookmarks = res.favorites;
    });
  }

}
