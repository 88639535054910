import { Directive, ElementRef, OnDestroy, OnInit, Optional, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appBootstrapFormControl]'
})
export class BootstrapFormControlDirective implements OnInit, OnDestroy {

  static readonly INVALID = 'is-invalid';
  static readonly VALID = 'is-valid';

  private ngUnsubscribe = new Subject();

  constructor(@Optional() @Self() private ngControl: NgControl,
              private el: ElementRef,
              private renderer: Renderer2) {
  }

  public static getBootstrapFormClass(ngControl: NgControl) {
    if (ngControl.disabled || ngControl.pending) {
      return '';
    }
    if (ngControl.dirty) {
      return ngControl.valid ? this.VALID : this.INVALID;
    } else {
      return ngControl.valid ? '' : this.INVALID;
    }
  }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'form-control-bootstrap-classes');
    if (!this.ngControl) {
      return;
    }

    this.ngControl.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.updateFormClass();
    });
    this.updateFormClass();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private updateFormClass() {
    this.renderer.removeClass(this.el.nativeElement, BootstrapFormControlDirective.INVALID);
    this.renderer.removeClass(this.el.nativeElement, BootstrapFormControlDirective.VALID);

    const cssClass = BootstrapFormControlDirective.getBootstrapFormClass(this.ngControl);
    if (cssClass) {
      this.renderer.addClass(this.el.nativeElement, cssClass);
    }
  }
}
