import {AutomationEvent} from './automation_event';
import {BaseSerializer} from '../base/base.serializer';
import {UserSerializer} from '../users/user.serializer';
import {DealAutomationSerializer} from '../deal_automations/deal_automation.serializer';
import {DealSerializer} from '../deals/deal.serializer';

export class AutomationEventSerializer extends BaseSerializer<AutomationEvent> {
  fromJson(json: any): AutomationEvent {
    const serialized = new AutomationEvent(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    if (json.deal_automation) {
      serialized.deal_automation = new DealAutomationSerializer().fromJson(json.deal_automation);
    }
    if (json.deal) {
      serialized.deal = new DealSerializer().fromJson(json.deal);
    }
    return serialized;
  }

  toJson(obj: AutomationEvent): string {
    let params: any = {automation_event: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
