import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {Asset} from '../assets/asset';
import {Offer} from '../offers/offer';
import {AutomationEvent} from '../automation_events/automation_event';

export class Deal {
  static deal_types: string[] = ['voucher', 'percent', 'xfory', 'free', 'largeness', 'special_points_event'];
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Titel', prop: 'title', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'Beschreibung', prop: 'description', type: ColumnType.String}),
    new Column({name: 'Bedingungen', prop: 'terms', type: ColumnType.String}),
    new Column({name: 'Deal-Typ', prop: 'deal_type', type: ColumnType.Number, standard: true, order: 2, flexGrow: .2}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Alter Preis', prop: 'old_price', type: ColumnType.Number}),
    new Column({name: 'Neuer Preis', prop: 'new_price', type: ColumnType.Number}),
    new Column({name: 'percent_amount', prop: 'percent_amount', type: ColumnType.Number}),
    new Column({name: 'amount_x', prop: 'amount_x', type: ColumnType.Number}),
    new Column({name: 'amount_y', prop: 'amount_y', type: ColumnType.Number}),
    new Column({name: 'Fester Betrag', prop: 'fixed_amount', type: ColumnType.Number}),
    new Column({name: 'Alte Größe', prop: 'old_size', type: ColumnType.String}),
    new Column({name: 'Neue Größe', prop: 'new_size', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 3, flexGrow: .3}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'type', prop: 'type', type: ColumnType.String}),
    new Column({name: 'Endet am', prop: 'ends_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({
      name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns,
      standard: true, standardProps: 'logo,name', order: 0
    }),
    new Column({name: 'asset', prop: 'asset', association: 'belongs_to', columns: Asset.allColumns})
  ];
  // Simple Columns
  id: number;
  asset_id: number;
  title: string;
  description: string;
  terms: string;
  deal_type: string;
  old_price: number;
  new_price: number;
  percent_amount: number;
  amount_x: number;
  amount_y: number;
  fixed_amount: number;
  old_size: string;
  new_size: string;
  type: string;
  starts_at: Date;
  ends_at: Date;
  duration_in_days: number;
  partner_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  // Associations
  partner: Partner;
  asset: Asset;
  offer: Offer[];
  automation_event: AutomationEvent[];

  constructor(v: Partial<Deal> = {}) {
    Object.assign(this, v);
    this.starts_at = !!v.starts_at ? new Date(v.starts_at) : undefined;
    this.ends_at = !!v.ends_at ? new Date(v.ends_at) : undefined;
    this.partner = !!v.partner ? new Partner(v.partner) : undefined;
    this.asset = !!v.asset ? new Asset(v.asset) : undefined;
  }

  static valueToString = (deal: Deal): string => {
    switch (deal.deal_type) {
      case 'voucher':
        return `${deal.new_price} cent statt ${deal.new_price} cent`;
      case 'percent':
        return `${deal.percent_amount}%`;
      case 'xfory':
        return `${deal.amount_x} für ${deal.amount_y}`;
      case 'fixed':
        return `${deal.fixed_amount} cent`;
      case 'free':
        return ``;
      case 'largeness':
        return `${deal.new_size} statt ${deal.old_size}`;
      default:
        return '';
    }
  };

  valid(): boolean {
    if (!this.title || !this.description || Deal.deal_types.indexOf(this.deal_type) == -1) {
      return false;
    }

    if (this.deal_type === 'special_points_event') {
      return true;
    }

    if (!this.asset || !(this.asset.id > -1)) {
      return false;
    }

    if (this.type !== 'Teaser' && (this.starts_at.getTime() >= this.ends_at.getTime() || this.ends_at.getTime() <= new Date().getTime())) {
      return false;
    }

    switch (this.deal_type) {
      case 'voucher':
        return (this.new_price > 0 && this.old_price > 0);
      case 'percent':
        return (this.percent_amount > 0);
      case 'xfory':
        return (this.amount_x > 0 && this.amount_y > 0);
      case 'fixed':
        return (this.fixed_amount > 0);
      case 'free':
        return true;
      case 'largeness':
        return (this.new_size && this.new_size.length > 0 && this.old_size && this.old_size.length > 0);
      default:
        return true;
    }
  }

  humanize() {
    return this.title;
  }
}

export class DealList {
  count: number;
  data: Deal[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
