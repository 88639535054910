import { Injectable } from '@angular/core';
import {Contract, ContractList} from './contract';
import {BaseService} from '../base/base.service';
import {ContractSerializer} from 'src/app/contracts/contract.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends BaseService<Contract, ContractList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'contracts' ;
    this.serializer = new ContractSerializer();
  }

  sign(contract_id: number, signature: string) {
    return this.update(contract_id, this.prepareSignature(signature));
  }

  private prepareSignature(signature: string) {
    return JSON.parse(JSON.stringify({signature: signature}));
  }

  download(id: number) {
    return this.http.get(`${environment.base_url + this.endpoint}/${id}/download_signed`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/pdf'})));
  }

  email(id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${id}/send_to_client`, null);
  }
}
