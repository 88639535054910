import { Injectable } from '@angular/core';
import {TabletDatum, TabletDatumList} from './tablet_datum';
import {BaseService} from '../base/base.service';
import {TabletDatumSerializer} from 'src/app/tablet_data/tablet_datum.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TabletDatumService extends BaseService<TabletDatum, TabletDatumList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'tablet_data' ;
    this.serializer = new TabletDatumSerializer();
  }
}
