import {MobileActivity} from './mobile_activity';
import {BaseSerializer} from '../base/base.serializer';
import {MobileSessionSerializer} from '../mobile_sessions/mobile_session.serializer';

export class MobileActivitySerializer extends BaseSerializer<MobileActivity> {
  fromJson(json: any): MobileActivity {
    const serialized = new MobileActivity(json);
    if (json.mobile_session) {
      serialized.mobile_session = new MobileSessionSerializer().fromJson(json.mobile_session);
    }
    return serialized;
  }

  toJson(obj: MobileActivity): string {
    let params: any = {mobile_activity: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
