import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {flatMap, first, tap, catchError, finalize} from 'rxjs/operators';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService,
              private _loading: LoadingBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loading.start(10);
    return this.auth.authenticated().pipe(first(), flatMap(authenticated => {
        if (authenticated) {
          return this.auth.currentToken().pipe(first(), flatMap(token => {
            if (!req.headers.get('Authorization')) {
              const cloned = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token).set('Accept', 'application/json')
              });
              return next.handle(cloned);
            } else {
              return next.handle(req);
            }
          }));
        } else {
          return next.handle(req);
        }
      }),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._loading.complete();
        }
      }, (err) => {
        this._loading.complete();
      }, () => {
        this._loading.complete();
      }),
      finalize(() => {
        this._loading.stop();
      }));
  }
}
