<ngx-datatable
  class="bootstrap fullscreen"
  trackByProp="id"
  [rows]="data"
  [selected]="selected"
  [selectAllRowsOnPage]="false"
  [selectionType]="selectionType"
  [headerHeight]="20"
  [footerHeight]="40"
  [rowHeight]="26"
  [externalPaging]="true"
  [externalSorting]="true"
  [sortType]="sortType"
  [count]="page.totalElements"
  [offset]="page.offset"
  [limit]="page.size"
  [columnMode]="columnMode"
  [loadingIndicator]="true"
  (sort)="onSort($event)"
  (reorder)="onReorder($event)"
  [cssClasses]="cssClasses"
  style="font-size: 14px;">

  <ngx-datatable-column
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
    [headerCheckboxable]="true"
    [checkboxable]="true"
    [comparator]="0"
    class="form-control">
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngFor="let col of columns"
    [name]="col.name"
    [flexGrow]="col.flexGrow"
    [comparator]="0"
    [prop]="col.prop"
    [headerClass]="col.prop + (col.type === ColumnType.Currency ? ' align-self-end' : '')"
    [cellClass]="col.prop + (col.type === ColumnType.Currency ? ' align-self-end' : '')">

    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <ng-container *ngTemplateOutlet="customCellTemplate; context: {row: row, value: value, col: col, all: all}">
      </ng-container>
    </ng-template>

  </ngx-datatable-column>

  <ngx-datatable-footer *ngIf="customFooterTemplate">
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template>
        <ng-container *ngTemplateOutlet="customFooterTemplate; context: {data: data}"></ng-container>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable-footer>

</ngx-datatable>

<app-right-panel-content>
  <app-table-controls [model]="model"
                      [columns]="columns"
                      [allColumns]="all"
                      [quickFilter]="quick"
                      [(selected)]="selected"
                      [(data)]="data"></app-table-controls>
</app-right-panel-content>
