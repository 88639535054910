import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Merchant} from '../merchants/merchant';

export class MerchantAccess {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Schreibrechte', prop: 'allow_write', type: ColumnType.Boolean, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, standard: true}),
    // Associated Columns
    new Column({name: 'Händler', prop: 'merchant', association: 'belongs_to', columns: Merchant.allColumns})
  ];

  // Simple Columns
  id: number;
  merchant_id: number;
  allow_write: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  target_type: string;
  target_name: string;
  target_id: number;
  target: any;
  // Associations
  merchant: Merchant;

  constructor(v: Partial<MerchantAccess> = {}) {
    Object.assign(this, v);
    this.merchant = v.merchant ? new Merchant(v.merchant) : undefined;
  }

  humanize() {
    return this.merchant && this.merchant.client.name ? this.merchant.client.name : this.id;
  }
}

export class MerchantAccessList {
  count: number;
  data: MerchantAccess[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
