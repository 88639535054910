import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {Campaign} from '../campaigns/campaign';

export class AudienceDefinition {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'audience_type', prop: 'audience_type', type: ColumnType.Number, standard: true}),
    new Column({name: 'scope', prop: 'scope', type: ColumnType.Number, standard: true}),
    new Column({name: 'segment', prop: 'segment', type: ColumnType.Number, standard: true}),
    new Column({name: 'purchase_volume_sum_total_min', prop: 'purchase_volume_sum_total_min', type: ColumnType.Number, standard: true}),
    new Column({name: 'purchase_volume_sum_total_max', prop: 'purchase_volume_sum_total_max', type: ColumnType.Number}),
    new Column({name: 'purchase_count_total_min', prop: 'purchase_count_total_min', type: ColumnType.Number}),
    new Column({name: 'purchase_count_total_max', prop: 'purchase_count_total_max', type: ColumnType.Number}),
    new Column({name: 'membership_created_at_after', prop: 'membership_created_at_after', type: ColumnType.DateTime}),
    new Column({name: 'membership_created_at_before', prop: 'membership_created_at_before', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'size', prop: 'size', type: ColumnType.Number}),
    new Column({name: 'visit_count', prop: 'visit_count', type: ColumnType.Number}),
    new Column({name: 'atg_multiplier', prop: 'atg_multiplier', type: ColumnType.Number}),
    new Column({name: 'birthday', prop: 'birthday', type: ColumnType.Boolean}),
    // Associated Columns
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns}),
  ];

  static segments: Object = {
    partner_all: 'Alle Kunden',
    partner_new: 'Neukunden',
    partner_walk_in: 'Gelegenheitskunden',
    partner_loyal: 'Treue Kunden',
    partner_vip: 'VIP Kunden',
    business_all: 'Alle Kunden',
    business_new: 'Neukunden',
    business_walk_in: 'Gelegenheitskunden',
    business_loyal: 'Treue Kunden',
    business_vip: 'VIP Kunden',
    // mankido_all: 'Alle',
    // mankido_app: 'App-Nutzer',
    // mankido_noapp: 'Karten-Nutzer'
  };

  // Simple Columns
  id: number;
  audience_type: number;
  scope: number;
  partner_id: number;
  segment: number;
  purchase_volume_sum_total_min: number;
  purchase_volume_sum_total_max: number;
  purchase_count_total_min: number;
  purchase_count_total_max: number;
  membership_created_at_after: boolean;
  membership_created_at_before: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  size: number;
  visit_count: number;
  atg_multiplier: number;
  birthday: boolean;
  // Associations
  partner: Partner;
  campaign: Campaign[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.partner && this.partner.name ? this.partner.name : this.audience_type;
  }

  humanAudienceDefinition(): string {
    const result = AudienceDefinition.segments[this.segment];
    return result ? result : 'unbekannt';
  }
}

export class AudienceDefinitionList {
  count: number;
  data: AudienceDefinition[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
