import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { AffiliateDeal } from '../affiliate_deals/affiliate_deal';

export class AffiliateCategory {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: 'id', prop: 'id', type: ColumnType.Number, standard: true, flexGrow: 0.05}),
    new Column({name: 'Veröffentlicht', prop: 'approved', type: ColumnType.Boolean, standard: true, flexGrow: 0.05}),
    new Column({name: 'Favorite', prop: 'favorite', type: ColumnType.Boolean, standard: true, flexGrow: 0.05}),
    new Column({name: 'rank', prop: 'rank', type: ColumnType.Number, standard: true, flexGrow: 0.05}),
    new Column({name: 'published', prop: 'published', type: ColumnType.Boolean, standard: true, flexGrow: 0.05}),
    new Column({name: 'name', prop: 'name', type: ColumnType.String, standard: true, flexGrow: 0.75}),
    new Column({name: 'unique_id', prop: 'unique_id', type: ColumnType.String, standard: false}),
    new Column({name: 'created_at', prop: 'created_at', type: ColumnType.DateTime, standard: false}),
    new Column({name: 'updated_at', prop: 'updated_at', type: ColumnType.DateTime, standard: false}),
    new Column({name: 'deleted_at', prop: 'deleted_at', type: ColumnType.DateTime, standard: false}),
    // Associated Columns
  ];

  // Simple Columns
  id: number;
  approved: boolean;
  favorite: boolean;
  name: string;
  rank: number;
  unique_id: string;
  published: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  affiliate_deals: AffiliateDeal[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return `${this.name} (${this.id})`;
  }
}

export class AffiliateCategoryList {
  count: number;
  data: AffiliateCategory[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
