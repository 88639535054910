<a class="nav-link" [href]="target" (click)="open($event)" routerLinkActive="active"> <!-- @jan !-->
  <span>{{emoticon()}}</span>
  {{nameWithoutEmoticon()}}
</a>
<span *ngIf="editMode" class="text-danger" (click)="clickDeleteBookmark()" (dblclick)="deleteBookmark()" ><i class="fa fa-trash"></i></span>
<span *ngIf="editMode" class="text-success" (click)="bookmarkEdit.show()"><i class="fa fa-pencil"></i></span>
<span *ngIf="editMode" (click)="favorite()"><i *ngIf="!bookmark.favorite" class="fa fa-star-o"></i><i *ngIf="bookmark.favorite" class="fa fa-star"></i></span>

<div bsModal #bookmarkEdit="bs-modal" class="modal fade">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Lesezeichen umbenennen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bookmarkEdit.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Wie möchtest du das Lesezeichen nennen?
        <input type="text" class="form-control d-inline-flex" placeholder="Lesezeichen umbenennen" (keydown.enter)="onEditBookmark()" [(ngModel)]="bookmark.name" autofocus>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="bookmarkEdit.hide()">Abbrechen</button>
        <button type="button" class="btn btn-primary" (click)="onEditBookmark(); bookmarkEdit.hide()">Speichern</button>
      </div>
    </div>
  </div>
</div>
