import {Deal} from './deal';
import {BaseSerializer} from '../base/base.serializer';

export class DealSerializer extends BaseSerializer<Deal> {
  fromJson(json: any): Deal {
    return new Deal(json);
  }

  toJson(obj: Deal): string {
    let params: any = {deal: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
