<form [formGroup]="form" *ngIf="form">
  <div class="row mt-4">
    <div class="col-6" formGroupName="facts">
      <div class="form-group">
        <label for="quantity">Anzahl</label>
        <input appBootstrapFormControl type="number" min="0" id="quantity" class="form-control" formControlName="quantity">
      </div>

      <div class="form-group">
        <label for="print_finish">Druckveredelung</label>
        <select appBootstrapFormControl class="custom-select" id="print_finish" formControlName="print_finish">
          <option disabled="disabled"></option>
          <option *ngFor="let printFinish of PersonalizedCardOrder.PRINT_FINISH_OPTIONS"
                  [value]="printFinish">{{ printFinish }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="print_template">Druckvorlage</label>
        <app-pdf-uploader id="print_template" formControlName="print_template"></app-pdf-uploader>
      </div>

      <div class="form-group">
        <label for="attachment_one">Weitere Anhänge</label>
        <app-pdf-uploader id="attachment_one" formControlName="attachment_one"></app-pdf-uploader>
      </div>

      <div class="form-group" *ngIf="form.get('facts').get('attachment_one').value">
        <app-pdf-uploader id="attachment_two" formControlName="attachment_two"></app-pdf-uploader>
      </div>

      <div class="form-group">
        <label for="comment">Anmerkung</label>
        <textarea class="form-control" id="comment" rows="4" formControlName="comment"></textarea>
      </div>

      <hr>

      <div class="form-group">
        <label for="client_id">Kunde</label>
        <app-typeahead-select id="client_id"
                              [model]="'clients'"
                              [typeaheadProperties]="['name', 'contact']"
                              formControlName="client_id"></app-typeahead-select>
      </div>
      <div class="form-row">
        <div class="col-6">
          <label for="company_name">Firmenname</label>
          <input appBootstrapFormControl class="form-control" id="company_name" type="text" formControlName="company_name">
        </div>
        <div class="col-6">
          <label for="contact_person">Kontaktperson</label>
          <input appBootstrapFormControl class="form-control" id="contact_person" type="text" formControlName="contact_person">
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <label for="address">Adresse</label>
          <input appBootstrapFormControl class="form-control" id="address" type="text" formControlName="address">
        </div>
      </div>
      <div class="form-row">
        <div class="col-6">
          <label for="zip">PLZ</label>
          <input appBootstrapFormControl class="form-control" id="zip" type="text" formControlName="zip">
        </div>
        <div class="col-6">
          <label for="city">Stadt</label>
          <input appBootstrapFormControl class="form-control" id="city" type="text"  formControlName="city">
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <label for="country">Land</label>
          <input appBootstrapFormControl class="form-control" id="country" type="text" formControlName="country">
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label for="email_subject">Betreff</label>
        <input class="form-control" id="email_subject" disabled="disabled" [value]="emailSubject">
      </div>
      <div class="form-group">
        <label for="text" class="d-flex justify-content-between">
          <span>E-Mail Text</span>
          <button class="btn btn-sm btn-primary float-right" (click)="toggleEmailTextEditor()">Bearbeiten</button>
        </label>
        <app-trix-editor id="text" formControlName="text" [saveIndicator]="false" *ngIf="showEmailTextEditor"></app-trix-editor>
        <div class="trix-text card card-body bg-light" *ngIf="!showEmailTextEditor">
          <div  [innerHtml]="form.get('text').value"></div>
        </div>
      </div>
      <div class="alert alert-warning" *ngIf="showManualEditAlert">
        <h4>Text manuell geändert</h4>
        <p>Der E-Mail Text wird bei Änderungen der Daten nicht mehr automatisch angepasst.</p>
        <p>Soll der Text aus dem Formular neu erstellt werden?</p>
        <button class="btn btn-outline-success btn-sm" (click)="resyncText()">Überschreiben</button>
      </div>
      <button class="btn btn-success" [disabled]="!form.valid || saving" (click)="submit()">
        {{  saving ? 'Bestellen ...' : 'Bestellen' }}
      </button>
    </div>
  </div>
</form>
