import {Injectable} from '@angular/core';
import {Group} from './group';
import * as rison from 'rison';
import {Column} from '../shared/models/column';
import {Filter} from './filter';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() {
  }

  /**
   * Converts rison string to filter group
   * @param {string} q
   * @param {Array.<Column>} allColumns
   * @return {Array.<Group>}
   */
  urlToFilter(q: string, allColumns: Column[]): Group[] {
    const finalGroup: Group[] = [];
    const risonGroup: any[] = (this.isEmptyKey(q)) ? [] : rison.decode(q);
    if (risonGroup.length > 0) {
      risonGroup.forEach(group => {
        let link: string = group['m'];
        const finalFilter: Filter[] = [];
        Object.keys(group).filter(k => k !== 'm').forEach(filter => {
          let object: string = group[filter]; // object for what is searched
          const organized_key = this.organizeKey(filter); // finds and returns what is predicate and subject
          const subject = organized_key['subject'];
          if (group && group[filter] && group[filter].length && group[filter].includes(',')) {
            const splitted = group[filter].split(',');
            object = splitted[0];
            link = 'or';
            splitted.forEach((obj, i) => {
              if (i !== 0) {
                if (obj.startsWith(' ')) {
                  obj = obj.replace(' ', '');
                }
                finalFilter.push({
                  subject: subject,
                  predicate: organized_key['predicate'],
                  object1: obj
                });
              }
            });
          }
          finalFilter.push({
            subject: subject,
            predicate: organized_key['predicate'],
            object1: object
          });
        });

        finalGroup.push({
          m: link,
          filter: finalFilter
        });
      });
    }
    return finalGroup;
  }

  /**
   * Reformats a Group array to a usable object array for list service
   * @param {Array.<Group>} group
   * @return {Array.<Object>} q
   */
  filterToUrl(group: Group[]): Object[] {
    return Group.filterToUrl(group);
  }

  /**
   * Finds out what is subject and what is predicate and returns an object with properties: subject, predicate
   * @param {string} key
   * @return {Object}
   */
  organizeKey(key: string): Object {
    if (this.isEmptyKey(key)) {
      const subject = key.replace('_blank', '').replace('_present', '');
      const predicate = Column.getStringPredicate(key.replace(subject + '_', ''));
      return {subject: subject, predicate: predicate};
    } else {
      const splitted_key = key.split(new RegExp('_', 'g')); // split key at all underscore occurencies
      const underscore_count = splitted_key.length - 1; // get amount of underscores in key

      for (let i = 0; i <= underscore_count; i++) { // iterate using underscore count as limit
        const found_string = key.lastIndexOf(splitted_key[i]); // position where the part of the key lays
        const check_string = key.substring(found_string).replace(/_\d/g, ''); // get string from position to end -> will be checked later
        const predicate_check = Column.getStringPredicate(check_string); // get predicate from check_string
        const subject = key.substring(0, found_string - 1); // get string from start until found_string occurence - 1 to remove underscore

        if (predicate_check !== undefined) { // when predicate_check is undefined, it's not a predicate at all, the search goes on...
          return {subject: subject, predicate: predicate_check};
          // if predicate_check is a predicate, subject and predicate are returned
        }
      }
    }
  }

  /**
   * Checks if q/key is a blank predicate (blank/present) without an object
   * @param {string} keys
   * @return {boolean}
   */
  isEmptyKey(keys: string): boolean {
    return keys.includes('_blank') || keys.includes('_present');
  }
}
