import {Email} from './email';
import {BaseSerializer} from '../base/base.serializer';
import {MessageSerializer} from '../messages/message.serializer';

export class EmailSerializer extends BaseSerializer<Email> {
  fromJson(json: any): Email {
    const serialized = new Email(json);
    if (json.message) {
      serialized.message = new MessageSerializer().fromJson(json.message);
    }
    return serialized;
  }

  toJson(obj: Email): string {
    let params: any = {email: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
