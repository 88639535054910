import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Todo} from '../../../shared/enums/todo.enum';
import {ToastrService} from 'ngx-toastr';
import {ListService} from '../../list.service';
import {SelectionOption} from '../../../shared/models/selection-option';
import {Observable} from 'rxjs';
import {Predicate} from '../../../shared/enums/predicate.enum';

@Component({
  selector: 'app-selection-controls',
  templateUrl: './selection-controls.component.html',
  styleUrls: ['./selection-controls.component.scss']
})
export class SelectionControlsComponent implements OnInit {

  @Input() data = [];
  @Output() dataChange = new EventEmitter();

  @Input() selected;
  @Output() selectedChange = new EventEmitter();

  @Input() model: string;
  @Input() columns;

  selectionOption = new SelectionOption(); // Helping object for mass-edit/replace
  selectionsOnly = false;

  _data: any;
  _assoc: any;
  setAssoc: any;
  asyncAssoc: any;
  targetAssoc: number;
  dataCopy = [];
  Todo = Todo;

  constructor(private toastr: ToastrService,
              private _list: ListService) { }

  ngOnInit() {
    this._data = this._list.getServiceEndpoint(this.model);
  }

  onEditSelections(): void {
    switch (this.selectionOption.todo) {
      case Todo.CHANGE:
        this.selected.forEach((selected) => {
          this.data.forEach((data, i) => {
            if (selected.id === data.id) {

              let prop;
              if (this.isColumnAssociation) {
                prop = this.selectionOption.column.prop.split('.')[0] + '_id';
                data[prop] = this.targetAssoc;
              } else {
                prop = this.selectionOption.column.prop;
                data[prop] = this.selectionOption.value1;
              }

              this._data.update(data.id, {[prop]: data[prop]}).subscribe((res) => {
                this.data.splice(i, 1, res);
                this.dataChange.emit(this.data);
                this.selected = [];
                if (this.targetAssoc) {
                  this.targetAssoc = undefined;
                }
                this.toastr.success('Massenbearbeitung erfolgreich');
              }, () => this.toastr.error('Massenbearbeitung fehlgeschlagen'));
            }
          });
        });
        break;
      case Todo.EXTEND:
        this.selected.forEach((selected) => {
          this.data.forEach((data) => {
            if (selected.id === data.id) {
              const prop = this.selectionOption.column.prop;
              const extend = this.selectionOption.value1;
              data[prop] = data[prop] + extend;
              this._data.update(data.id, {[prop]: data[prop]}).subscribe(() => {
                this.toastr.success('Massenbearbeitung erfolgreich');
                this.selected = [];
              }, () => this.toastr.error('Massenbearbeitung fehlgeschlagen'));
            }
          });
        });
        break;
      case Todo.REPLACE:
        this.selected.forEach((selected) => {
          this.data.forEach((data) => {
            if (selected.id === data.id) {
              const prop = this.selectionOption.column.prop;
              const find = this.selectionOption.value1;
              const replace = (this.selectionOption.value2) ? this.selectionOption.value2 : '';
              data[prop] = data[prop].replace(new RegExp(find, 'g'), replace);

              this._data.update(data.id, {[prop]: data[prop]}).subscribe(() => {
                this.toastr.success('Massenbearbeitung erfolgreich');
                this.selected = [];
              }, () => this.toastr.error('Massenbearbeitung fehlgeschlagen'));
            }
          });
        });
        break;
    }
    this.selected = [];
  }

  deleteSelections(): void {
    this.selected.forEach(selected => {
      this.data.forEach((data, i) => {
        if (selected.id === data.id) {
          this._data.delete(data.id).subscribe(() => {
            const j = this.data.findIndex(x => x.id === data.id);
            this.data.splice(j, 1);
            this.selected = [];
            this.selectedChange.emit(this.selected);
          });
        }
      });
    });
  }

  clearSelection(): void {
    this.selected = [];
    this.selectedChange.emit(this.selected);
  }

  get isColumnAssociation() {
    return this.selectionOption.column.prop.includes('.');
  }

  toggleShow(): void {
    this.selectionsOnly = !this.selectionsOnly;
    if (this.selectionsOnly) {
      this.dataCopy = this.data;
      this.data = this.selected;
    } else {
      this.data = this.dataCopy;
    }
    this.dataChange.emit(this.data);
  }

  deleteInfo(): void {
    this.toastr.warning(
      `Du bist im Begriff die ausgewählten Datensätze komplett zu löschen.
Um diese Aktion auszuführen, doppelklicke auf "Einträge löschen".`,
      'Einträge löschen');
  }

  checkColumn() {
    if (this.isColumnAssociation) {
      this._assoc = this._list.getServiceEndpoint(this.selectionOption.column.plural);
      this.asyncAssoc = Observable.create((observer: any) => {
        this._assoc.fetchAll('id,name',
          this._assoc.simpleQ('name', this.setAssoc, Predicate.CONTAINS))
          .subscribe(res => {
            observer.next(res.data);
            this.targetAssoc = res.data[0].id;
          });
      });
    }
  }
}
