import { Injectable } from '@angular/core';
import {PosMessage, PosMessageList} from './pos_message';
import {BaseService} from '../base/base.service';
import {PosMessageSerializer} from 'src/app/pos_messages/pos_message.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PosMessageService extends BaseService<PosMessage, PosMessageList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'pos_messages' ;
    this.serializer = new PosMessageSerializer();
  }
}
