import {Area} from './area';
import {BaseSerializer} from '../base/base.serializer';

export class AreaSerializer extends BaseSerializer<Area> {
  fromJson(json: any): Area {
    const serialized = new Area(json);
    return serialized;
  }

  toJson(obj: Area): string {
    let params: any = {area: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
