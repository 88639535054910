import {BusinessPhoto} from './business_photo';
import {BaseSerializer} from '../base/base.serializer';
import {BusinessSerializer} from '../businesses/business.serializer';
import {AssetSerializer} from '../assets/asset.serializer';

export class BusinessPhotoSerializer extends BaseSerializer<BusinessPhoto> {
  fromJson(json: any): BusinessPhoto {
    const serialized = new BusinessPhoto(json);
    if (json.business) {
      serialized.business = new BusinessSerializer().fromJson(json.business);
    }
    if (json.asset) {
      serialized.asset = new AssetSerializer().fromJson(json.asset);
    }
    return serialized;
  }

  toJson(obj: BusinessPhoto): string {
    let params: any = {business_photo: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
