import { Injectable } from '@angular/core';
import {PosSession, PosSessionList} from './pos_session';
import {BaseService} from '../base/base.service';
import {PosSessionSerializer} from 'src/app/pos_sessions/pos_session.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PosSessionService extends BaseService<PosSession, PosSessionList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'pos_sessions' ;
    this.serializer = new PosSessionSerializer();
  }
}
