
export const environment = {
  production: true,
  environment_name: 'staging',
  release: 'eaf6a8625eed8c9d2e65decd9eb4408344137e40',

  hmr: false,
  base_url: 'https://api.staging.mankidodev.de/admin/v2/',
  iban_base_url: '/iban',
  apesign2_base_url: '/apesign2',
  merchantcenter_base_url: 'https://merchants.staging.mankidodev.de/',
  places_base_url: '/maps',
  places_api_key: 'AIzaSyAqHc9aItV8T5eVE3VoInJjQOuyYnF-4Lo',
  websocket_url: 'wss://api.staging.mankidodev.de/websocket',

  sentry_dsn: 'https://34299abc3a1c49311ca17756d8fd6adc@sentry.mankido.net/5',
  sentry_project_id: '5'
};
