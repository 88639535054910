import {Asset} from './asset';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class AssetSerializer extends BaseSerializer<Asset> {
  fromJson(json: any): Asset {
    const serialized = new Asset(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: Asset): string {
    let params: any = {asset: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
