import {Business} from './business';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';
import {AreaSerializer} from '../areas/area.serializer';
import {ClientSerializer} from '../clients/client.serializer';

export class BusinessSerializer extends BaseSerializer<Business> {
  fromJson(json: any): Business {
    const serialized = new Business(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    if (json.area) {
      serialized.area = new AreaSerializer().fromJson(json.area);
    }
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    return serialized;
  }

  toJson(obj: Business): string {
    let params: any = {business: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
