import {Injectable} from '@angular/core';
import {SepaMandate, SepaMandateList} from './sepa_mandate';
import {BaseService} from '../base/base.service';
import {SepaMandateSerializer} from 'src/app/sepa_mandates/sepa_mandate.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SepaMandateService extends BaseService<SepaMandate, SepaMandateList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'sepa_mandates';
    this.serializer = new SepaMandateSerializer();
  }

  download(id: number) {
    return this.http.get(`${environment.base_url + this.endpoint}/${id}/download`, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/pdf'}))
    );
  }

  email(id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${id}/send_to_client`, null, {
      responseType: 'blob'
    }).pipe(
      filter(x => !!x),
      take(1),
      map(res => new Blob([res], {type: 'application/pdf'}))
    );
  }
}
