import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Deal } from '../deals/deal';
import { Membership } from '../memberships/membership';
import { MessageAttachment } from '../message_attachments/message_attachment';
import { MobileMessage } from '../mobile_messages/mobile_message';

export class Offer {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Reserviert', prop: 'reserved_at', type: ColumnType.Boolean, standard: true, order: 2, flexGrow: .1}),
    new Column({name: 'Endet am', prop: 'ends_at', type: ColumnType.DateTime}),
    new Column({name: 'Eingelöst', prop: 'redeemed_at', type: ColumnType.Boolean, standard: true, order: 3, flexGrow: .1}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'reservation_token', prop: 'reservation_token', type: ColumnType.String}),
    // Associated Columns
    new Column({name: 'Deal', prop: 'deal', association: 'belongs_to', columns: Deal.allColumns, standard: true,
    standardProps: 'title', order: 1}),
    new Column({name: 'Mitgliedschaft', prop: 'membership', association: 'belongs_to', columns: Membership.allColumns,
    standard: true, order: 0, standardProps: 'partner.name,user.email'}),
  ];

  // Simple Columns
  id: number;
  deal_id: number;
  membership_id: number;
  reserved_at: boolean;
  ends_at: boolean;
  redeemed_at: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  reservation_token: string;
  // Associations
  deal: Deal;
  membership: Membership;
  message_attachment: MessageAttachment[];
  mobile_message: MobileMessage[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.reservation_token;
  }
}

export class OfferList {
  count: number;
  data: Offer[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
