import {Partner} from './partner';
import {BaseSerializer} from '../base/base.serializer';
import {AreaSerializer} from '../areas/area.serializer';
import {ClientSerializer} from '../clients/client.serializer';

export class PartnerSerializer extends BaseSerializer<Partner> {
  fromJson(json: any): Partner {
    const serialized = new Partner(json);
    if (json.area) {
      serialized.area = new AreaSerializer().fromJson(json.area);
    }
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    return serialized;
  }

  toJson(obj: Partner): string {
    let params: any = {partner: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
