<ngx-loading-bar [includeSpinner]="false" color="#28a5dc"></ngx-loading-bar>
<app-top *ngIf="authenticated"></app-top>

<div class="main">
  <app-left *ngIf="authenticated"></app-left>

  <div class="content" [attr.data-right-panel]="panelStates.right" [attr.data-left-panel]="panelStates.left">
    <router-outlet>
    </router-outlet>
  </div>

  <app-right *ngIf="authenticated"></app-right>
</div>
