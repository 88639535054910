import { Injectable } from '@angular/core';
import {Offer, OfferList} from './offer';
import {BaseService} from '../base/base.service';
import {OfferSerializer} from 'src/app/offers/offer.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends BaseService<Offer, OfferList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'offers' ;
    this.serializer = new OfferSerializer();
  }
}
