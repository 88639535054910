import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AdministratorService} from '../../administrators/administrator.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(private adminService: AdministratorService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.adminService.currentAdministrator.pipe(
      map(() => this.adminService.allowed(route.data.permission))
    );
  }
}
