import {AffiliateMerchant} from './affiliate_merchant';
import {BaseSerializer} from '../base/base.serializer';
import {AffiliateCategorySerializer} from '../affiliate_categories/affiliate_category.serializer';

export class AffiliateMerchantSerializer extends BaseSerializer<AffiliateMerchant> {
  fromJson(json: any): AffiliateMerchant {
    const serialized = new AffiliateMerchant(json);
    if (json.affiliate_category) {
      serialized.affiliate_category = new AffiliateCategorySerializer().fromJson(json.affiliate_category);
    }
    return serialized;
  }

  toJson(obj: AffiliateMerchant): string {
    let params: any = {affiliate_merchant: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
