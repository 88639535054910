import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RansackTableService {

  private refreshEvent$ = new Subject<void>();

  constructor() { }

  public get refreshEvent(): Observable<void> {
    return this.refreshEvent$.asObservable();
  }

  public refresh(): void {
    this.refreshEvent$.next();
  }
}
