import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';

export class Reward {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Titel', prop: 'title', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'Beschreibung', prop: 'description', type: ColumnType.String}),
    new Column({name: 'Punkte', prop: 'points', type: ColumnType.Number, standard: true, order: 2, flexGrow: .15}),
    new Column({name: 'discontinued_at', prop: 'discontinued_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({
      name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns, standard: true,
      standardProps: 'logo,name', order: 0
    }),
  ];

  // Simple Columns
  id: number;
  partner_id: number;
  title: string;
  description: string;
  points: number;
  discontinued_at: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  partner: Partner;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.title;
  }
}

export class RewardList {
  count: number;
  data: Reward[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
