import {Merchant} from './merchant';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MerchantSerializer extends BaseSerializer<Merchant> {
  fromJson(json: any): Merchant {
    const serialized = new Merchant(json);
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    return serialized;
  }

  toJson(obj: Merchant): string {
    let params: any = {merchant: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
