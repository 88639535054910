import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { EmailEvent } from '../email_events/email_event';
import { Message } from '../messages/message';
import { Campaign } from '../campaigns/campaign';
import { DealAutomation } from '../deal_automations/deal_automation';
import { MessageStat } from '../message_stats/message_stat';

export class Email {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .2}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 4, flexGrow: .4}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Status', prop: 'status', type: ColumnType.String, standard: true, order: 2, flexGrow: .3}),
    new Column({name: 'Empfänger', prop: 'recipient_data', type: ColumnType.Polymorphic, standard: true, order: 1, flexGrow: .8}),
    new Column({name: 'unqualified_reason', prop: 'unqualified_reason', type: ColumnType.Number}),
    new Column({name: 'type', prop: 'type', type: ColumnType.String, standard: true, order: 3, flexGrow: .8}),
    // Associated Columns
    new Column({name: 'Nachricht', prop: 'message', association: 'belongs_to', columns: Message.allColumns}),
    new Column({name: 'Kampagne', prop: 'campaign', association: 'has_one', columns: Campaign.allColumns}),
    new Column({name: 'Benutzer', prop: 'user', association: 'has_one', columns: Campaign.allColumns}),
    new Column({name: 'Dealautomatisierung', prop: 'deal_automation', association: 'has_one', columns: DealAutomation.allColumns}),
    new Column({name: 'message_stat', prop: 'message_stat', association: 'has_one', columns: MessageStat.allColumns}),
  ];

  // Simple Columns
  id: number;
  sg_message_id: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  status: number;
  unqualified_reason: number;
  message_id: number;
  type: string;
  // Associations
  email_event: EmailEvent[];
  message: Message;
  campaign: Campaign;
  deal_automation: DealAutomation;
  message_stat: MessageStat;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.sg_message_id;
  }
}

export class EmailList {
  count: number;
  data: Email[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
