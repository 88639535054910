import {Tablet} from './tablet';
import {BaseSerializer} from '../base/base.serializer';
import {Terminal} from '../terminals/terminal';

export class TabletSerializer extends BaseSerializer<Tablet> {
  fromJson(json: any): Tablet {
    if (json.tablet_datum) {
      json.tablet_datum = new TabletSerializer().fromJson(json.tablet_datum);
    }
    if (json.terminal) {
      json.terminal = new Terminal(json.terminal);
    }
    return new Tablet(json);
  }

  toJson(obj: Tablet): string {
    let params: any = {tablet: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
