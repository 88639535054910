import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Partner } from '../partners/partner';
import { Transaction } from '../transactions/transaction';

export class Coupon {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'code', prop: 'code', type: ColumnType.String, standard: true}),
    new Column({name: 'coupon_type', prop: 'coupon_type', type: ColumnType.Number, standard: true}),
    new Column({name: 'Punkte', prop: 'points', type: ColumnType.Number, standard: true}),
    new Column({name: 'valid_from', prop: 'valid_from', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'valid_until', prop: 'valid_until', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns}),
  ];

  // Simple Columns
  id: number;
  code: string;
  coupon_type: number;
  partner_id: number;
  points: number;
  valid_from: boolean;
  valid_until: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  partner: Partner;
  transaction: Transaction[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.code;
  }
}

export class CouponList {
  count: number;
  data: Coupon[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
