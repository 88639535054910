import { Injectable } from '@angular/core';
import {Activity, ActivityList} from './activity';
import {BaseService} from '../base/base.service';
import {ActivitySerializer} from 'src/app/activities/activity.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService<Activity, ActivityList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'activities' ;
    this.serializer = new ActivitySerializer();
  }
}
