import {Activity} from './activity';
import {BaseSerializer} from '../base/base.serializer';
import {UserSerializer} from '../users/user.serializer';

export class ActivitySerializer extends BaseSerializer<Activity> {
  fromJson(json: any): Activity {
    const serialized = new Activity(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    return serialized;
  }

  toJson(obj: Activity): string {
    let params: any = {activity: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
