<ng-template let-row="row" let-value="value" let-col="col" let-all="all" #customCellTemplate>
  <span>
    <ng-container *ngIf="col.prop === 'status'">{{ row.humanStatus() }}</ng-container>
    <ng-container [ngSwitch]="col.type" *ngIf="col.prop !== 'status'">
      <ng-container *ngSwitchDefault>{{ value }}</ng-container>
      <ng-container *ngSwitchCase="columnTypes.Date">{{ value | date:'shortDate' }}</ng-container>
      <ng-container *ngSwitchCase="columnTypes.DateTime">{{ value | date:'shortDate' }}</ng-container>
    </ng-container>
  </span>
</ng-template>

<ng-template let-data="data" #customFooterTemplate>
  <span>{{ data?.length }} Einträge</span>
</ng-template>

<app-split-view-table [model]="'personalized_card_orders'"
                      [all]="allColumns"
                      [quickFilters]="quickFilters"
                      [sort]="defaultSort"
                      [customCellTemplate]="customCellTemplate"
                      [customFooterTemplate]="customFooterTemplate"
                      [forceFullScreen]="false"></app-split-view-table>
