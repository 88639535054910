import {SepaMandate} from './sepa_mandate';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';
import {ContractSerializer} from '../contracts/contract.serializer';

export class SepaMandateSerializer extends BaseSerializer<SepaMandate> {
  fromJson(json: any): SepaMandate {
    const serialized = new SepaMandate(json);
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    if (json.contract) {
      serialized.contract = new ContractSerializer().fromJson(json.contract);
    }
    return serialized;
  }

  toJson(obj: SepaMandate): string {
    let params: any = {sepa_mandate: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
