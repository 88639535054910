import {Device} from './device';
import {BaseSerializer} from '../base/base.serializer';
import {UserSerializer} from '../users/user.serializer';

export class DeviceSerializer extends BaseSerializer<Device> {
  fromJson(json: any): Device {
    const serialized = new Device(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    return serialized;
  }

  toJson(obj: Device): string {
    let params: any = {device: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
