import { Injectable } from '@angular/core';
import {Invitation, InvitationList} from './invitation';
import {BaseService} from '../base/base.service';
import {InvitationSerializer} from 'src/app/invitations/invitation.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvitationService extends BaseService<Invitation, InvitationList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'invitations' ;
    this.serializer = new InvitationSerializer();
  }
}
