import { Injectable } from '@angular/core';
import {TransactionFlag, TransactionFlagList} from './transaction_flag';
import {BaseService} from '../base/base.service';
import {TransactionFlagSerializer} from 'src/app/transaction_flags/transaction_flag.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionFlagService extends BaseService<TransactionFlag, TransactionFlagList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'transaction_flags' ;
    this.serializer = new TransactionFlagSerializer();
  }
}
