import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { PosSession } from '../pos_sessions/pos_session';

export class PosActivity {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Aktion', prop: 'action', type: ColumnType.String, standard: true}),
    new Column({name: 'tilt', prop: 'tilt', type: ColumnType.String, standard: true}),
    new Column({name: 'Daten', prop: 'data', type: ColumnType.String, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'POS Session', prop: 'pos_session', association: 'belongs_to', columns: PosSession.allColumns}),
  ];

  // Simple Columns
  id: number;
  pos_session_id: number;
  action: string;
  tilt: string;
  data: string;
  created_at: boolean;
  deleted_at: boolean;
  // Associations
  pos_session: PosSession;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.data;
  }
}

export class PosActivityList {
  count: number;
  data: PosActivity[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
