import { Injectable } from '@angular/core';
import {Message, MessageList} from './message';
import {BaseService} from '../base/base.service';
import {MessageSerializer} from 'src/app/messages/message.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseService<Message, MessageList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'messages' ;
    this.serializer = new MessageSerializer();
  }
}
