import {Predicate} from '../shared/enums/predicate.enum';

export class Filter {
  subject: string;
  predicate = Predicate.EQUAL;
  object1 = '';

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
