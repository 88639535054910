import {Injectable} from '@angular/core';
import {Merchant, MerchantList} from './merchant';
import {BaseService} from '../base/base.service';
import {MerchantSerializer} from 'src/app/merchants/merchant.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MerchantService extends BaseService<Merchant, MerchantList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'merchants';
    this.serializer = new MerchantSerializer();
  }

  fetchPassword(id: number): Observable<Merchant> {
    return this.http.get(`${environment.base_url + this.endpoint}/${id}/decrypted_password`).pipe(
        filter(x => !!x),
        take(1),
        map((res: any) => this.serializer.fromJson(res))
    );
  }

  fetchMerchantCenterToken(id: number) {
    return this.http.get(`${environment.base_url + this.endpoint}/${id}/merchantcenter_token`).pipe(
      filter(x => !!x),
      take(1),
      map((res: any) => res)
    );
  }
}
