import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {delay, filter} from 'rxjs/operators';
import {NgForage} from 'ngforage';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-last-visit',
  templateUrl: './last-visit.component.html',
  styleUrls: ['./last-visit.component.scss']
})
export class LastVisitComponent implements OnInit {

  lastVisited = [];

  constructor(private router: Router, private route: ActivatedRoute, private ngForage: NgForage, private title: Title) { }

  ngOnInit() {
    this.ngForage.getItem('lastVisits').then((value: Array<Object>) => {
      if (value != null) {
        this.lastVisited = value;
      }
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      delay(300)
    ).subscribe((event: NavigationEnd) => {
      const currentBase = event.url.split('?')[0].replace('/', '');
      const currentTitle = this.title.getTitle().replace(' | Mankido Admin', '');
      if (!this.lastVisited.map(x => x.title).includes(currentTitle)) {
        const baseMatch = this.lastVisited.findIndex(val => val.base === currentBase);
        if (baseMatch !== -1) {
          this.lastVisited.splice(baseMatch, 1);
        }
        this.lastVisited.unshift({title: currentTitle, base: currentBase, url: event.url});
        if (this.lastVisited.length > 5) {
          this.lastVisited.splice(this.lastVisited.length - 1, 1);
        }
        this.ngForage.setItem('lastVisits', this.lastVisited);
      } else {
        const pushIndex = this.lastVisited.findIndex(x => x.title === currentTitle);
        const copy = this.lastVisited[pushIndex];
        this.lastVisited.splice(pushIndex, 1);
        this.lastVisited.unshift(copy);
      }
    });
  }

  goTo(url: string, e) {
    e.preventDefault();
    this.router.navigateByUrl(url);
  }

}
