import { ColumnType } from '../enums/column-type.enum';
import {TreeviewItem} from 'ngx-treeview';
import {Predicate} from '../enums/predicate.enum';

/**
 * @typedef {Object} Column                 - Adds a new column to the list
 * @property {string} name                  - human readable name of column
 * @property {string} prop                  - property for backend
 * @property {ColumnType} type              - type of column, see ColumnType
 * @property {number} [flexGrow=1]          - flex grow (width), obviously
 * @property {boolean} [editable=true]      - is column editable when using mass-edit?
 * @property {string} [tooltip=null]        - optional tooltip when hovering
 * @property {boolean} [standard=false]      - is column shown by default?
 * @property {number} [order]               - default ordering (only when standard is true)
 * @property {string} [standardProps]       - ONLY for associations & when standard is true: standard props of associated columns
 * @property {string} [association]         - type of association (belongs_to, has_many, has_one)
 * @property {Array.<Column>} [columns]     - only for associations: associated allColumns
 * @property {string} [plural]              - sets automatically in url service, don't touch
 * @property {Object} [options]             - Object to choose from
 * @property {Function} [asyncOptions]      - Option to load options asynchronously
 */

export class Column {
  name: string;
  prop: string;
  type: ColumnType = ColumnType.String;
  flexGrow = 1;
  editable = true;
  tooltip: string = null;
  standard = false;
  order?: number;
  standardProps?: string;
  association?: string;
  columns?: Column[];
  plural?: string;
  options?: Object;
  asyncOptions?: Function;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  static objectMap(obj, cols: Column[], recursive, colString?: string): any {
    cols.forEach(value => {
      const recursive2 = [recursive, value.prop].filter(n => n).join('.');
      if (value.association) {
        obj.children.push(new TreeviewItem(this.objectMap({
          text: value.name,
          value: Math.round(Math.random() * 1000),
          collapsed: true,
          children: []
        }, value.columns, recursive2, colString)));
      } else {
        let checkOpt = false;
        if (colString) { checkOpt = (colString.split(',').includes(recursive2)); }
        obj.children.push(new TreeviewItem({
          checked: checkOpt,
          text: value.name,
          value: recursive2
        }));
      }
    });
    obj.children.sort((a, b) => {
      const aL = (a.children) ? a.children.length : 0;
      const bL = (b.children) ? b.children.length : 0;
      return aL - bL;
    });
    return obj;
  }

  static asTree(columns: Column[], colString?: string) {
    return [new TreeviewItem(this.objectMap({text: 'Alle', value: 9, children: []}, columns, null, colString))];
  }

  static getPredicateString(predicate: Predicate): string {
    switch (predicate) {
      case Predicate.EQUAL:                 return 'eq';
      case Predicate.NOT_EQUAL:             return 'not_eq';
      case Predicate.SMALLER_THAN:          return 'lt';
      case Predicate.GREATER_THAN:          return 'gt';
      case Predicate.EQUAL_OR_SMALLER_THAN: return 'lteq';
      case Predicate.EQUAL_OR_GREATER_THAN: return 'gteq';
      case Predicate.EMPTY:                 return 'null';
      case Predicate.NOT_EMPTY:             return 'not_null';
      case Predicate.BEGINS_WITH:           return 'start';
      case Predicate.NOT_BEGINS_WITH:       return 'not_start';
      case Predicate.CONTAINS:              return 'cont';
      case Predicate.NOT_CONTAINS:          return 'not_cont';
      case Predicate.ENDS_WITH:             return 'end';
      case Predicate.NOT_ENDS_WITH:         return 'not_end';
      case Predicate.IN:                    return 'in';
      default:                              return undefined;
    }
  }

  static getStringPredicate(predicate: string): Predicate {
    switch (predicate) {
      case 'eq':                            return Predicate.EQUAL;
      case 'not_eq':                        return Predicate.NOT_EQUAL;
      case 'lt':                            return Predicate.SMALLER_THAN;
      case 'gt':                            return Predicate.GREATER_THAN;
      case 'lteq':                          return Predicate.EQUAL_OR_SMALLER_THAN;
      case 'gteq':                          return Predicate.EQUAL_OR_GREATER_THAN;
      case 'null':                          return Predicate.EMPTY;
      case 'not_null':                      return Predicate.NOT_EMPTY;
      case 'start':                         return Predicate.BEGINS_WITH;
      case 'not_start':                     return Predicate.NOT_BEGINS_WITH;
      case 'cont':                          return Predicate.CONTAINS;
      case 'not_cont':                      return Predicate.NOT_CONTAINS;
      case 'end':                           return Predicate.ENDS_WITH;
      case 'not_end':                       return Predicate.NOT_ENDS_WITH;
      case 'in':                            return Predicate.IN;
      default:                              return undefined;
    }
  }

  /**
   * searches & returns column of property
   * @param {string} property
   * @param {Array.<Column>} inColumns
   * @return {Column}
   */
  static findColumn(property: string, inColumns: Column[]): Column {
    let foundColumn: Column;
    if (property.includes('.')) {
      const splitted_property = property.split(/\./g);
      const point_count = splitted_property.length - 1;
      let current_column = inColumns;
      for (let i = 0; i <= point_count; i++) {
        const find_result = current_column.find(c => c.prop === splitted_property[i]);
        if (find_result) {
          (!!find_result.columns) ? current_column = find_result.columns : foundColumn = find_result;
        } else {
          foundColumn = inColumns.find(c => c.prop === property);
        }
      }
    } else {
      foundColumn = inColumns.find(c => c.prop === property);
    }
    return foundColumn;
  }
}
