<accordion>
  <accordion-group *ngIf="showcolumns">
    <button class="btn btn-link cardhead" accordion-heading>
      Spalten <span class="badge badge-primary">{{ selectedColumnsCount }}</span>
    </button>
    <ngx-treeview [items]="tree" [config]="treeviewConfig" (selectedChange)="onColumnsChange($event)"></ngx-treeview>
  </accordion-group>

  <accordion-group *ngIf="all && group">
    <button class="btn btn-link cardhead" accordion-heading>
      Filter <span class="badge badge-primary">{{ filterCount }}</span>
    </button>
    <app-filter [columns]="all" [group]="group"></app-filter>
  </accordion-group>
</accordion>
