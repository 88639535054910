import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Partner } from '../partners/partner';
import { Asset } from '../assets/asset';
import { Offer } from '../offers/offer';
import { AutomationEvent } from '../automation_events/automation_event';

export class Teaser {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Titel', prop: 'title', type: ColumnType.String, standard: true}),
    new Column({name: 'Beschreibung', prop: 'description', type: ColumnType.String, standard: true}),
    new Column({name: 'Bedingungen', prop: 'terms', type: ColumnType.String, standard: true}),
    new Column({name: 'Deal-Typ', prop: 'deal_type', type: ColumnType.Number, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Alter Preis', prop: 'old_price', type: ColumnType.Number}),
    new Column({name: 'Neuer Preis', prop: 'new_price', type: ColumnType.Number}),
    new Column({name: 'percent_amount', prop: 'percent_amount', type: ColumnType.Number}),
    new Column({name: 'amount_x', prop: 'amount_x', type: ColumnType.Number}),
    new Column({name: 'amount_y', prop: 'amount_y', type: ColumnType.Number}),
    new Column({name: 'Fester Betrag', prop: 'fixed_amount', type: ColumnType.Number}),
    new Column({name: 'Alte Größe', prop: 'old_size', type: ColumnType.String}),
    new Column({name: 'Neue Größe', prop: 'new_size', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'type', prop: 'type', type: ColumnType.String}),
    new Column({name: 'Endet am', prop: 'ends_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns}),
    new Column({name: 'asset', prop: 'asset', association: 'belongs_to', columns: Asset.allColumns}),
  ];

  // Simple Columns
  id: number;
  title: string;
  description: string;
  terms: string;
  deal_type: number;
  deleted_at: boolean;
  old_price: number;
  new_price: number;
  percent_amount: number;
  amount_x: number;
  amount_y: number;
  fixed_amount: number;
  old_size: string;
  new_size: string;
  created_at: boolean;
  updated_at: boolean;
  asset_id: number;
  partner_id: number;
  type: string;
  ends_at: boolean;
  // Associations
  partner: Partner;
  asset: Asset;
  offer: Offer[];
  automation_event: AutomationEvent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.title;
  }
}

export class TeaserList {
  count: number;
  data: Teaser[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
