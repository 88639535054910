import {AffiliateDeal} from './affiliate_deal';
import {BaseSerializer} from '../base/base.serializer';
import {AffiliateMerchantSerializer} from '../affiliate_merchants/affiliate_merchant.serializer';

export class AffiliateDealSerializer extends BaseSerializer<AffiliateDeal> {
  fromJson(json: any): AffiliateDeal {
    const serialized = new AffiliateDeal(json);
    if (json.affiliate_merchant) {
      serialized.affiliate_merchant = new AffiliateMerchantSerializer().fromJson(json.affiliate_merchant);
    }
    return serialized;
  }

  toJson(obj: AffiliateDeal): string {
    let params: any = {affiliate_deal: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
