import { Injectable } from '@angular/core';
import {Area, AreaList} from './area';
import {BaseService} from '../base/base.service';
import {AreaSerializer} from 'src/app/areas/area.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends BaseService<Area, AreaList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'areas' ;
    this.serializer = new AreaSerializer();
  }
}
