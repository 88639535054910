<div class="drop-block "
     dndEffectAllowed="copy"
     [dndDropzone]="[]"
     [dndAllowExternal]="true"
     [dndDragoverClass]="'dragover'"
     (dndDrop)="onDrop($event)"
     (dragenter)="onDragEnter($event)"
     (click)="fileInput.click()">
  <input #fileInput type="file" class="d-none" (change)="onInputFile($event)" [accept]="allowedFileTypes" />

  <div *ngIf="isFile"
       class="overlay d-flex flex-column align-items-center justify-content-center text-white">
    <i class="fas fa-2x fa-cloud-upload-alt mb-2"></i>
    <h3>Datei loslassen</h3>
  </div>
  <div *ngIf="!isFile" class="overlay d-flex flex-column align-items-center justify-content-center text-warning">
    <i class="fas fa-2x fa-exclamation-triangle mb-2"></i>
    <h3>Dateiformat nicht unterstützt</h3>
  </div>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <i [class.fad]="!file" [class.fas]="file" class="fa-2x fa-file-pdf mb-2"></i>
    <h5 class="mb-2 text-muted" *ngIf="!file">Wähle eine Datei aus</h5>
    <h5 class="mb-2" *ngIf="file">{{ file.name }}</h5>
  </div>
</div>
