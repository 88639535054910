import { Injectable } from '@angular/core';
import {Membership, MembershipList} from './membership';
import {BaseService} from '../base/base.service';
import {MembershipSerializer} from 'src/app/memberships/membership.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MembershipService extends BaseService<Membership, MembershipList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'memberships' ;
    this.serializer = new MembershipSerializer();
  }
}
