import { GalleryComponent } from './../base/gallery/gallery.component';
import { AssetsGalleryComponent } from './assets-gallery/assets-gallery.component';
import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';









@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    TabsModule,
    AccordionModule,
    SharedModule,
    TooltipModule.forRoot()
  ],
  declarations: [
    GalleryComponent,
    AssetsGalleryComponent
  ]
})
export class AssetsModule { }
