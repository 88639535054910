import { Injectable } from '@angular/core';
import { User, UserList } from './user';
import { BaseService } from '../base/base.service';
import { UserSerializer } from 'src/app/users/user.serializer';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User, UserList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'users';
    this.serializer = new UserSerializer();
  }

  merge_into(oldid: number, id: number): Observable<User> {
    return this.http.put(`${environment.base_url + this.endpoint}/${oldid}/merge_into/${id}`, null).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.base_url + this.endpoint}/${id}/deep_destroy`)
      .pipe(
        filter(x => !!x),
        take(1)
      );
  }

  resend_confirmation_email(id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${id}/resend_confirmation_mail`, null)
      .pipe(
        filter(x => !!x),
        take(1)
      );
  }

  confirm(id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${id}/confirm`, null)
      .pipe(
        filter(x => !!x),
        take(1)
      );
  }

  resend_otc_email(id: number, regenerate = false) {
    const body = regenerate ? {regenerate: true} : {};
    return this.http.post(`${environment.base_url + this.endpoint}/${id}/otc`, body)
      .pipe(
        filter(x => !!x),
        take(1)
      );
  }

  downloadGDPR(id: number) {
    return this.http.get(`${environment.base_url}${this.endpoint}/${id}/gdpr`, {responseType: 'blob'}).pipe(
      map(res => new Blob([res], {type: 'application/pdf'}))
    );
  }
}
