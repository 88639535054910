import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { User } from '../users/user';
import { DealAutomation } from '../deal_automations/deal_automation';
import { Deal } from '../deals/deal';
import { Membership } from '../memberships/membership';
import { Partner } from '../partners/partner';

export class AutomationEvent {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, standard: true}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns}),
    new Column({name: 'Dealautomatisierung', prop: 'deal_automation', association: 'belongs_to', columns: DealAutomation.allColumns}),
    new Column({name: 'Deal', prop: 'deal', association: 'belongs_to', columns: Deal.allColumns}),
    new Column({name: 'Mitgliedschaft', prop: 'membership', association: 'has_one', columns: Membership.allColumns}),
    new Column({name: 'Partner', prop: 'partner', association: 'has_one', columns: Partner.allColumns}),
  ];

  // Simple Columns
  id: number;
  user_id: number;
  deal_id: number;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  deal_automation_id: number;
  // Associations
  user: User;
  deal_automation: DealAutomation;
  deal: Deal;
  membership: Membership;
  partner: Partner;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.id;
  }
}

export class AutomationEventList {
  count: number;
  data: AutomationEvent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
