import { Injectable } from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpClient} from '@angular/common/http';
import {WebshopRequest, WebshopRequestList} from './webshop_request';
import {WebshopRequestSerializer} from './webshop_request.serializer';

@Injectable({
  providedIn: 'root'
})
export class WebshopRequestService extends BaseService<WebshopRequest, WebshopRequestList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'webshop_requests' ;
    this.serializer = new WebshopRequestSerializer();
  }
}
