<form *ngFor="let g of group; let i = index" (change)="onChange()">
  <div class="condition-group">
    <button class="btn btn-sm delete-btn" (click)="onDeleteGroup(i)">Gruppe löschen <i class="fa fa-trash-alt fa-fw"></i></button>
    Verknüpfung: <select class="form-control" [(ngModel)]="g.m" name="operator">
      <option [ngValue]="'and'">UND</option>
      <option [ngValue]="'or'">ODER</option>
    </select>

    <app-condition *ngFor="let f of g.filter; let fi = index" [columns]="columns" [filter]="f"
                   [groupIndex]="i" [index]="fi" (sub)="onSubmitFilter($event)"></app-condition>

    <button class="btn btn-sm" (click)="onAddCondition(i)">+ Neue Bedingung</button>
  </div>
</form>
<button class="btn btn-sm" (click)="onAddGroup()">+ Neue Gruppe</button>
