import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Tablet } from '../tablets/tablet';
import { Business } from '../businesses/business';
import { Administrator } from '../administrators/administrator';
// import { Administrator } from '../created_bies/created_by';
// import { Administrator } from '../deactivated_bies/deactivated_by';
import { Transaction } from '../transactions/transaction';
import { PosSession } from '../pos_sessions/pos_session';

export class Terminal {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Hersteller', prop: 'manufacturer', type: ColumnType.String}),
    new Column({name: 'Modell', prop: 'model', type: ColumnType.String}),
    new Column({name: 'Seriennummer', prop: 'serial_number', type: ColumnType.String}),
    new Column({name: 'UUID', prop: 'uuid', type: ColumnType.String}),
    // new Column({name: 'Standort ID', prop: 'business_id', type: ColumnType.Number}),
    new Column({name: 'token_issued_at', prop: 'token_issued_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Business ID', prop: 'business_id', type: ColumnType.Number}),
    new Column({name: 'Letzter Kontakt', prop: 'last_ping_at', type: ColumnType.DateTime, standard: true, order: 3, flexGrow: .4}),
    new Column({name: 'Letzte IP', prop: 'last_ip', type: ColumnType.String}),
    new Column({name: 'Letztes kippen', prop: 'last_tilt', type: ColumnType.String}),
    new Column({name: 'Zuletzt benutzt am', prop: 'last_used_at', type: ColumnType.DateTime, standard: true, order: 2, flexGrow: .4}),
    new Column({name: 'Einstellungen', prop: 'settings', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Deaktiviert am', prop: 'deactivated_at', type: ColumnType.DateTime}),
    new Column({name: 'Letzte Authorisierung', prop: 'last_auth', type: ColumnType.Number}),
    new Column({name: 'public_key', prop: 'public_key', type: ColumnType.String}),
    new Column({name: 'Aktiviert am', prop: 'activated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Tablet', prop: 'tablet', association: 'belongs_to', columns: Tablet.allColumns, standard: true,
    standardProps: 'name,serial_number,color,cellular', order: 1}),
    new Column({name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns}),
    new Column({name: 'administrator', prop: 'administrator', association: 'belongs_to', columns: Administrator.allColumns,
      standard: true, standardProps: 'name', order: 4}),
    new Column({name: 'created_by', prop: 'created_by', association: 'belongs_to', columns: Administrator.allColumns}),
    new Column({name: 'deactivated_by', prop: 'deactivated_by', association: 'belongs_to', columns: Administrator.allColumns}),
  ];

  // Simple Columns
  id: number;
  tablet_id: number;
  business_id: number;
  manufacturer: string;
  model: string;
  serial_number: string;
  uuid: string;
  token_issued_at: Date;
  deleted_at: Date;
  last_ping_at: Date;
  last_ip: string;
  last_tilt: string;
  last_used_at: Date;
  settings: string;
  created_at: Date;
  created_by_id: number;
  deactivated_at: Date;
  deactivated_by_id: number;
  last_auth: number;
  public_key: string;
  administrator_id: number;
  activated_at: Date;
  // Associations
  tablet: Tablet;
  business: Business;
  administrator: Administrator;
  created_by: Administrator;
  deactivated_by: Administrator;
  transaction: Transaction[];
  pos_session: PosSession[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.serial_number ? this.serial_number : this.id;
  }
}

export class TerminalList {
  count: number;
  data: Terminal[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
