import {Terminal} from './terminal';
import {BaseSerializer} from '../base/base.serializer';
import {TabletSerializer} from '../tablets/tablet.serializer';
import {BusinessSerializer} from '../businesses/business.serializer';
import {AdministratorSerializer} from '../administrators/administrator.serializer';
// import {AdministratorSerializer} from '../created_bies/created_by.serializer';
// import {AdministratorSerializer} from '../deactivated_bies/deactivated_by.serializer';

export class TerminalSerializer extends BaseSerializer<Terminal> {
  fromJson(json: any): Terminal {
    const serialized = new Terminal(json);
    if (json.tablet) {
      serialized.tablet = new TabletSerializer().fromJson(json.tablet);
    }
    if (json.business) {
      serialized.business = new BusinessSerializer().fromJson(json.business);
    }
    if (json.administrator) {
      serialized.administrator = new AdministratorSerializer().fromJson(json.administrator);
    }
    if (json.created_by) {
      serialized.created_by = new AdministratorSerializer().fromJson(json.created_by);
    }
    if (json.deactivated_by) {
      serialized.deactivated_by = new AdministratorSerializer().fromJson(json.deactivated_by);
    }
    return serialized;
  }

  toJson(obj: Terminal): string {
    let params: any = {terminal: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
