import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Administrator, AdministratorList} from './administrator';
import {HttpClient} from '@angular/common/http';
import {ReplaySubject} from 'rxjs';
import {BaseService} from '../base/base.service';
import {AdminSerializer} from './admin.serializer';
import {Permission} from '../shared/permissions/permission';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService extends BaseService<Administrator, AdministratorList> {
  currentAdministrator: Subject<Administrator> = new ReplaySubject<Administrator>(1);
  admin: Administrator;

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'administrators';
    this.serializer = new AdminSerializer();
  }

  public setCurrentAdministrator(newAdministrator: Administrator): void {
    this.admin = newAdministrator;
    this.currentAdministrator.next(newAdministrator);
    Sentry.setUser({username: newAdministrator.name, id: newAdministrator.id});
  }

  allowed(permission: string): boolean {
    return Permission.allowed(this.admin.permissions, permission);
  }
}
