import {Coupon} from './coupon';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class CouponSerializer extends BaseSerializer<Coupon> {
  fromJson(json: any): Coupon {
    const serialized = new Coupon(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: Coupon): string {
    let params: any = {coupon: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
