<form class="form-signin text-center" (submit)="login()">
  <img class="mb-4" src="/assets/logo.svg" alt="" width="72" height="72">
  <h1 class="h3 mb-3 font-weight-normal">Bitte anmelden</h1>
  <label for="inputEmail" class="sr-only">Email address</label>
  <input type="email" class="form-control block" id="inputEmail" placeholder="E-Mail Adresse" [(ngModel)]="user.mail"
         name="email" required autofocus>
  <label for="inputPassword" class="sr-only">Password</label>
  <input type="password" class="form-control" id="inputPassword" placeholder="Passwort" [(ngModel)]="user.pass"
         name="password" required>
  <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="loading">Anmelden</button>
</form>
