import { Column } from '../shared/models/column';
import { ColumnType } from '../shared/enums/column-type.enum';
import { Partner } from '../partners/partner';
import { AddonTypes } from './addon_types';

export class AddonSubscription {
  // allColumns for DataTable
  static allColumns: Column[] = [
    // Associated Columns
    new Column({
      name: 'partner',
      prop: 'partner',
      association: 'belongs_to',
      standardProps: 'name',
      standard: true,
      columns: Partner.allColumns,
      flexGrow: 2
    }),

    new Column({name: 'id', prop: 'id', type: ColumnType.Number, standard: true, flexGrow: 1}),
    new Column({name: 'Abonniert am', prop: 'subscribed_at', type: ColumnType.DateTime, standard: true, flexGrow: 2}),
    new Column({name: 'Abonniert bis', prop: 'unsubscribed_at', type: ColumnType.DateTime, standard: true, flexGrow: 2}),
    new Column({name: 'Addon Typ', prop: 'addon_type', type: ColumnType.Number, standard: true, flexGrow: 2}),
    new Column({name: 'Reviews Aktuell', prop: 'quantity_current_month', type: ColumnType.Number, standard: true, flexGrow: 2}),
    new Column({name: 'Reviews Last', prop: 'quantity_last_month', type: ColumnType.Number, standard: true, flexGrow: 2}),
    new Column({name: 'Aktive Automatisierung', prop: 'has_active_automation', type: ColumnType.Boolean, standard: true, flexGrow: 2}),
    new Column({name: 'deleted_at', prop: 'deleted_at', type: ColumnType.DateTime, standard: true, flexGrow: 2}),
    new Column({name: 'created_at', prop: 'created_at', type: ColumnType.DateTime, flexGrow: 2}),
    new Column({name: 'updated_at', prop: 'updated_at', type: ColumnType.DateTime, flexGrow: 2}),
  ];

  // Simple Columns
  id: number;
  partner_id: number;
  subscribed_at: Date;
  unsubscribed_at: Date;
  addon_type: AddonTypes;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  quantity_current_month: number;
  quantity_last_month: number;
  has_active_automation: boolean;
  // Associations
  partner: Partner;
  billing: { quantity: number, price: number, total: number };

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    const addonTypeNames = {
      'obtain_review': 'Google AddOn',
      'referral': 'Kunden werben Kunden',
      'custom_campaigns': 'Erweiterter Kampagnen Editor'
    };
    const addonTypeName = addonTypeNames[this.addon_type];
    return `${addonTypeName} [${this.id}]`;
  }
}

export class AddonSubscriptionList {
  count: number;
  data: AddonSubscription[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
