import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contract } from '../../contracts/contract';
import { PersonalizedCardOrder } from '../personalized-card-order';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextGenerator } from './text-generator.service';
import { filter, map, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ClientService } from '../../clients/client.service';
import { Subject } from 'rxjs';
import { PersonalizedCardOrderService } from '../personalized-card-order.service';
import { RansackTableService } from '../../base/base-list/services/ransack-table.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-personalized-card-order-form',
  templateUrl: './personalized-card-order-form.component.html',
  styleUrls: ['./personalized-card-order-form.component.scss']
})
export class PersonalizedCardOrderFormComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  protected readonly Contract = Contract;
  protected readonly Object = Object;
  protected readonly PersonalizedCardOrder = PersonalizedCardOrder;

  form: FormGroup;
  emailSubject: string;

  syncFactsToText = true;
  showManualEditAlert = false;
  showEmailTextEditor = true;
  saving = false;

  constructor(private formBuilder: FormBuilder,
              private clientService: ClientService,
              private personalizedCardOrderService: PersonalizedCardOrderService,
              private ransackTableService: RansackTableService,
              private router: Router,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.buildForm();
    this.resetForm();
    this.subscribeToValueChanges();
    this.subscribeToClientChanges();
    this.form.get('facts').updateValueAndValidity({onlySelf: false, emitEvent: true});
    this.form.get('text').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      tap(() => this.syncFactsToText = false)
    ).subscribe();
    this.subscribeToNavigationEndEvent();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleEmailTextEditor() {
    this.showEmailTextEditor = !this.showEmailTextEditor;
  }

  submit() {
    const personalizedCardOrder = new PersonalizedCardOrder({
      ...this.form.get('facts').value,
      text: this.form.get('text').value,
      print_template: {file: this.form.get('facts.print_template').value},
      attachment_one: {file: this.form.get('facts.attachment_one').value},
      attachment_two: {file: this.form.get('facts.attachment_two').value},
    });

    this.saving = true;
    this.personalizedCardOrderService.create(personalizedCardOrder).pipe(
      tap(order => {
        this.ransackTableService.refresh();
        this.toastrService.success('Kartenbestellung erfolgreich erstellt');
        this.router.navigate(['personalized_card_orders', order.id]);
      }),
      tap(() => this.saving = false)
    ).subscribe(_ => {}, error => {
      console.log(error);
      this.toastrService.error(error.message, 'Fehler beim Erstellen der Kartenbestellung')
    });
  }

  public resyncText() {
    this.syncFactsToText = true;
    this.showManualEditAlert = false;

    const order = new PersonalizedCardOrder(this.form.get('facts').value);
    const comment = this.form.get('facts.comment').value;
    this.generateTextFromFacts(order, comment);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      facts: this.formBuilder.group({
        client_id: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        print_finish: [null, [Validators.required]],
        print_template: [null, []],
        attachment_one: [null, []],
        attachment_two: [null, []],

        comment: [null, []],

        company_name: [null, []],
        contact_person: [null, [Validators.required]],
        address: [null, [Validators.required]],
        zip: [null, [Validators.required]],
        city: [null, [Validators.required]],
        country: [null, []]
      }),

      text: [null, [Validators.required]],
    });
  }

  private subscribeToNavigationEndEvent() {
    this.router.events.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(event => event instanceof NavigationEnd),
      throttleTime(1000),
      tap(_ => {
        this.resetForm();
      }),
    ).subscribe();
  }

  private resetForm() {
    this.form.reset({
      facts: {
        quantity: 1000,
        print_finish: PersonalizedCardOrder.DEFAULT_PRINT_FINISH,
      }
    });
    this.resyncText();
  }

  private subscribeToValueChanges() {
    this.form.get('facts').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      map(value => {
        return [new PersonalizedCardOrder(value), value.comment];
      }),
      tap(_ => {
        this.showEmailTextEditor = false;
        if (!this.syncFactsToText) {
          this.showManualEditAlert = true;
        }
      }),
      tap(([order, _]) => {
        this.emailSubject = order.emailSubject();
      })
    ).subscribe(([order, comment]) => {
      if (this.syncFactsToText) {
        this.generateTextFromFacts(order, comment);
      }
    });
  }

  private generateTextFromFacts(order: PersonalizedCardOrder, comment: string) {
    const emailText = TextGenerator.generate(order, comment);
    this.form.controls.text.setValue(emailText, {emitEvent: false, onlySelf: true});
  }

  private subscribeToClientChanges() {
    this.form.get('facts').get('client_id').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(clientId => clientId !== null),
      switchMap(clientId => this.clientService.fetch(clientId)),
      tap(client => {
        this.form.patchValue({
          facts: {
            company_name: client.company,
            contact_person: client.contact,
            address: client.address,
            zip: client.zip,
            city: client.city
          }
        });
      })
    ).subscribe();
  }
}
