import { Injectable } from '@angular/core';
import {Terminal, TerminalList} from './terminal';
import {BaseService} from '../base/base.service';
import {TerminalSerializer} from 'src/app/terminals/terminal.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TerminalService extends BaseService<Terminal, TerminalList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'terminals' ;
    this.serializer = new TerminalSerializer();
  }

  public deactivate(id: number) {
    return this.http.put(`${environment.base_url + this.endpoint}/${id}/deactivate`, {}).pipe(map(res => {
      return this.serializer.fromJson(res);
    }));
  }
}
