import {PosMessage} from './pos_message';
import {BaseSerializer} from '../base/base.serializer';
import {TabletSerializer} from '../tablets/tablet.serializer';

export class PosMessageSerializer extends BaseSerializer<PosMessage> {
  fromJson(json: any): PosMessage {
    const serialized = new PosMessage(json);
    if (json.tablet) {
      serialized.tablet = new TabletSerializer().fromJson(json.tablet);
    }
    return serialized;
  }

  toJson(obj: PosMessage): string {
    let params: any = {pos_message: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
