export class RecurringEvent {
  id: number;
  rule: 'singular' | 'daily' | 'weekly' | 'monthly' | 'yearly' | any; // TODO: is | 0 necessary?
  starts_at: Date;
  ends_at: Date;
  _destroy: boolean;

  constructor(values: Partial<RecurringEvent> = {}) {
    Object.assign(this, values);
    if (this.starts_at != null) {
      this.starts_at = new Date(this.starts_at);
    }
    if (this.ends_at != null) {
      this.ends_at = new Date(this.ends_at);
    }
  }
}
