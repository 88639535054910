import {PosActivity} from './pos_activity';
import {BaseSerializer} from '../base/base.serializer';
import {PosSessionSerializer} from '../pos_sessions/pos_session.serializer';

export class PosActivitySerializer extends BaseSerializer<PosActivity> {
  fromJson(json: any): PosActivity {
    const serialized = new PosActivity(json);
    if (json.pos_session) {
      serialized.pos_session = new PosSessionSerializer().fromJson(json.pos_session);
    }
    return serialized;
  }

  toJson(obj: PosActivity): string {
    let params: any = {pos_activity: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
