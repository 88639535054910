import { Injectable } from '@angular/core';
import {ContentElement, ContentElementList} from './content_element';
import {BaseService} from '../base/base.service';
import {ContentElementSerializer} from 'src/app/content_elements/content_element.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContentElementService extends BaseService<ContentElement, ContentElementList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'content_elements' ;
    this.serializer = new ContentElementSerializer();
  }
}
