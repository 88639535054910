import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Message } from '../messages/message';
import { Offer } from '../offers/offer';

export class MessageAttachment {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'attachment_type', prop: 'attachment_type', type: ColumnType.String, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Nachricht', prop: 'message', association: 'belongs_to', columns: Message.allColumns}),
    new Column({name: 'Angebot', prop: 'offer', association: 'belongs_to', columns: Offer.allColumns}),
  ];

  // Simple Columns
  id: number;
  message_id: number;
  attachment_type: string;
  attachment_id: number;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  offer_id: number;
  // Associations
  message: Message;
  offer: Offer;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.attachment_type;
  }
}

export class MessageAttachmentList {
  count: number;
  data: MessageAttachment[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
