import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {User} from '../users/user';
import {Token} from '../tokens/token';

export class Card {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .3}),
    new Column({name: 'Zuletzt benutzt am', prop: 'last_used_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns, standard: true,
      standardProps: 'email,origin_type,origin_id', order: 2}),
    new Column({
      name: 'Token', prop: 'token', association: 'has_one', columns: Token.allColumns, standard: true,
      standardProps: 'token', order: 1
    }),
  ];

  // Simple Columns
  id: number;
  user_id: number;
  last_used_at: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  user: User;
  token: Token;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.token.token ? this.token.token : this.id;
  }
}

export class CardList {
  count: number;
  data: Card[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
