import { Injectable } from '@angular/core';
import {Deal, DealList} from './deal';
import {BaseService} from '../base/base.service';
import {DealSerializer} from 'src/app/deals/deal.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DealService extends BaseService<Deal, DealList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'deals' ;
    this.serializer = new DealSerializer();
  }
}
