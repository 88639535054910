import {MessageAttachment} from './message_attachment';
import {BaseSerializer} from '../base/base.serializer';
import {MessageSerializer} from '../messages/message.serializer';
import {OfferSerializer} from '../offers/offer.serializer';

export class MessageAttachmentSerializer extends BaseSerializer<MessageAttachment> {
  fromJson(json: any): MessageAttachment {
    const serialized = new MessageAttachment(json);
    if (json.message) {
      serialized.message = new MessageSerializer().fromJson(json.message);
    }
    if (json.offer) {
      serialized.offer = new OfferSerializer().fromJson(json.offer);
    }
    return serialized;
  }

  toJson(obj: MessageAttachment): string {
    let params: any = {message_attachment: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
