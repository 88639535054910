import {BaseSerializer} from '../../../../base/base.serializer';
import {Bookmark} from './bookmark';

export class BookmarkSerializer extends BaseSerializer<Bookmark> {
  fromJson(json: any): Bookmark {
    return new Bookmark(json);
  }

  toJson(obj: Bookmark): string {
    let params: any = {bookmark: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
