import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DunningProcess, DunningProcessList } from './dunning_process';
import { HttpClient } from '@angular/common/http';
import { DunningProcessSerializer } from './dunning_process.serializer';
import { environment } from '../../environments/environment';
import { DunningMail } from './dunning-mail';

@Injectable({
  providedIn: 'root'
})
export class DunningProcessService extends BaseService<DunningProcess, DunningProcessList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'dunning_processes';
    this.serializer = new DunningProcessSerializer();
  }

  note(id: number, note: string): Observable<DunningProcess> {
    return this.http.post(`${environment.base_url + this.endpoint}/${id}/note`, {'note': note}).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  dunningMail(id: number, dunningMail: DunningMail): Observable<void> {
    return this.http.post<void>(`${environment.base_url + this.endpoint}/${id}/dunning_mail`, {dunning_mail: dunningMail});
  }
}
