import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';
import { PersonalizedCardOrder } from './personalized-card-order';

export class PersonalizedCardOrderSerializer extends BaseSerializer<PersonalizedCardOrder> {
  fromJson(json: any): PersonalizedCardOrder {
    const serialized = new PersonalizedCardOrder(json);
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    return serialized;
  }

  toJson(obj: PersonalizedCardOrder): string {
    let params: any = {personalized_card_order: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
