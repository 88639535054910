import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Filter } from '../filter';
import { Predicate } from '../../shared/enums/predicate.enum';
import { ColumnType } from '../../shared/enums/column-type.enum';
import { DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Column } from '../../shared/models/column';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit, AfterViewInit {

  @Input() columns: Column[];
  @Input() filter: Filter;
  @Input() index: number;
  @Input() groupIndex: number;
  @Output() sub = new EventEmitter();
  @ViewChild(DropdownTreeviewComponent, {static: true}) dropdownTreeviewComponent: DropdownTreeviewComponent;
  @ViewChild('treeviewComponent', {static: false, read: ElementRef}) dropdownTreeviewElement: ElementRef;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasCollapseExpand: false,
    hasFilter: false,
    maxHeight: 500
  });
  predicate = Predicate;
  columnType = ColumnType;
  Object = Object;
  items;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
    this.items = Column.asTree(this.columns, this.filter.subject)[0].children;
    if (this.filter.subject) {
      this.select(new Event('mouseover'), new TreeviewItem({
        text: Column.findColumn(this.filter.subject, this.columns).name,
        value: this.filter.subject
      }));
    }
  }

  ngAfterViewInit() {
    if (!this.filter.subject) {
      setTimeout(() => {
        this.openNgxDropdownTreeview();
      }, 0);
    }
  }

  getColumnType(property: string): ColumnType {
    return Column.findColumn(property, this.columns).type;
  }

  hasOptions(property: string): boolean {
    return !!this.getOptions(property) || !!this.getAsyncOptions(property);
  }

  getOptions(property: string): Object {
    return Column.findColumn(property, this.columns).options;
  }

  getAsyncOptions(property: string): Function {
    return Column.findColumn(property, this.columns).asyncOptions;
  }

  onChange() {
    this.sub.emit({index: this.index, groupIndex: this.groupIndex, filter: this.filter});
  }

  onDeleteFilter() {
    this.filter = null;
    this.onChange();
  }

  get options() {
    if (Number.isNaN(+Object.keys(this.getOptions(this.filter.subject))[0])) {
      return Object.keys(this.getOptions(this.filter.subject));
    } else {
      return Object.keys(this.getOptions(this.filter.subject))
        .filter(key => key.match(/^[0-9]+$/)); // filter number-only occurences that may come out of an enum
    }
  }

  select(e, item) {
    e.preventDefault();
    if (item.children && item.children.length > 0) {
      return;
    }
    try {
      this.dropdownTreeviewComponent.treeviewComponent.selection.checkedItems = [item];
      this.dropdownTreeviewComponent.onSelectedChange([item]);
    } catch (e) {
    }
    this.filter.subject = item.value;
    this.closeNgxDropdownTreeview();
  }

  private closeNgxDropdownTreeview() {
    if (!this.dropdownTreeviewElement || !this.dropdownTreeviewElement.nativeElement) {
      return;
    }
    this.renderer.removeClass(this.getNgxDropdownElement(), 'show');
    this.renderer.removeClass(this.getNgxDropdownMenuElement(), 'show');
  }

  private openNgxDropdownTreeview() {
    if (!this.dropdownTreeviewElement || !this.dropdownTreeviewElement.nativeElement) {
      return;
    }
    this.renderer.removeClass(this.getNgxDropdownElement(), 'show');
    this.renderer.removeClass(this.getNgxDropdownMenuElement(), 'show');
  }

  private getNgxDropdownElement() {
    return this.dropdownTreeviewElement.nativeElement.querySelector('div[ngxDropdown]');
  }

  private getNgxDropdownMenuElement() {
    return this.dropdownTreeviewElement.nativeElement.querySelector('div[ngxDropdownMenu]');
  }
}
