import {Injectable} from '@angular/core';
import {Token, TokenList} from './token';
import {BaseService} from '../base/base.service';
import {TokenSerializer} from 'src/app/tokens/token.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService extends BaseService<Token, TokenList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'tokens';
    this.serializer = new TokenSerializer();
  }

  massRequest(name: string, count: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/mass_create`, {mass_create: {name, count}});
  }
}
