import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Terminal} from '../terminals/terminal';
import {Business} from '../businesses/business';
import {Token} from '../tokens/token';
import {User} from '../users/user';
import {Membership} from '../memberships/membership';
import {PosActivity} from '../pos_activities/pos_activity';

export class PosSession {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .2}),
    new Column({name: 'started_at', prop: 'started_at', type: ColumnType.DateTime, standard: true, order: 5, flexGrow: .5}),
    new Column({name: 'ended_at', prop: 'ended_at', type: ColumnType.DateTime}),
    new Column({name: 'ip', prop: 'ip', type: ColumnType.String}),
    new Column({name: 'app_version', prop: 'app_version', type: ColumnType.String, standard: true, order: 3, flexGrow: .35}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'started_with', prop: 'started_with', type: ColumnType.String}),
    new Column({name: 'ended_with', prop: 'ended_with', type: ColumnType.String}),
    new Column({name: 'user_confirmed', prop: 'user_confirmed', type: ColumnType.Boolean, standard: true, order: 4, flexGrow: .1}),
    new Column({name: 'Punkte', prop: 'points', type: ColumnType.Number}),
    new Column({name: 'signup', prop: 'signup', type: ColumnType.Boolean}),
    new Column({name: 'first_visit', prop: 'first_visit', type: ColumnType.Boolean}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Terminal', prop: 'terminal', association: 'belongs_to', columns: Terminal.allColumns}),
    new Column({
      name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns, standard: true,
      standardProps: 'partner.logo,partner.name,name', order: 1
    }),
    new Column({name: 'Token', prop: 'token', association: 'belongs_to', columns: Token.allColumns}),
    new Column({
      name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns, standard: true,
      standardProps: 'email', order: 2
    }),
    new Column({name: 'Mitgliedschaft', prop: 'membership', association: 'belongs_to', columns: Membership.allColumns}),
  ];

  // Simple Columns
  id: number;
  started_at: boolean;
  ended_at: boolean;
  terminal_id: number;
  business_id: number;
  token_id: number;
  user_id: number;
  ip: string;
  app_version: string;
  created_at: boolean;
  updated_at: boolean;
  started_with: string;
  ended_with: string;
  user_confirmed: boolean;
  points: number;
  signup: boolean;
  first_visit: boolean;
  membership_id: number;
  deleted_at: boolean;
  // Associations
  terminal: Terminal;
  business: Business;
  token: Token;
  user: User;
  membership: Membership;
  pos_activity: PosActivity[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.started_at;
  }
}

export class PosSessionList {
  count: number;
  data: PosSession[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
