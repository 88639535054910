import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {BookmarkService} from '../bookmarks/bookmark.service';
import {Bookmark} from '../bookmarks/bookmark';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  editMode = false;
  bookmarks: Bookmark[] = [];
  basePath = '';

  constructor(private router: Router, private bookmarkService: BookmarkService) { }


  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.basePath = event.url.split('?')[0].replace('/', '');
    });

    this.bookmarkService.editMode.subscribe(editMode => {
      this.editMode = editMode;
    });

    this.bookmarkService.bookmarks.subscribe(bookmarks => {
      this.bookmarks = bookmarks;
    });
  }

}
