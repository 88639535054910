import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgForage} from 'ngforage';
import {Observable, Subject, ReplaySubject} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AdministratorService} from '../administrators/administrator.service';
import {Administrator} from '../administrators/administrator';
import {JwtHelper} from './jwthelper';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static TOKEN_NAME = 'jwt';
  static AUTH_URL = environment.base_url + 'authenticate';

  contentHeader: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  error: string;

  token: Subject<string> = new ReplaySubject(1);

  constructor(private http: HttpClient, private ngForage: NgForage, private administratorService: AdministratorService) {
    this.token.subscribe(token => {
      this.ngForage.setItem(AuthService.TOKEN_NAME, token);
      if (token !== null) {
        this.setAdministratorFromToken(token);
      }
    });
    this.ngForage.getItem(AuthService.TOKEN_NAME).then((token: string) => {
      this.token.next(token);
    });
  }

  public authenticated(): Observable<boolean> {
    return this.token.asObservable().pipe(
      map(token => token && !JwtHelper.isTokenExpired(token))
    );
  }

  public login(credentials): Observable<boolean> {
    return this.http.post<{ jwt: string }>(AuthService.AUTH_URL, JSON.stringify({auth: credentials}), {headers: this.contentHeader})
      .pipe(
        filter(x => !!x),
        take(1),
        map(data => {
          if (data.jwt) {
            this.token.next(data.jwt);
            return true;
          }
          return false;
        })
      );
  }

  public logout() {
    this.ngForage.removeItem(AuthService.TOKEN_NAME).then(() => {
      this.token.next(null);
    });
  }

  public currentToken(): Observable<string> {
    return this.token;
  }

  private setAdministratorFromToken(token: string) {
    const administratorId: number = JwtHelper.decodeToken(token).sub;
    this.administratorService.fetch(administratorId).subscribe((administrator: Administrator) => {
      this.administratorService.setCurrentAdministrator(administrator);
    });
  }
}
