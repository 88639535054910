import { Injectable } from '@angular/core';
import {Device, DeviceList} from './device';
import {BaseService} from '../base/base.service';
import {DeviceSerializer} from 'src/app/devices/device.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseService<Device, DeviceList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'devices' ;
    this.serializer = new DeviceSerializer();
  }
}
