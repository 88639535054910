import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Message} from '../messages/message';
import {User} from '../users/user';
import {Campaign} from '../campaigns/campaign';
import {DealAutomation} from '../deal_automations/deal_automation';
import {Deal} from '../deals/deal';
import {Offer} from '../offers/offer';
import {MessageStat} from '../message_stats/message_stat';

export class MobileMessage {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .1}),
    new Column({name: 'Angekommen', prop: 'first_delivered_at', type: ColumnType.DateTime, standard: true, order: 2, flexGrow: .2}),
    new Column({name: 'Gelesen', prop: 'first_read_at', type: ColumnType.DateTime, standard: true, order: 3, flexGrow: .2}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns, standard: true,
      standardProps: 'email', order: 1}),
    new Column({name: 'Kampagne', prop: 'campaign', association: 'belongs_to', columns: Campaign.allColumns}),
    new Column({name: 'Nachricht', prop: 'message', association: 'belongs_to', columns: Message.allColumns}),
    new Column({name: 'Dealautomatisierung', prop: 'deal_automation', association: 'belongs_to', columns: DealAutomation.allColumns}),
    new Column({name: 'Deal', prop: 'deal', association: 'belongs_to', columns: Deal.allColumns}),
    new Column({name: 'Angebot', prop: 'offer', association: 'belongs_to', columns: Offer.allColumns}),
    new Column({name: 'message_stat', prop: 'message_stat', association: 'has_one', columns: MessageStat.allColumns}),
  ];

  // Simple Columns
  id: number;
  campaign_id: number;
  user_id: number;
  first_delivered_at: boolean;
  first_read_at: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  message_id: number;
  deal_automation_id: number;
  deal_id: number;
  offer_id: number;
  // Associations
  message: Message;
  user: User;
  campaign: Campaign;
  deal_automation: DealAutomation;
  deal: Deal;
  offer: Offer;
  message_stat: MessageStat;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.message && this.message.humanize() ? this.message.humanize() : this.id;
  }
}

export class MobileMessageList {
  count: number;
  data: MobileMessage[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
