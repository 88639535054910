import { Directive, ElementRef, Host, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { BaseListComponent } from '../base-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PanelStateService } from '../../../shared/ui/panel-state.service';
import { takeUntil, tap } from 'rxjs/operators';
import { DatatableComponent, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { RansackTableDirective } from './ransack-table.directive';

@Directive({
  selector: 'ngx-datatable[appSidebarTable]'
})
export class SidebarTableDirective implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input()
  forceFullScreen = true;


  currentRowId: number | null = null;
  currentTab: string | null = null;

  constructor(@Optional() private baseList: BaseListComponent,
              @Host() private table: DatatableComponent,
              @Host() private ransackDirective: RansackTableDirective,
              private domElement: ElementRef,
              private route: ActivatedRoute,
              private router: Router,
              private panelStateService: PanelStateService) {
  }

  ngOnInit() {
    this.domElement.nativeElement.classList.add('scrollable-table');
    this.configureNgxTable();

    if (this.route.firstChild) {
      this.currentRowId = +this.route.firstChild.snapshot.params.id;
    }

    this.subscribeToActivateEvent();

    this.subscribeToRefreshedEvent();
  }

  @HostListener('window:keydown', ['$event.keyCode', '$event'])
  onKeyDown(keyCode: number, ev: any) {
    if (ev.target.nodeName === 'INPUT' || ev.target.nodeName === 'TEXTAREA' || ev.target.isContentEditable) {
      return;
    }

    if (keyCode === 38) {
      this.moveSelectionByDelta(-1);
      ev.preventDefault();
    } else if (keyCode === 40) {
      this.moveSelectionByDelta(1);
      ev.preventDefault();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRowClass(row: any) {
    return {
      'table-row-clickable': true,
      'table-row-active': row.id === this.currentRowId,
      'zero': row.netto_sum_cents === 0,
      'shown': row.id === this.currentRowId
    };
  }

  private configureNgxTable() {
    this.table.rowClass = this.getRowClass.bind(this);
    this.table.scrollbarV = true;
    this.table.selectionType = SelectionType.checkbox;
    this.table.sortType = SortType.multi;
    this.table.externalPaging = false;
    this.table.externalSorting = false;
  }

  private subscribeToRefreshedEvent() {
    this.ransackDirective.refreshed.pipe(
      takeUntil(this.ngUnsubscribe),
      tap(_ => {
        if (this.currentRowId) {
          this.scrollToId(this.currentRowId);
        }
      })).subscribe();
  }

  private subscribeToActivateEvent() {
    this.table.activate.subscribe(($event) => {
      if ($event.type !== 'click' || $event.cellIndex === 0) {
        return;
      }
      this.currentRowId = +$event.row.id;
      this.show($event.row.id);
    });
  }

  private moveSelectionByDelta(delta: number) {
    if (!this.currentRowId || this.table.rows.length === 0) {
      return;
    }
    const idx = this.table.rows.findIndex((x) => x.id === this.currentRowId) || 0;

    const boxedNextId = Math.min(this.table.rows.length - 1, Math.max(0, idx + delta));
    this.currentRowId = this.table.rows[boxedNextId].id;
    this.show(this.currentRowId);
  }

  private show(id: number) {
    if (this.forceFullScreen) {
      this.panelStateService.setState('left', 'hidden');
      this.panelStateService.setState('right', 'hidden');
    }

    this.router.navigate([this.ransackDirective.model, id], {queryParamsHandling: 'preserve'}).then(() => {
      this.table.rows = [...this.table.rows];
      this.scrollToId(id);
    });
  }

  scrollToId(id) {
    const idx = this.table.rows.findIndex((x) => x.id === id);
    if (idx === -1) {
      return;
    }

    const offsetY: number = +this.table.bodyComponent.offsetY;
    const offsetYEnd: number = this.table.bodyHeight + offsetY;

    const firstShownIndex = this.table.bodyComponent.rowHeightsCache.getRowIndex(offsetY);
    const lastShownIndex = this.table.bodyComponent.rowHeightsCache.getRowIndex(offsetYEnd);

    if (idx < firstShownIndex) {
      this.table.bodyComponent.updateOffsetY(idx / this.table.bodyComponent.pageSize);
    } else if (idx >= lastShownIndex) {
      const newTopIndex = firstShownIndex + (idx - lastShownIndex) + 1;
      this.table.bodyComponent.updateOffsetY(newTopIndex / this.table.bodyComponent.pageSize);
    }
  }
}
