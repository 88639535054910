import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Categorization } from '../categorizations/categorization';
import { Partner } from '../partners/partner';

export class Category {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true}),
    new Column({name: 'short_name_en', prop: 'short_name_en', type: ColumnType.String, standard: true}),
    new Column({name: 'short_name_de', prop: 'short_name_de', type: ColumnType.String, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
  ];

  // Simple Columns
  id: number;
  name: string;
  short_name_en: string;
  short_name_de: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  // Associations
  categorization: Categorization[];
  partner: Partner[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.name;
  }
}

export class CategoryList {
  count: number;
  data: Category[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
