import { Injectable } from '@angular/core';
import {MobileMessage, MobileMessageList} from './mobile_message';
import {BaseService} from '../base/base.service';
import {MobileMessageSerializer} from 'src/app/mobile_messages/mobile_message.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MobileMessageService extends BaseService<MobileMessage, MobileMessageList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'mobile_messages' ;
    this.serializer = new MobileMessageSerializer();
  }
}
