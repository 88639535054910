import { Injectable } from '@angular/core';
import {Coupon, CouponList} from './coupon';
import {BaseService} from '../base/base.service';
import {CouponSerializer} from 'src/app/coupons/coupon.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CouponService extends BaseService<Coupon, CouponList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'coupons' ;
    this.serializer = new CouponSerializer();
  }
}
