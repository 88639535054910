import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PersonalizedCardOrdersComponent } from './personalized-card-orders/personalized-card-orders.component';
import { PersonalizedCardOrdersRoutingModule } from './personalized-card-orders-routing.module';
import { PersonalizedCardOrderListComponent } from './personalized-card-order-list/personalized-card-order-list.component';
import { PersonalizedCardOrderFormComponent } from './personalized-card-order-form/personalized-card-order-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DndModule } from 'ngx-drag-drop';
import { PdfUploaderComponent } from './personalized-card-order-form/pdf-uploader/pdf-uploader.component';
import { TrixModule } from '../trix/trix.module';
import { PersonalizedCardOrderShowComponent } from './personalized-card-order-show/personalized-card-order-show.component';
import { AppModule } from '../app.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


@NgModule({
  declarations: [
    PersonalizedCardOrdersComponent,
    PersonalizedCardOrderListComponent,
    PersonalizedCardOrderFormComponent,
    PdfUploaderComponent,
    PersonalizedCardOrderShowComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PersonalizedCardOrdersRoutingModule,
    BsDropdownModule,
    ReactiveFormsModule,
    UiSwitchModule,
    DndModule,
    TrixModule
  ]
})
export class PersonalizedCardOrdersModule {
}
