import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { User } from '../users/user';
import { PosSession } from '../pos_sessions/pos_session';

export class Token {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .2}),
    new Column({name: 'bearer_type', prop: 'bearer_type', type: ColumnType.String, standard: true, order: 2, flexGrow: .2}),
    new Column({name: 'Token', prop: 'token', type: ColumnType.String, standard: true, order: 1, flexGrow: .3}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns, standard: true,
      standardProps: 'email,origin_type,origin_id', order: 3}),
  ];

  // Simple Columns
  id: number;
  user_id: number;
  bearer_type: string;
  bearer_id: number;
  token: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  user: User;
  pos_session: PosSession[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.token ? this.token : '';
  }
}

export class TokenList {
  count: number;
  data: Token[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
