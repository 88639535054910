import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Tablet } from '../tablets/tablet';

export class TabletDatum {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'altitude', prop: 'altitude', type: ColumnType.Number, standard: true}),
    new Column({name: 'floor_level', prop: 'floor_level', type: ColumnType.Number, standard: true}),
    new Column({name: 'horizontal_accuracy', prop: 'horizontal_accuracy', type: ColumnType.Number, standard: true}),
    new Column({name: 'is_inaccurate', prop: 'is_inaccurate', type: ColumnType.Boolean, standard: true}),
    new Column({name: 'is_old', prop: 'is_old', type: ColumnType.Boolean}),
    new Column({name: 'latitude', prop: 'latitude', type: ColumnType.Number}),
    new Column({name: 'longitude', prop: 'longitude', type: ColumnType.Number}),
    new Column({name: 'location_finished', prop: 'location_finished', type: ColumnType.Boolean}),
    new Column({name: 'location_type', prop: 'location_type', type: ColumnType.String}),
    new Column({name: 'position_type', prop: 'position_type', type: ColumnType.String}),
    new Column({name: 'timestamp', prop: 'timestamp', type: ColumnType.DateTime}),
    new Column({name: 'vertical_accuracy', prop: 'vertical_accuracy', type: ColumnType.Number}),
    new Column({name: 'location_capable', prop: 'location_capable', type: ColumnType.Boolean}),
    new Column({name: 'location_enabled', prop: 'location_enabled', type: ColumnType.Boolean}),
    new Column({name: 'lost_device', prop: 'lost_device', type: ColumnType.String}),
    new Column({name: 'lost_mode_capable', prop: 'lost_mode_capable', type: ColumnType.Boolean}),
    new Column({name: 'lost_mode_enabled', prop: 'lost_mode_enabled', type: ColumnType.Boolean}),
    new Column({name: 'lost_timestamp', prop: 'lost_timestamp', type: ColumnType.DateTime}),
    new Column({name: 'low_power_mode', prop: 'low_power_mode', type: ColumnType.Boolean}),
    new Column({name: 'mesg', prop: 'mesg', type: ColumnType.String}),
    new Column({name: 'battery_level', prop: 'battery_level', type: ColumnType.Number}),
    new Column({name: 'battery_status', prop: 'battery_status', type: ColumnType.String}),
    new Column({name: 'is_locating', prop: 'is_locating', type: ColumnType.Boolean}),
    new Column({name: 'device_status', prop: 'device_status', type: ColumnType.String}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Tablet', prop: 'tablet', association: 'belongs_to', columns: Tablet.allColumns}),
  ];

  // Simple Columns
  id: number;
  tablet_id: number;
  altitude: number;
  floor_level: number;
  horizontal_accuracy: number;
  is_inaccurate: boolean;
  is_old: boolean;
  latitude: number;
  longitude: number;
  location_finished: boolean;
  location_type: string;
  position_type: string;
  timestamp: boolean;
  vertical_accuracy: number;
  location_capable: boolean;
  location_enabled: boolean;
  lost_device: string;
  lost_mode_capable: boolean;
  lost_mode_enabled: boolean;
  lost_timestamp: boolean;
  low_power_mode: boolean;
  mesg: string;
  battery_level: number;
  battery_status: string;
  is_locating: boolean;
  device_status: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  tablet: Tablet;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.mesg;
  }
}

export class TabletDatumList {
  count: number;
  data: TabletDatum[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
