import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Partner } from '../partners/partner';
import { Business } from '../businesses/business';

export class Area {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .3}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'center_lat', prop: 'center_lat', type: ColumnType.Number}),
    new Column({name: 'center_lng', prop: 'center_lng', type: ColumnType.Number}),
    new Column({name: 'Radius', prop: 'radius', type: ColumnType.Number, standard: true, order: 2, flexGrow: .3}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
  ];

  // Simple Columns
  id: number;
  name: string;
  center_lat: number;
  center_lng: number;
  radius: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  partner: Partner[];
  business: Business[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.name;
  }
}

export class AreaList {
  count: number;
  data: Area[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
