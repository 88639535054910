import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {Business} from '../businesses/business';

// import { PosExperienceUsage } from '../pos_experience_usages/pos_experience_usage';

export class PosExperience {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Typ', prop: 'type', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'Eingetragen', prop: 'validDays', type: ColumnType.Custom, standard: true, order: 2}),
    new Column({name: 'Startet am', prop: 'starts_at', type: ColumnType.Date, standard: true, order: 3}),
    new Column({name: 'Endet am', prop: 'ends_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({
      name: 'Partner',
      prop: 'partner',
      association: 'belongs_to',
      columns: Partner.allColumns,
      standard: true,
      standardProps: 'logo,name',
      order: 0
    }),
    new Column({
      name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns,
      standard: true,
      strandardProps: 'name,city', order: 1
    }),
  ];

  // Simple Columns
  id: number;
  partner_id: number;
  business_id: number;
  type: PosExperienceType;
  starts_at: Date;
  ends_at: Date;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  // Associations
  partner: Partner;
  business: Business;

  // pos_experience_usages: PosExperienceUsage[];

  constructor(v: Partial<PosExperience> = {}) {
    Object.assign(this, v);
    this.id = v.id ? v.id : undefined;
    this.type = v.type ? v.type : PosExperienceType.advent_calendar;
    this.partner = v.partner ? new Partner(v.partner) : undefined;
    this.business = v.business ? new Business(v.business) : undefined;
  }

  humanize() {
    return this.partner && this.partner.name ? `${this.partner.name} - ${this.type}` : `#${this.id} - ${this.type}`;
  }
}

export class PosExperienceList {
  count: number;
  data: PosExperience[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class AdventCalendar extends PosExperience {
  config: AdventCalendarConfig[];
  validDays: number;

  constructor(v: Partial<AdventCalendar> = {}) {
    super(v);
    this.config = v.config ? v.config.map(c => new AdventCalendarConfig(c)) : this.setDefaultConfig();
    this.validDays = v.config.filter(c => (!!c.points && c.points > 0) || (!!c.text && c.text.length > 3)).length || 0;
    this.starts_at = new Date(new Date().getFullYear(), 11, 1, 0, 0, 0);
    this.ends_at = new Date(new Date().getFullYear(), 11, 25, 0, 0, 0);
  }

  private setDefaultConfig() {
    const a = [];
    for (let i = 1; i <= 24; i++) {
      a.push(new AdventCalendarConfig());
    }
    return a;
  }
}

export class AdventCalendarConfig {
  name: string;
  surprise_type: SurpriseType;
  text: string;
  points: number;

  constructor(v: Partial<AdventCalendarConfig> = {}) {
    Object.assign(this, v);
    this.name = v.name ? v.name : undefined;
    this.surprise_type = v.surprise_type ? v.surprise_type : SurpriseType.points;
    this.text = v.text ? v.text : undefined;
    this.points = v.points ? v.points : undefined;
  }
}

export enum PosExperienceType {
  advent_calendar = 'advent_calendar',
  wheel_of_luck = 'wheel_of_luck'
}

export enum SurpriseType {
  free_text = 'free_text',
  points = 'points',
}

export class WheelOfLuck extends PosExperience {
}
