import {MobileMessage} from './mobile_message';
import {BaseSerializer} from '../base/base.serializer';
import {MessageSerializer} from '../messages/message.serializer';
import {UserSerializer} from '../users/user.serializer';
import {CampaignSerializer} from '../campaigns/campaign.serializer';
import {DealAutomationSerializer} from '../deal_automations/deal_automation.serializer';
import {DealSerializer} from '../deals/deal.serializer';
import {OfferSerializer} from '../offers/offer.serializer';

export class MobileMessageSerializer extends BaseSerializer<MobileMessage> {
  fromJson(json: any): MobileMessage {
    const serialized = new MobileMessage(json);
    if (json.message) {
      serialized.message = new MessageSerializer().fromJson(json.message);
    }
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    if (json.campaign) {
      serialized.campaign = new CampaignSerializer().fromJson(json.campaign);
    }
    if (json.deal_automation) {
      serialized.deal_automation = new DealAutomationSerializer().fromJson(json.deal_automation);
    }
    if (json.deal) {
      serialized.deal = new DealSerializer().fromJson(json.deal);
    }
    if (json.offer) {
      serialized.offer = new OfferSerializer().fromJson(json.offer);
    }
    return serialized;
  }

  toJson(obj: MobileMessage): string {
    let params: any = {mobile_message: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
