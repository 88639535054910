<form (submit)="onChange()">

  <ng-template #headerTemplate let-config="config" let-item="item">

  </ng-template>

  <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand">
    <div class="form-inline row-item">
      <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa" [class.fa-caret-right]="item.collapsed"
         [class.fa-caret-down]="!item.collapsed"></i>
      <a class="form-check-label" (click)="select($event, item)">{{ item.text }}</a>
    </div>
  </ng-template>

  <ngx-dropdown-treeview #treeviewComponent [config]="config" [headerTemplate]="headerTemplate" [items]="items" [itemTemplate]="itemTemplate">
  </ngx-dropdown-treeview>

  <select class="form-control predicate" [(ngModel)]="filter.predicate" name="filter.predicate" *ngIf="filter.subject">
    <option [ngValue]="predicate.EQUAL">ist gleich</option>

    <option [ngValue]="predicate.NOT_EQUAL">ist nicht gleich</option>

    <option [ngValue]="predicate.BEGINS_WITH"
            *ngIf="(getColumnType(filter.subject) === columnType.String
              || getColumnType(filter.subject) === columnType.Url) &&
              (getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted)">fängt an mit</option>

    <option [ngValue]="predicate.NOT_BEGINS_WITH"
            *ngIf="(getColumnType(filter.subject) === columnType.String
              || getColumnType(filter.subject) === columnType.Url) &&
              (getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted)">fängt nicht an mit</option>

    <option [ngValue]="predicate.CONTAINS"
            *ngIf="(getColumnType(filter.subject) === columnType.String
              || getColumnType(filter.subject) === columnType.Url) &&
              (getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted)">beinhaltet</option>

    <option [ngValue]="predicate.NOT_CONTAINS"
            *ngIf="(getColumnType(filter.subject) === columnType.String
              || getColumnType(filter.subject) === columnType.Url) &&
              (getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted)">beinhaltet nicht</option>

    <option [ngValue]="predicate.ENDS_WITH"
            *ngIf="(getColumnType(filter.subject) === columnType.String
              || getColumnType(filter.subject) === columnType.Url) &&
              (getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted)">endet mit</option>

    <option [ngValue]="predicate.NOT_ENDS_WITH"
            *ngIf="(getColumnType(filter.subject) === columnType.String
              || getColumnType(filter.subject) === columnType.Url) &&
              (getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted)">endet nicht mit</option>

    <option [ngValue]="predicate.SMALLER_THAN"
            *ngIf="getColumnType(filter.subject) === columnType.Number || getColumnType(filter.subject) === columnType.DateTime || getColumnType(filter.subject) === columnType.Date">kleiner als</option>

    <option [ngValue]="predicate.GREATER_THAN"
            *ngIf="getColumnType(filter.subject) === columnType.Number || getColumnType(filter.subject) === columnType.DateTime || getColumnType(filter.subject) === columnType.Date">größer als</option>

    <option [ngValue]="predicate.EQUAL_OR_SMALLER_THAN"
            *ngIf="getColumnType(filter.subject) === columnType.Number || getColumnType(filter.subject) === columnType.DateTime || getColumnType(filter.subject) === columnType.Date">gleich oder kleiner als</option>

    <option [ngValue]="predicate.EQUAL_OR_GREATER_THAN"
            *ngIf="getColumnType(filter.subject) === columnType.Number || getColumnType(filter.subject) === columnType.DateTime || getColumnType(filter.subject) === columnType.Date">gleich oder größer als</option>

    <option [ngValue]="predicate.EMPTY"
            *ngIf="getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted">ist leer</option>

    <option [ngValue]="predicate.NOT_EMPTY"
            *ngIf="getColumnType(filter.subject) !== columnType.Boolean && getColumnType(filter.subject) !== columnType.Boolean_Inverted">ist nicht leer</option>
  </select>

  <div *ngIf="filter.predicate !== predicate.EMPTY
                && filter.predicate !== predicate.NOT_EMPTY
                && filter.predicate !== undefined
                && filter.subject !== undefined">

    <ng-container *ngIf="!hasOptions(filter.subject)"> <!-- Simplify, better than adding that ngIf check to every input !-->
      <input *ngIf="getColumnType(filter.subject) === columnType.String || getColumnType(filter.subject) === columnType.Url"
             type="text" class="form-control" name="filter.object1" [(ngModel)]="filter.object1">

      <input *ngIf="getColumnType(filter.subject) === columnType.Number"
             type="number" class="form-control" name="filter.object1" [(ngModel)]="filter.object1">

      <input *ngIf="getColumnType(filter.subject) === columnType.Date || getColumnType(filter.subject) === columnType.DateTime"
             type="text" class="form-control" name="filter.object1" [(ngModel)]="filter.object1" placeholder="25.09.2018 oder 20.days.ago">

      <select *ngIf="getColumnType(filter.subject) === columnType.Boolean || getColumnType(filter.subject) === columnType.Boolean_Inverted"
              class="form-control" [(ngModel)]="filter.object1" name="filter.object1">
        <option [ngValue]="'true'">wahr</option>
        <option [ngValue]="'false'">falsch</option>
      </select>
    </ng-container>

    <select *ngIf="hasOptions(filter.subject)" class="form-control" name="filter.object1" [(ngModel)]="filter.object1">
      <option *ngFor="let option of options" [ngValue]="option">{{getOptions(filter.subject)[option]}}</option>
    </select>

  </div>

</form>
<button class="btn d-inline delete-btn" (click)="onDeleteFilter()"><i class="fa fa-trash-alt fa-fw"></i></button>
