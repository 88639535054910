import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalizedCardOrderService } from '../personalized-card-order.service';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PersonalizedCardOrder } from '../personalized-card-order';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-personalized-card-order-show',
  templateUrl: './personalized-card-order-show.component.html',
  styleUrls: ['./personalized-card-order-show.component.scss']
})
export class PersonalizedCardOrderShowComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  personalCardOrder: PersonalizedCardOrder = null;
  printTemplateUrl: SafeUrl;
  attachmentOneUrl: SafeUrl;
  attachmentTwoUrl: SafeUrl;

  constructor(private route: ActivatedRoute,
              private personalCardOrderService: PersonalizedCardOrderService,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe),
      map(params => parseInt(params.id, 10)),
      switchMap(id => this.personalCardOrderService.fetch(id)),
      tap(order => this.personalCardOrder = order),
      tap(order => this.printTemplateUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(order.print_template.url)),
      tap(order => this.attachmentOneUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(order.attachment_one.url)),
      tap(order => this.attachmentTwoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(order.attachment_two.url))
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
