import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { PersonalizedCardOrderSerializer } from './personalized-card-order.serializer';
import { PersonalizedCardOrder, PersonalizedCardOrderList } from './personalized-card-order';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalizedCardOrderService extends BaseService<PersonalizedCardOrder, PersonalizedCardOrderList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'personalized_card_orders';
    this.serializer = new PersonalizedCardOrderSerializer();
  }

  create(personalizedCardOrder: PersonalizedCardOrder): Observable<PersonalizedCardOrder> {
    const formData = new FormData();
    const fields = {
      print_template: personalizedCardOrder.print_template.file,
      attachment_one: personalizedCardOrder.attachment_one.file,
      attachment_two: personalizedCardOrder.attachment_two.file,
      client_id: personalizedCardOrder.client_id,
      quantity: personalizedCardOrder.quantity,
      print_finish: personalizedCardOrder.print_finish,
      company_name: personalizedCardOrder.company_name,
      contact_person: personalizedCardOrder.contact_person,
      address: personalizedCardOrder.address,
      zip: personalizedCardOrder.zip,
      city: personalizedCardOrder.city,
      country: personalizedCardOrder.country,
      text: personalizedCardOrder.text
    };

    Object.keys(fields).forEach(key => {
      if (fields[key] != null) {
        formData.append(`personalized_card_order[${key}]`, fields[key]);
      }
    });

    return this.http.post(`${environment.base_url + this.endpoint}`, formData).pipe(
      map((res: any) => this.serializer.fromJson(res))
    );
  }
}
