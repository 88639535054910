import {Injectable} from '@angular/core';
import {Column} from '../../shared/models/column';
import {NgForage} from 'ngforage';
import {ActivatedRoute} from '@angular/router';
import {ListService} from '../list.service';
import {Sort} from '../../shared/models/sort';
import {Group, QuickFilter} from '../../filter/group';
import {FilterService} from '../../filter/filter.service';
import {TreeviewItem} from 'ngx-treeview';
import * as pluralize from 'pluralize';
import {ColumnType} from '../../shared/enums/column-type.enum';
import { Invoice } from '../../invoices/invoice';


@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private ngForage: NgForage,
              private route: ActivatedRoute,
              private listService: ListService,
              private filterService: FilterService) {
  }

  urlToPage(params): number {
    return this.listService.getParam(params, 'page',
      (pageNum) => +pageNum - 1,
      () => 0);
  }

  urlToSort(params): Sort[] {
    let sorting: Sort[] = [];
    this.listService.getParam(params, 'sort',
      (res: string) => {
        const result = res.split(',');
        for (const object of result) {
          const obj = object.substr(0, object.length - 1).substr(1).split(':');
          sorting.push({name: obj[0], dir: obj[1]});
        }
      },
      () => sorting = [new Sort]);
    return sorting;
  }

  fieldsToFetch(columnString: string): string {
    const splitted = columnString.split(/,/g);
    splitted.forEach(val => {
      if (val.includes('.')) {
          const assoc = val.substr(0, val.lastIndexOf('.'));
          if (!splitted.includes(assoc + '.id')) {
            splitted.push(assoc + '.id');
          }
      }
      if (val.endsWith('_data')) {
        const polyAssoc = val.substr(0, val.lastIndexOf('_data'));
        if (!splitted.includes(polyAssoc + '_id')) {
          splitted.push(polyAssoc + '_id');
        }
        if (!splitted.includes(polyAssoc + '_type')) {
          splitted.push(polyAssoc + '_type');
        }
      }
    });
    return splitted.join(',');
  }

  urlToSelectionsOnly(params): boolean {
    let result = false;
    this.listService.getParam(params, 's',
      (res) => result = (+res === 1));
    return result;
  }

  urlToSelections(params, data) {
    let selections = params.selections;
    if (selections) {
      selections = selections.split(',').map(x => +x);
      return data.filter((x) => selections.includes(x.id));
    }
    return [];
  }

  urlToFilter(params, columns: Column[]): Group[] {
    let group: Group[] = [];
    this.listService.getParam(params, 'q',
      (res) => group = this.filterService.urlToFilter(res, columns));
    return group;
  }

  manageQuickFilter(params, quickFilter: QuickFilter[]): QuickFilter[] {
    if (!quickFilter || quickFilter.length === 0) {
      quickFilter = [];
    }
    quickFilter.forEach(qf => {
      qf.active = params['q'] && params['q'].length > 0 && params['q'].includes(qf.groupRison);
    });
    return quickFilter;
  }

  columnsToUrl(properties, columnsString: string) {
    const cCArray = columnsString.split(',');
    let diff = properties.filter(x => !cCArray.includes(x));
    if (diff && diff.length > 0) {
      cCArray.push(diff);
    } else {
      diff = cCArray.filter(x => !properties.includes(x));
      if (diff && diff.length > 0) {
        const ind = cCArray.findIndex((v) => v === diff[0]);
        cCArray.splice(ind, 1);
      }
    }
    this.listService.toURL('', {cols: cCArray.join(',')});
  }

  urlToColumns(params, allcolumns: Column[]): Column[] {
    let columns: Column[] = [];
    this.listService.getParam(params, 'cols',
      (res) => columns = this.stringToColumns(res, allcolumns),
      () => {
        const columns_string = this.getStandardColumns(allcolumns);
        columns = this.stringToColumns(columns_string, allcolumns);
      });
    return columns;
  }

  urlToColumnString(params, allcolumns: Column[]) {
    let result = '';
    this.listService.getParam(params, 'cols',
      (res) => {
        result = res;
      },
      () => {
        result = this.getStandardColumns(allcolumns);
      });
    return result;
  }

  stringToColumns(columnsString: string, allcolumns: Column[]): Column[] {
    const columns: Column[] = [];
    const props = columnsString.split(',');
    for (const prop of props) {
      if (prop.includes('.')) {
        const col = Column.findColumn(prop, allcolumns);
        const readable = prop.split(/\./g).map(x =>
            x.charAt(0).toUpperCase() + x.slice(1)).join(' '); // replacing points with spaces and makes first letters uppercase
        const splitted_prop = prop.split(/\./g);
        const target = splitted_prop[splitted_prop.length - 2];
        const assocCol = new Column({ // Build new column to keep full property instead of column property
          name: (col.type === ColumnType.Image) ? '' : readable, // @TODO: beautify
          prop: prop,
          flexGrow: col.flexGrow,
          editable: col.editable,
          type: col.type,
          tooltip: col.tooltip,
          plural: pluralize(target)
        });
        columns.push(assocCol);
      } else {
        columns.push(Column.findColumn(prop, allcolumns));
      }
    }
    return columns;
  }

  urlToColumnsTree(params, columns: Column[]): TreeviewItem[] {
    let result: TreeviewItem[] = [];
    this.listService.getParam(params, 'cols',
      (res) => result = this.stringToColumnsTree(res, columns),
      () => {
        const columns_string = this.getStandardColumns(columns);
        result = this.stringToColumnsTree(columns_string, columns);
      });
    return result;
  }

  getStandardColumns(columns: Column[]) {
    let res;
    res = columns.filter(e => e.standard === true).sort((x, y) => x.order - y.order)
      .map(l => (l.standardProps) ?
        l.standardProps.split(',').map(s => l.prop + '.' + s).join(',') :
        l.prop).join(',');
    if (res.length < 2) {
      res = columns.slice(0, 6).map(x => x.prop).join(',');
    }
    return res;
  }

  stringToColumnsTree(columnsString: string, columns: Column[]): TreeviewItem[] {
    return Column.asTree(columns, columnsString)[0].children;
  }

  reorderColumns(columnString: string, prevValue: number, newValue: number): void {
    const reOrder = columnString.split(',');
    const safeCopy = reOrder[prevValue - 1];
    reOrder.splice(prevValue - 1, 1);
    reOrder.splice(newValue, 0, safeCopy);
    this.listService.toURL('', {cols: reOrder.join(',')});
  }
}
