<button class="btn btn-block btn-sm btn-secondary text-left mt-2" (click)="resetParams()">
  <i class="fa fa-undo"></i> Zurücksetzen
</button>

<app-list-controls [all]="allColumns" [group]="group"></app-list-controls>

<app-selection-controls [columns]="columns"
                        [model]="model" [data]="data"
                        (dataChange)="onDataChange($event)"
                        [selected]="selected"
                        (selectedChange)="onSelectedChange($event)"></app-selection-controls>

<ng-container *ngIf="quickFilter && quickFilter.length > 0">
  <h6 class="sidebar-heading ml-4 mt-3 mb-1 text-muted">
    <span>Quick Filter</span>
  </h6>

  <div class="ml-1 form-check" *ngFor="let qf of quickFilter">
    <input id="check{{qf.name}}" class="form-check-input" type="checkbox"
           [checked]="qf.active"
           (change)="onChangeQuickFilter(qf, $event.currentTarget.checked)">

    <label class="form-check-label" for="check{{qf.name}}">{{qf.name}}</label>
  </div>
</ng-container>
