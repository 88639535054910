import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Device } from '../devices/device';
import { User } from '../users/user';
import { MobileActivity } from '../mobile_activities/mobile_activity';

export class MobileSession {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, order: 0, flexGrow: .2}),
    new Column({name: 'started_at', prop: 'started_at', type: ColumnType.DateTime}),
    new Column({name: 'ended_at', prop: 'ended_at', type: ColumnType.DateTime}),
    new Column({name: 'ip', prop: 'ip', type: ColumnType.String}),
    new Column({name: 'app_version', prop: 'app_version', type: ColumnType.String, standard: true, order: 3, flexGrow: .2}),
    new Column({name: 'origin', prop: 'origin', type: ColumnType.String}),
    new Column({name: 'deep_link', prop: 'deep_link', type: ColumnType.String}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Gerät', prop: 'device', association: 'belongs_to', columns: Device.allColumns, standard: true, order: 2,
    standardProps: 'model'}),
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns, standard: true, order: 1,
      standardProps: 'email'}),
  ];

  // Simple Columns
  id: number;
  started_at: boolean;
  ended_at: boolean;
  device_id: number;
  user_id: number;
  ip: string;
  app_version: string;
  origin: string;
  deep_link: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  // Associations
  device: Device;
  user: User;
  mobile_activity: MobileActivity[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.started_at;
  }
}

export class MobileSessionList {
  count: number;
  data: MobileSession[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
