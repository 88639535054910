import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column } from '../../../shared/models/column';
import { Group, QuickFilter } from '../../../filter/group';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from '../../list.service';
import { tap } from 'rxjs/operators';
import { UrlService } from '../../url/url.service';

@Component({
  selector: 'app-table-controls',
  templateUrl: './table-controls.component.html',
  styleUrls: ['./table-controls.component.scss']
})
export class TableControlsComponent implements OnInit {

  @Input()
  model: string;

  @Input()
  columns: Column[] = [];

  @Input()
  allColumns: Column[] = [];

  @Input()
  quickFilter: QuickFilter[] = [];

  @Input()
  selected: Column[] = [];

  @Output()
  selectedChange = new EventEmitter();

  @Input()
  data: any[] = [];

  @Output()
  dataChange = new EventEmitter();

  group: Group[] = [];

  constructor(private router: Router, private listService: ListService, private urlService: UrlService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(
      tap((params) => {
        this.group = this.urlService.urlToFilter(params, this.allColumns);
      })
    );
  }

  resetParams() {
    this.router.navigate([], {
      queryParams: {page: 1}
    }).catch((err) => console.log('Error resetting params: ', err));
  }

  onChangeQuickFilter(qf: QuickFilter, event) {
    if (event) {
      this.group.push(qf.group);
    } else {
      const find = this.group.findIndex(x => x === qf.group);
      this.group.splice(find, 1);
    }
    this.listService.onFilter(this.group);
  }

  onSelectedChange($event) {
    this.selected = $event;
    this.selectedChange.emit(this.selected);
  }

  onDataChange($event) {
    this.data = $event;
    this.dataChange.emit(this.data);
  }
}
