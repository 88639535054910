import {DealAutomation} from './deal_automation';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';
import {DealSerializer} from '../deals/deal.serializer';
import {MessageStatSerializer} from '../message_stats/message_stat.serializer';

export class DealAutomationSerializer extends BaseSerializer<DealAutomation> {
  fromJson(json: any): DealAutomation {
    const serialized = new DealAutomation(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    if (json.deal) {
      serialized.deal = new DealSerializer().fromJson(json.deal);
    }
    if (json.message_stat) {
      serialized.message_stat = new MessageStatSerializer().fromJson(json.message_stat);
    }
    return serialized;
  }

  toJson(obj: DealAutomation): string {
    let params: any = {deal_automation: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
