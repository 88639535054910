import { Column } from '../shared/models/column';
import { ColumnType } from '../shared/enums/column-type.enum';
import { Client } from '../clients/client';
import { DunningLevel, Invoice } from '../invoices/invoice';
import { DunningProcessEvent } from './dunning-process-events';

export enum ProcessDunningLevel {
  open = 'Keine',
  reminder = 'Erinnerung',
  first_dunning = 'Erste',
  second_dunning = 'Zweite',
  collection = 'Letzte Mahnung',
  ended = 'Abgeschlossen'
}

export enum DunningLevels {
  open = '0',
  reminder = '1',
  first_dunning = '2',
  second_dunning = '3',
  collection = '4',
  ended = '5'
}

export const DunningLevelMapping = ['Keine', 'Erinnerung', 'Erste', 'Zweite', 'Letzte Mahnung', 'Abgeschlossen'];


export class DunningProcess {
  static readonly OVERDUE_IN_DAYS = 3;

  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true, flexGrow: 0.3}),
    new Column({name: 'Kunde', prop: 'client.name', standard: true, flexGrow: 2}),
    new Column({
      name: 'Prozessstufe',
      prop: 'process_level',
      type: ColumnType.Number,
      standard: true,
      flexGrow: 1,
      options: DunningLevelMapping
    }),
    new Column({
      name: 'Höchste Mahnstufe',
      prop: 'highest_level',
      type: ColumnType.String,
      standard: false,
      flexGrow: 1,
      options: DunningLevel
    }),
    new Column({name: 'Rechnungen', prop: 'invoices_count', type: ColumnType.Number, standard: false, flexGrow: 1}),
    new Column({name: 'E-Mail', prop: 'email', type: ColumnType.String, standard: false, flexGrow: 1}),
    new Column({name: 'Gestartet am', prop: 'created_at', type: ColumnType.DateTime, standard: false, flexGrow: 2}),
    new Column({name: 'Letzte Aktivität', prop: 'updated_at', type: ColumnType.DateTime, standard: true, flexGrow: 1}),
    new Column({name: 'Beendet am', prop: 'ended_at', type: ColumnType.DateTime, standard: false, flexGrow: 2}),
    new Column({name: 'Fällig am', prop: 'due_at', type: ColumnType.DateTime, standard: true, flexGrow: 2}),
  ];

  // Simple Columns
  id: number;
  client_id: number;
  started_at: Date;
  ended_at: Date;
  last_activity_at: Date;
  process_level: ProcessDunningLevel;
  email: string;
  resolved_email: string;
  due_at: Date;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  client: Client;

  dunning_process_events: DunningProcessEvent[];
  invoices: Invoice[]; // { id: number, state: string, netto_sum_cents: number }[];

  constructor(values: Partial<DunningProcess> = {}) {
    Object.assign(this, values);
    this.client = new Client(values.client);
    if (values.due_at) {
      this.due_at = new Date(values.due_at);
    }
  }

  humanize() {
    return this.client_id;
  }

  unpaidSum(): number {
    let sum = 0.0;
    for (let invoice of this.invoices) {
      if (invoice.state != 'paid') {
        sum += invoice.netto_sum_cents / 100.0;
      }
    }
    return sum;
  }

  public isDue(): boolean {
    if (!this.due_at) {
      return true;
    }

    return new Date() > this.due_at;
  }

  public isOverdue(): boolean {
    if (!this.due_at) {
      return false;
    }

    let overDueDate = new Date(this.due_at.setHours(23, 59, 59));
    overDueDate = new Date(overDueDate.setDate(overDueDate.getDate() + DunningProcess.OVERDUE_IN_DAYS));

    return new Date() > overDueDate;
  }

  public setDueIn(days: number): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.due_at = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  }
}

export class DunningProcessList {
  count: number;
  data: DunningProcess[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
