import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { interval } from 'rxjs';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ReleaseMonitorService {

  private timer$ = interval(60 * 1000);
  private updated$ = new ReplaySubject<void>();

  constructor(private httpClient: HttpClient, private toastrService: ToastrService) {
    this.timer$.pipe(
      takeUntil(this.updated$),
      switchMap(_ => this.checkVersion()),
      filter(release => release.version !== environment.release),
      tap(release => console.log('App has been updated', environment.release, '->', release.version)),
      tap(_ => {
        this.toastrService.info(
          'Neue App Version verfügbar! Lade die Seite neu, um die neueste Version zu erhalten.',
          'Update verfügbar',
          {timeOut: 0}
        );
      }),
      tap(_ => this.updated$.next())
    ).subscribe();
  }

  private checkVersion(): Observable<{ version: string }> {
    return this.httpClient.get<{ version: string, notify: string }>('/latest-version.json');
  }
}
