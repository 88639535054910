import {Contract} from './contract';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';

export class ContractSerializer extends BaseSerializer<Contract> {
  fromJson(json: any): Contract {
    const serialized = new Contract(json);
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    return serialized;
  }

  toJson(obj: Contract): string {
    let params: any = {contract: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
