import {Categorization} from './categorization';
import {BaseSerializer} from '../base/base.serializer';
import {CategorySerializer} from '../categories/category.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class CategorizationSerializer extends BaseSerializer<Categorization> {
  fromJson(json: any): Categorization {
    const serialized = new Categorization(json);
    if (json.category) {
      serialized.category = new CategorySerializer().fromJson(json.category);
    }
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: Categorization): string {
    let params: any = {categorization: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
