export class Search {

  static searchProps = {
    businesses: ['name', 'partner.name'],
    invoices: ['file_name', 'client_company'],
    dunning_processes: ['process_level', 'email', 'client.name'],
    clients: ['name', 'company', 'contact', 'phone'],
    users: ['name', 'email'],
    activities: ['id', 'action'],
    campaigns: ['subject_line', 'title', 'subtitle'],
    cards: ['token.token', 'id'],
    categories: ['name', 'short_name_en', 'short_name_de'],
    client_documents: ['file'],
    contracts: ['company'],
    deals: ['title', 'description', 'terms'],
    coupons: ['code'],
    merchants: ['username'],
    mobile_sessisons: ['user.name', 'user.email'],
    pos_sessions: ['user.name', 'user.email', 'business.name', 'business.partner.name'],
    rewards: ['title', 'descriptions'],
    sepa_mandates: ['mandate_reference', 'bankaccount_owner', 'signee', 'contract.company'],
    tablets: ['name', 'serial_number'],
    teasers: ['title', 'description', 'terms'],
    terminals: ['business.name', 'business.partner.name'],
    token: ['token'],
    transactions: ['business.name', 'business.partner.name']
  }

  static getSearchProps(endpoint: string): Array<string> {
    return Search.searchProps[endpoint] ? Search.searchProps[endpoint] : ['name'];
  }
}
