import { Injectable } from '@angular/core';
import {MobileActivity, MobileActivityList} from './mobile_activity';
import {BaseService} from '../base/base.service';
import {MobileActivitySerializer} from 'src/app/mobile_activities/mobile_activity.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MobileActivityService extends BaseService<MobileActivity, MobileActivityList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'mobile_activities' ;
    this.serializer = new MobileActivitySerializer();
  }
}
