import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ListService } from '../../../base/list.service';
import { BaseService } from '../../../base/base.service';
import { Observable, Subject } from 'rxjs';
import { Predicate } from '../../enums/predicate.enum';
import { BootstrapFormControlDirective } from '../../directives/bootstrap-form-control.directive';
import { Group } from '../../../filter/group';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-typeahead-select',
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeaheadSelectComponent),
      multi: true
    }
  ]
})
export class TypeaheadSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  @Input()
  model: string;

  @Input()
  labelProperty = 'name';

  @Input()
  typeaheadProperties = ['name'];

  @Input()
  scopeQuery: Group[] = [];

  @Input()
  displaySelectedAs: 'name' | 'icon' = 'name';

  @Input()
  placeholder: string = null;

  private modelService: BaseService<any, any>;

  value: any;
  label: string;
  searchTerm: string;
  onChange: (string) => void | null;
  onTouched: () => void | null;
  isDisabled = false;

  private ngControl: NgControl | null = null;

  bootstrapValidationClass = '';

  constructor(private listService: ListService, private inj: Injector, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.modelService = this.listService.getServiceEndpoint(this.model);
  }

  ngAfterViewInit() {
    this.ngControl = this.inj.get(NgControl);
    if (!this.ngControl) {
      return;
    }

    this.bootstrapValidationClass = BootstrapFormControlDirective.getBootstrapFormClass(this.ngControl);
    this.cdr.detectChanges();
    this.ngControl.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(_ => {
      this.bootstrapValidationClass = BootstrapFormControlDirective.getBootstrapFormClass(this.ngControl);
    });
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.value) {
      return;
    }
    this.modelService.fetch(this.value).subscribe(model => {
      this.label = model[this.labelProperty];
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  updateSearchTerm(newSearchTerm: any): void {
    this.searchTerm = newSearchTerm;
    this.notifyOnTouched();
  }

  createTypeaheadObservable(): Observable<{ id: number, name: string }> {
    return new Observable((observer: any) => {
      const properties = [...['id', this.labelProperty], ...this.typeaheadProperties].join(',');
      const simpleQ = this.modelService.simpleQ(this.labelProperty, this.searchTerm || '', Predicate.CONTAINS).concat(this.scopeQuery);
      this.modelService.fetchAll(properties, simpleQ).subscribe(res => observer.next(res.data));
    });
  }

  onSelect($event: any) {
    this.label = $event.item.name;
    this.updateSearchTerm(null);
    this.notifyOnTouched();
    this.notifyOnChange($event.item.id);
    this.writeValue($event.item.id);
  }

  onReset() {
    this.value = null;
    this.notifyOnChange(null);
  }

  public createTypeaheadLine(model: any) {
    return this.typeaheadProperties.map(property => model[property]).join(', ') + ` [${model.id}]`;
  }

  get capitalizedLabelProperty(): string {
    if (!this.labelProperty) {
      return '';
    }
    return this.labelProperty.charAt(0).toUpperCase() + this.labelProperty.slice(1);
  }

  inputPlaceholder(): string {
    if (this.isDisabled) {
      return '';
    }
    if (this.displaySelectedAs === 'name' || this.value === null) {
      return this.placeholder ? this.placeholder : `Gib ein ${this.capitalizedLabelProperty} ein...`;
    } else {
      return this.label;
    }
  }

  private notifyOnChange(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  private notifyOnTouched(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
