import { Injectable } from '@angular/core';
import { Asset, AssetList } from './asset';
import { BaseService } from '../base/base.service';
import { AssetSerializer } from 'src/app/assets/asset.serializer';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends BaseService<Asset, AssetList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'assets';
    this.serializer = new AssetSerializer();
  }

  public upload(partnerId: number, file: File): Observable<Asset> {
    const formData = new FormData();
    formData.append('asset[file]', file);
    formData.append('asset[partner_id]', partnerId.toString());

    return this.http.post<Asset>(`${environment.base_url}${this.endpoint}`, formData).pipe(
      map(res => this.serializer.fromJson(res))
    );
  }
}
