<div class="row mt-2" *ngIf="personalCardOrder">
  <div class="col-6">
    <div class="form-group">
      <label>Status</label>
      <div class="alert" [class.alert-warning]="['created', 'prepared', 'sent'].includes(personalCardOrder.status)"
           [class.alert-success]="personalCardOrder.status === 'delivered'"
           [class.alert-danger]="['delivery_failed', 'token_generation_failed'].includes(personalCardOrder.status)">
        {{ personalCardOrder.humanStatus() }}
      </div>
    </div>

    <div class="form-group">
      <label for="quantity">Anzahl</label>
      <input type="number" min="0" id="quantity" class="form-control" [ngModel]="personalCardOrder.quantity"
             disabled="disabled">
    </div>

    <div class="form-group">
      <label for="print_finish">Druckveredelung</label>
      <input type="text" id="print_finish" class="form-control" [ngModel]="personalCardOrder.print_finish"
             disabled="disabled">
    </div>

    <hr>

    <div class="form-group">
      <label for="client_id">Kunde</label>
      <app-typeahead-select id="client_id"
                            [model]="'clients'"
                            [typeaheadProperties]="['name', 'contact']"
                            [ngModel]="personalCardOrder.client_id"
                            disabled="disabled"></app-typeahead-select>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="company_name">Firmenname</label>
        <input class="form-control" id="company_name" type="text" [ngModel]="personalCardOrder.company_name"
               disabled="disabled">
      </div>
      <div class="col-6">
        <label for="contact_person">Kontaktperson</label>
        <input class="form-control" id="contact_person" type="text" [ngModel]="personalCardOrder.contact_person"
               disabled="disabled">
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <label for="address">Adresse</label>
        <input class="form-control" id="address" type="text" [ngModel]="personalCardOrder.address" disabled="disabled">
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <label for="zip">PLZ</label>
        <input class="form-control" id="zip" type="text" [ngModel]="personalCardOrder.zip" disabled="disabled">
      </div>
      <div class="col-6">
        <label for="city">Stadt</label>
        <input class="form-control" id="city" type="text" [ngModel]="personalCardOrder.city" disabled="disabled">
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <label for="country">Land</label>
        <input class="form-control" id="country" type="text" [ngModel]="personalCardOrder.country" disabled="disabled">
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="form-group">
      <label>Betreff</label>
      <input class="form-control" type="text" [ngModel]="personalCardOrder.emailSubject()" disabled="disabled">
    </div>

    <div class="form-group">
      <label>E-Mail</label>
      <div class="trix-text card card-body bg-light">
        <div [innerHtml]="personalCardOrder.text"></div>
      </div>
    </div>

    <table class="table table-sm table-hover">
      <thead>
      <tr>
        <th>E-Mail</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let email of personalCardOrder.emails">
        <td>{{ email?.message?.recipient_email }}</td>
        <td>{{ email.status || 'n/a' }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row mt-2" *ngIf="personalCardOrder">
  <div [class.col-12]="!personalCardOrder.attachment_one.url && !personalCardOrder.attachment_two.url"
       [class.col-6]="personalCardOrder.attachment_one.url || personalCardOrder.attachment_two.url">
    <div class="form-group" *ngIf="personalCardOrder.print_template.url">
      <label>Druckvorlage: {{ personalCardOrder.print_template.identifier }}</label>
      <object type="application/pdf" [data]="printTemplateUrl" width="100%" height="100%" style="height: 500px"></object>
    </div>
  </div>

  <div class="col-6" *ngIf="personalCardOrder.attachment_one.url">
    <div class="form-group">
      <label>Anhang 1: {{ personalCardOrder.attachment_one.identifier }}</label>
      <object type="application/pdf" [data]="attachmentOneUrl" width="100%" height="100%" style="height: 500px"></object>
    </div>
  </div>

  <div class="col-6" *ngIf="personalCardOrder.attachment_two.url">
    <div class="form-group">
      <label>Anhang 2: {{ personalCardOrder.attachment_two.identifier }}</label>
      <object type="application/pdf" [data]="attachmentTwoUrl" width="100%" height="100%" style="height: 500px"></object>
    </div>
  </div>
</div>
