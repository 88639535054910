import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Client} from '../clients/client';
import {LineItem} from '../line_items/line_item';
import {ClientDocument} from '../client_documents/client_document';
import {SepaMandate} from '../sepa_mandates/sepa_mandate';
import { AddonSubscription } from '../addon_subscriptions/addon_subscription';
import {DunningProcess} from '../dunning_processes/dunning_process';

export enum InvoiceState {
  other = 'Sonstiges',
  next = 'Nächste',
  sent = 'Versendet',
  paid = 'Bezahlt',
  unpaid = 'Unbezahlt',
  lost = 'Verloren'
}

export enum DunningLevel {
  zero = 'Keine',
  reminder = 'Erinnerung',
  first_dunning = 'Erste',
  second_dunning = 'Zweite',
  collection = 'Inkasso'
}

export enum InvoiceSellers {
  mankido_gbr = 'Mankido',
  social_gorilla = 'Social Gorilla'
}

export class Invoice {

  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Dateiname', prop: 'file_name', type: ColumnType.String}),
    new Column({name: 'Firma', prop: 'client_company', type: ColumnType.String, standard: true, order: 1}),

    new Column({
      name: 'Rechnungsposten', prop: 'line_items', association: 'has_many', columns: [
        new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
        new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true}),
        new Column({name: 'Beschreibung', prop: 'description', type: ColumnType.String, standard: true}),
        new Column({name: 'quantity', prop: 'quantity', type: ColumnType.Number, standard: true}),
        new Column({name: 'Summe', prop: 'price_cents_sum', type: ColumnType.Number, standard: true, flexGrow: .3}),
      ], editable: false
    }),

    new Column({name: 'Kontakt', prop: 'client_contact_person', type: ColumnType.String}),
    new Column({name: 'Adresse', prop: 'client_address', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'client_zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'client_city', type: ColumnType.String}),
    new Column({name: 'Kundennummer', prop: 'client_number', type: ColumnType.String}),
    new Column({
      name: 'Rechnungsnummer', prop: 'invoice_number', type: ColumnType.String, standard: true,
      order: 0, flexGrow: .7
    }),
    new Column({name: 'Rechnungsdatum', prop: 'invoice_date', type: ColumnType.Date}),
    new Column({name: 'Rechnungszeitraum', prop: 'invoice_period_name', type: ColumnType.String}),
    new Column({name: 'Rechnungsstatus', prop: 'state', type: ColumnType.String, options: InvoiceState}),
    new Column({name: 'Mahnstufe', prop: 'dunning_level', type: ColumnType.String, options: DunningLevel}),
    new Column({name: 'Zahlart', prop: 'payment_method', type: ColumnType.String}),
    new Column({name: 'IBAN', prop: 'iban', type: ColumnType.String}),
    new Column({name: 'Fälligkeit', prop: 'due_date', type: ColumnType.Date}),
    new Column({name: 'Mandatsreferenz', prop: 'mandate_reference', type: ColumnType.String}),
    new Column({name: 'Grund', prop: 'partial_description', type: ColumnType.String}),
    new Column({name: 'Anteilig', prop: 'partial_factor', type: ColumnType.Number}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Zeitraum (Start)', prop: 'period_start', type: ColumnType.Date}),
    new Column({name: 'Zeitraum (Ende)', prop: 'period_end', type: ColumnType.Date}),
    new Column({name: 'Status', prop: 'state', type: ColumnType.Number, options: InvoiceState}),
    new Column({name: 'Steuersatz', prop: 'vat_rate', type: ColumnType.Number}),
    new Column({name: 'Anbieter', prop: 'seller', type: ColumnType.String, options: InvoiceSellers}),
    new Column({name: 'Nettobetrag', prop: 'netto_sum_cents', type: ColumnType.Number, standard: true, order: 2, flexGrow: .4}),
    // Associated Columns
    new Column({name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns}),
    new Column({name: 'Dokument', prop: 'client_document', association: 'has_one', columns: ClientDocument.allColumns}),
  ];

  static mankidoPackage = {
    'Starter (79€/Monat)': {
      name: 'Starter',
      desc: `Digitales Bonussystem
POS Terminal
Kundenauswertungen`,
      price_cents: 7900
    },
    'Basic (99€/Monat)': {
      name: 'Basic',
      desc: `Digitales Bonussystem
POS Terminal (inkl. Apple iPad)
Kundenauswertungen
Erstellen und Versenden von Kampagnen
Installation & Schulung
Unlimitierte Anzahl an Bonuskarten, kostenloser Austausch des Terminals bei Beschädigung, Softwareupdates`,
      price_cents: 9900
    },
    'Premium (129€/Monat)': {
      name: 'Premium',
      desc: `Digitales Bonussystem
POS Terminal (incl. Apple iPad)
Kundenkarten (unlimitiert)
Kundenauswertungen
Manuelle und automatisierte Kampagnen
Auswertungen für Einzelfilialen`,
      price_cents: 12900
    }
  };


  static testTemplates = {
    '1 Monat kostenlos & unverbindlich': {
      name: 'Testphase',
      desc: 'Kostenlose und unverbindliche Testphase',
      price_cents: 0
    },
    '1 Monat (49€)': {
      name: 'Testphase',
      desc: 'Testphase zum reduzierten Preis',
      price_cents: 4900
    }
  };

  // Simple Columns
  id: number;
  file_name: string;
  title: string;
  client_company: string;
  client_contact_person: string;
  client_address: string;
  client_zip: string;
  client_city: string;
  client_number: string;
  email: string;
  invoice_number: string;
  invoice_date: string;
  invoice_period_name: string;
  payment_method: string;
  iban: string;
  due_date: string;
  mandate_reference: string;
  netto_sum_cents: number;
  partial_description: string;
  partial_factor: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  client_id: number;
  period_start: string;
  period_end: string;
  state: InvoiceState | string;
  dunning_level: string;
  vat_rate: number;
  // Associations
  client: Client;
  seller: 'mankido_gbr' | 'social_gorilla';
  line_items: LineItem[];
  mandate: SepaMandate;
  client_document: ClientDocument;
  addon_subscriptions: AddonSubscription[];
  dunning_process: DunningProcess;
  reason: string;

  get humanSeller() {
    return this.seller === 'mankido_gbr' ? 'Mankido GbR' : 'Social Gorilla';
  }

  set start(date: any) {
    this.period_start = date.getFullYear() ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : date;
  }

  set end(date: any) {
    this.period_end = date.getFullYear() ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : date;
  }

  set due(date: any) {
    this.due_date = date.getFullYear() ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : date;
  }

  set idate(date: any) {
    this.invoice_date = date.getFullYear() ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : date;
  }

  constructor(values: Object = {}) {
    Object.assign(this, values, {
      line_items: (values['line_items'] !== undefined) ?
        values['line_items'].map(x => new LineItem(x)) : []
    });
  }

  humanize() {
    return this.file_name;
  }

  get valid_line_items(): LineItem[] {
    return this.line_items.filter(x => x._destroy !== true);
  }

  get fullSum(): number {
    let s = 0;
    this.valid_line_items.forEach(i => s += i.sum);
    return s;
  }

  get sum(): number {
    return this.fullSum * this.partial_factor;
  }

  get discounted_sum(): number {
    if (this.valid_line_items.length === 0) {
      return 0;
    }
    return this.valid_line_items.map(i => i.discounted_sum).reduce((sum, i) => sum + i) * this.partial_factor;
  }

  deleteItem(item: LineItem) {
    item._destroy = true;
    this.line_items
      .filter(x => x.position > item.position)
      .forEach(x => x.position = x.position - 1);
  }

  addItem() {
    this.line_items.push(new LineItem({
      position: this.line_items.length + 1,
      recurring_rule: 'monthly',
      recurring_interval: 1,
      quantity: 1
    }));
  }

  moveItemUp(item: LineItem) {
    if (item.position !== 1) {
      const higherItem = this.line_items.find(x => x.position === item.position - 1);
      // swap
      higherItem.position = item.position;
      item.position = item.position - 1;
      this.line_items.sort((a, b) => {
        return a.position - b.position;
      });
    }
  }

  moveItemDown(item: LineItem) {
    if (item.position !== this.line_items.length) {
      const lowerItem = this.line_items.find(x => x.position === item.position + 1);
      // swap
      lowerItem.position = item.position;
      item.position = item.position + 1;
      this.line_items.sort((a, b) => {
        return a.position - b.position;
      });
    }
  }
}

export class InvoiceList {
  count: number;
  data: Invoice[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
