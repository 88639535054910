import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Campaign } from '../campaigns/campaign';
import { Business } from '../businesses/business';
// import { Business } from '../supervised_businesses/supervised_business';
import { Terminal } from '../terminals/terminal';
// import { Terminal } from '../created_terminals/created_terminal';
// import { Terminal } from '../deactivated_terminals/deactivated_terminal';

export class Administrator {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true, flexGrow: .8}),
    new Column({name: 'E-Mail', prop: 'email', type: ColumnType.String, standard: true}),
    new Column({name: 'permissions', prop: 'permissions', type: ColumnType.String, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'role', prop: 'role', type: ColumnType.Number}),
    // Associated Columns
  ];

  // Simple Columns
  id: number;
  name: string;
  email: string;
  apesign_api_token: string;
  apesign2_api_token: string;
  pipedrive_api_token: string;
  paperless_api_key: string;
  permissions: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  role: number;
  // Associations
  campaign: Campaign[];
  sold_business: Business[];
  supervised_business: Business[];
  terminal: Terminal[];
  created_terminal: Terminal[];
  deactivated_terminal: Terminal[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.name;
  }
}

export class AdministratorList {
  count: number;
  data: Administrator[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
