import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Membership} from '../memberships/membership';
import {Terminal} from '../terminals/terminal';
import {Business} from '../businesses/business';
import {Token} from '../tokens/token';
import {Reward} from '../rewards/reward';
// import { Reward } from '../../reward_with_deleteds/reward_with_deleted';
import {Coupon} from '../coupons/coupon';
import {TransactionFlag} from '../transaction_flags/transaction_flag';

export class Transaction {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Typ', prop: 'transaction_type', type: ColumnType.Number}),
    new Column({name: 'Betrag', prop: 'amount', type: ColumnType.Currency, standard: true, order: 3, flexGrow: .35}),
    new Column({name: 'Punkte', prop: 'points', type: ColumnType.Number, standard: true, order: 2, flexGrow: .25}),
    new Column({name: 'Abgebrochen am', prop: 'cancelled_at', type: ColumnType.DateTime}),
    new Column({name: 'Abgebrochen von', prop: 'cancelled_by', type: ColumnType.String}),
    new Column({name: 'Grund für Abbruch', prop: 'cancellation_reason', type: ColumnType.String}),
    new Column({name: 'Gelöscht', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 4, flexGrow: .6}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'stamps', prop: 'stamps', type: ColumnType.Number}),
    new Column({name: 'Transaktionsgrund', prop: 'transaction_reason', type: ColumnType.Number}),
    new Column({name: 'Beleg', prop: 'receipt', type: ColumnType.String}),
    // Associated Columns
    new Column({
      name: 'Mitgliedschaft', prop: 'membership', association: 'belongs_to',
      columns: Membership.allColumns, standard: true, standardProps: 'user.email', order: 1
    }),
    new Column({name: 'Terminal', prop: 'terminal', association: 'belongs_to', columns: Terminal.allColumns}),
    new Column({
      name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns,
      standard: true, standardProps: 'partner.name,name', order: 0
    }),
    new Column({name: 'Token', prop: 'token', association: 'belongs_to', columns: Token.allColumns}),
    new Column({name: '(Prämie)', prop: 'reward', association: 'belongs_to', columns: Reward.allColumns}),
    new Column({name: 'Prämie', prop: 'reward_with_deleted', association: 'belongs_to', columns: Reward.allColumns}),
    new Column({name: 'Coupon', prop: 'coupon', association: 'belongs_to', columns: Coupon.allColumns}),
  ];

  // Simple Columns
  id: number;
  transaction_type: number;
  membership_id: number;
  terminal_id: number;
  business_id: number;
  token_id: number;
  amount: number;
  points: number;
  cancelled_at: boolean;
  cancelled_by: string;
  cancellation_reason: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  reward_id: number;
  stamps: number;
  coupon_id: number;
  transaction_reason: number;
  receipt: string;
  // Associations
  membership: Membership;
  terminal: Terminal;
  business: Business;
  token: Token;
  reward: Reward;
  reward_with_deleted: Reward;
  coupon: Coupon;
  transaction_flag: TransactionFlag[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.receipt;
  }
}

export class TransactionList {
  count: number;
  data: Transaction[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
