import {BrowserModule} from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './auth/login/login.component';
import {AuthInterceptor} from './auth/auth.interceptor';
import {AuthGuard} from './auth/auth.guard';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BaseService} from './base/base.service';
import {BusinessService} from './businesses/business.service';
import {ToastrModule} from 'ngx-toastr';
import {PermissionGuard} from './shared/permissions/permission.guard';
import {TreeviewModule} from 'ngx-treeview';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {AssetsModule} from './assets/assets.module';
import {LogoutComponent} from './auth/logout/logout.component';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {deLocale} from 'ngx-bootstrap/locale';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {ActionCableService} from 'angular2-actioncable';
import { PersonalizedCardOrdersModule } from './personalized-card-orders/personalized-card-orders.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

defineLocale('de', deLocale);
registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxDatatableModule,
    AssetsModule,
    TreeviewModule.forRoot(),
    BsDatepickerModule.forRoot(),
    // NgForageModule.forRoot(),
    TypeaheadModule.forRoot(),
    LoadingBarModule,
    ZXingScannerModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      easeTime: 0,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoibWFua2lkbyIsImEiOiJjam11a2V4M3gwcnhmM2txeW9wdGJrNm9yIn0.zRfYWxdDRUNO8dokBua8rA'
    }),
    PersonalizedCardOrdersModule,

    SharedModule
  ],
  providers: [
    BaseService,
    BusinessService,
    ActionCableService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({showDialog: false})
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuard,
    PermissionGuard,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
