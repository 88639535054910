<!--<div class="card-columns">-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/150">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/100">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/120">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/50">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/200">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/130">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/75">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<img class="card-img-top img-fluid" src="https://via.placeholder.com/120">-->
<!--<div class="card-body">-->
<!--<p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
<!--</div>-->
<!--</div>-->

<!--</div>-->

<div class="card-columns">

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/800x560" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/360" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/560" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/200x260" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/400x300" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/160x90" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/230" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/800x560" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/360" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/560" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/200x260" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/400x300" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/160x90" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top img-fluid" src="//placehold.it/230" alt="Card image cap">
    <div class="card-body">
      <p class="card-text">Test Business</p>
    </div>
  </div>
</div>
