import {Shipment} from './shipment';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';
import {BusinessSerializer} from '../businesses/business.serializer';
import {AdministratorSerializer} from '../administrators/administrator.serializer';

export class ShipmentSerializer extends BaseSerializer<Shipment> {
  fromJson(json: any): Shipment {
    const serialized = new Shipment(json);
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    if (json.business) {
      serialized.business = new BusinessSerializer().fromJson(json.business);
    }
    if (json.administrator) {
      serialized.administrator = new AdministratorSerializer().fromJson(json.administrator);
    }
    return serialized;
  }

  toJson(obj: Shipment): string {
    let params: any = {shipment: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
