import { HttpParameterCodec } from '@angular/common/http';

/*
  The HttpParams class in Angular encodes all query parameters using encodeURIComponent,
  but it then decodes specific characters in accordance with RFC3986 (https://www.rfc-editor.org/rfc/rfc3986).
  The list of decoded characters includes @ and +. However, Rails expects full encoding and interprets a + as an empty space.

  According to the release notes of Angular v14.0.0 this issue appears to have been resolved:
  "Queries including + will now actually query for + instead of space. Most workarounds involving custom codecs will be unaffected.
  Possible server-side workarounds will need to be undone."
  - https://github.com/angular/angular/blob/main/CHANGELOG.md#1400-2022-06-02
 */
export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
