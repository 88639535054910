import { Injectable } from '@angular/core';
import {Reward, RewardList} from './reward';
import {BaseService} from '../base/base.service';
import {RewardSerializer} from 'src/app/rewards/reward.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RewardService extends BaseService<Reward, RewardList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'rewards' ;
    this.serializer = new RewardSerializer();
  }
}
