import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Email } from '../emails/email';
import { Message } from '../messages/message';
import { MessageStat } from '../message_stats/message_stat';

export class EmailEvent {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'timestamp', prop: 'timestamp', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Daten', prop: 'data', type: ColumnType.String, standard: true}),
    new Column({name: 'Aktionstyp', prop: 'event_type', type: ColumnType.Number, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'E-Mail', prop: 'email', association: 'belongs_to', columns: Email.allColumns}),
    new Column({name: 'Nachricht', prop: 'message', association: 'has_one', columns: Message.allColumns}),
    new Column({name: 'message_stat', prop: 'message_stat', association: 'has_one', columns: MessageStat.allColumns}),
  ];

  // Simple Columns
  id: number;
  email_id: number;
  timestamp: boolean;
  data: string;
  event_type: number;
  sg_event_id: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  // Associations
  email: Email;
  message: Message;
  message_stat: MessageStat;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.data;
  }
}

export class EmailEventList {
  count: number;
  data: EmailEvent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
