import { Directive, ElementRef, EventEmitter, Host, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Column } from '../../../shared/models/column';
import { Group, QuickFilter } from '../../../filter/group';
import { ActivatedRoute, Params } from '@angular/router';
import { ListService } from '../../list.service';
import { FilterService } from '../../../filter/filter.service';
import { UrlService } from '../../url/url.service';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { BaseService } from '../../base.service';
import { Observable, Subject } from 'rxjs';
import { BaseList } from '../../base.list';
import { Page } from '../../../shared/models/page';
import { Sort } from '../../../shared/models/sort';
import { RansackTableService } from '../services/ransack-table.service';

@Directive({
  selector: 'ngx-datatable[appRansackTable]'
})
export class RansackTableDirective implements OnInit, OnDestroy {

  @Input()
  all: Column[] = [];

  @Input()
  model: string;

  @Input()
  quick?: QuickFilter[];

  @Input()
  q?: string;

  @Input()
  sort?: Sort[] = [new Sort()];

  // properties that should always be included in the queries
  @Input()
  staticProps: string[] = [];

  @Input()
  set data(data: any[]) {
    this.table.rows = data;
  }

  @Output()
  dataChange = new EventEmitter<any[]>();

  @Output()
  columnsChange = new EventEmitter<Column[]>();

  @Output()
  selectedChange = new EventEmitter<Column[]>();

  public refreshed = new EventEmitter<void>(true);

  currentColumnsString = ''; // Currently shown columns as string

  selected = [];
  page: Page = {size: 99999} as Page; // Page Object
  // sort: Sort[] = [new Sort()]; // Sort Array
  group: Group[] = []; // Filter Groups
  columns: Column[] = []; // Current shown columns

  private ngUnsubscribe = new Subject<void>();
  private dataService: BaseService<any, any>;

  constructor(@Host() private table: DatatableComponent,
              private route: ActivatedRoute,
              private domElement: ElementRef,
              private listService: ListService,
              private filterService: FilterService,
              private urlService: UrlService,
              private ransackTableService: RansackTableService){
    this.addCssClasses();

    this.setupNgxDatatableEventListeners();

    this.configureNgxDatatable();

  }

  ngOnInit(): void {
    this.dataService = this.listService.getServiceEndpoint(this.model);

    this.ransackTableService.refreshEvent.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(100),
      mergeMap(_ => this.refreshPage())
    ).subscribe();

    this.subscribeToQueryParams();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onReorder(e) {
    this.urlService.reorderColumns(this.currentColumnsString, e.prevValue, e.newValue);
  }

  onSort(sort): void {
    this.listService.toURL('sort', sort.sorts.map(s => ({[s.prop]: s.dir})), 1);
  }

  onSelect(selected) {
    this.selected = selected;
    this.selectedChange.emit(this.selected);
  }

  private addCssClasses() {
    this.domElement.nativeElement.classList.add('bootstrap');
    this.domElement.nativeElement.classList.add('fullscreen');
  }

  private setupNgxDatatableEventListeners() {
    this.table.reorder.subscribe(e => this.onReorder(e));
    this.table.sort.subscribe(e => this.onSort(e));
    this.table.select.subscribe(e => this.onSelect(e.selected));
  }

  private configureNgxDatatable() {
    this.table.selectAllRowsOnPage = false;
    this.table.selectionType = SelectionType.multiClick;
    this.table.headerHeight = 20;
    this.table.footerHeight = 40;
    this.table.rowHeight = 26;
    // this.table.externalPaging = true;
    // this.table.externalSorting = true;
    this.table.sortType = SortType.multi;
    this.table.columnMode = ColumnMode.flex;
    this.table.loadingIndicator = true;
    this.table.cssClasses = {
      sortAscending: 'fa fa-sort-up',
      sortDescending: 'fa fa-sort-down',
      pagerLeftArrow: 'fa fa-angle-left',
      pagerRightArrow: 'fa fa-angle-right',
      pagerPrevious: 'fa fa-angle-double-left',
      pagerNext: 'fa fa-angle-double-right'
    };
  }

  private subscribeToQueryParams() {
    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(params => {
        if (this.quick && !Object.keys(params).includes('q')) {
          this.setDefaultQuickFilters();
          return false;
        }
        if (this.sort && !Object.keys(params).includes('sort')) {
          this.listService.toURL('sort', this.sort.map(s => ({[s.name]: s.dir})), 1);
          return false;
        }
        return true;
      }),
      tap(params => this.updateOptionsFromParams(params)),
      tap(_ => this.ransackTableService.refresh())
    ).subscribe();
  }

  private setDefaultQuickFilters() {
    const standards: Group[] = this.quick
      .filter(qf => qf.standard)
      .map(qf => qf.group);

    const toURL = this.filterService.filterToUrl(standards);
    return this.listService.toURL('q', toURL, 0);
  }

  private updateOptionsFromParams(params: Params) {
    this.sort = this.urlService.urlToSort(params);
    this.columns = this.urlService.urlToColumns(params, this.all);
    this.columnsChange.next(this.columns);
    this.currentColumnsString = this.urlService.urlToColumnString(params, this.all);
    if (this.q) {
      this.group = this.filterService.urlToFilter(this.q, this.all);
    } else {
      this.group = this.urlService.urlToFilter(params, this.all);
    }
    this.quick = this.urlService.manageQuickFilter(params, this.quick);
    this.page.offset = this.urlService.urlToPage(params);
  }

  private refreshPage(): Observable<any> {
    return this.dataService.fetchPage(this.page, this.group, this.sort, this.getFieldsToFetch())
      .pipe(tap(
        (dataList: any) => {
          this.updateData(dataList);
        },
        (err) => {
          console.error(`ERROR in BaseListComponent:`, err);
        }
      ));
  }

  private getFieldsToFetch(): string {
    const queryProps = this.urlService.fieldsToFetch(this.currentColumnsString);
    return this.staticProps.concat(queryProps).join(',');
  }

  private updateData(dataList: BaseList<any>) {
    this.table.rows = dataList.data;
    this.selected = this.listService.getSelections(this.selected, this.table.rows);
    this.table.selected = this.selected;
    this.page.totalElements = dataList.count;

    this.dataChange.emit(dataList.data);
    this.selectedChange.emit(this.table.selected);

    this.table.count = this.page.totalElements;
    this.table.offset = this.page.offset;
    this.table.limit = this.page.size;

    this.refreshed.next();
  }

}
