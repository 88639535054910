import { Injectable } from '@angular/core';
import {Shipment, ShipmentList, ShipmentTypes, ShipmentPresets} from './shipment';
import {BaseService} from '../base/base.service';
import {ShipmentSerializer} from 'src/app/shipments/shipment.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {Group} from '../filter/group';
import {Sort} from '../shared/models/sort';
import {Page} from '../shared/models/page';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService extends BaseService<Shipment, ShipmentList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'shipments' ;
    this.serializer = new ShipmentSerializer();
  }

  fetchPage(page: Page, group: Group[] = [], sort: Sort[] = [], fields: string = ''): Observable<ShipmentList> {
    const grp = JSON.parse(JSON.stringify(group));
    grp.forEach(g => {
      g.filter.forEach(f => {
        if (f.subject === 'preset') {
          if (f.object1) {
            const mapping = {starter: 0, cards_200: 1, cards_300: 2, cards_400: 3, replacement: 4};
            if (mapping[f.object1]) {
              f.object1 = mapping[f.object1];
            }
          }
        }
      });
    });
    const q = JSON.stringify(this.toRansackPage(grp, sort));
    let fieldProp = '';
    (fields.length === 0) ? fieldProp = '' : fieldProp = 'fields=' + fields + '&';
    return this.http
      .get<ShipmentList>(`${environment.base_url + this.endpoint}?${fieldProp}&per=999999&q=${q}`)
      .pipe(
        filter(x => !!x),
        take(1),
        map(res => {
          res.data = res.data.map(obj => this.serializer.fromJson(obj));
          return res;
        }));
  }

  ship(shipmentId: number): Observable<any> {
    return this.http.post(`${environment.base_url + this.endpoint}/${shipmentId}/ship`, null).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  print(shipmentId: number): Observable<any> {
    return this.http.post(`${environment.base_url + this.endpoint}/${shipmentId}/print`, null).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  email(shipmentId: number): Observable<any> {
    return this.http.post(`${environment.base_url + this.endpoint}/${shipmentId}/send_to_client`, null).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  byParcelNumber(parcelNumber: string): Observable<any> {
    return this.http.get(`${environment.base_url + this.endpoint}/by_parcel_number/${parcelNumber}`).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  typeToHuman(type: string) {
    switch (type) {
      case ShipmentTypes.delivery:
        return 'Lieferung';
      case ShipmentTypes.retoure:
        return 'Rücksendung';
    }
  }

  presetToHuman(preset: string) {
    switch (preset) {
      case ShipmentPresets.starter:
        return 'Mankido Starter Pack';
      case ShipmentPresets.cards_200:
        return '200 Mankido Karten';
      case ShipmentPresets.cards_300:
        return '300 Mankido Karten';
      case ShipmentPresets.cards_400:
        return '400 Mankido Karten';
      case ShipmentPresets.replacement:
        return 'Austauschgerät';
    }
  }

  weightFromPreset(preset: ShipmentPresets) {
    switch (preset) {
      case ShipmentPresets.starter:
        return 2.1;
      case ShipmentPresets.cards_200:
        return 1.0;
      case ShipmentPresets.cards_300:
        return 1.5;
      case ShipmentPresets.cards_400:
        return 2.0;
      case ShipmentPresets.replacement:
        return 2.0;
    }
  }

}
