import { Injectable } from '@angular/core';
import {NgForage} from 'ngforage';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PanelStateService {
  public panelStates = new BehaviorSubject<any>({ left: 'full', right: 'full'});

  constructor(private ngForage: NgForage) {
    this.ngForage.getItem('panelStates').then((value) => {
      if (value != null) {
        this.panelStates.next(value);
      }
    });
  }

  setState(side: string, state: string) {
    const states = this.panelStates.value;
    states[side] = state;
    this.panelStates.next(states);
    this.ngForage.setItem('panelStates', states);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  toggleState(side: string) {
    if (this.panelStates.value[side] === 'full') {
      this.setState(side, 'small');
    } else if (this.panelStates.value[side] === 'small') {
      this.setState(side, 'hidden');
    } else if (this.panelStates.value[side] === 'hidden') {
      this.setState(side, 'full');
    }
  }

}
