import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Partner } from '../partners/partner';
import { Device } from '../devices/device';

export class Invitation {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, standard: true}),
    // Associated Columns
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns}),
    new Column({name: 'Gerät', prop: 'device', association: 'belongs_to', columns: Device.allColumns}),
  ];

  // Simple Columns
  id: number;
  partner_id: number;
  device_id: number;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  // Associations
  partner: Partner;
  device: Device;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.partner_id.toString();
  }
}

export class InvitationList {
  count: number;
  data: Invitation[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
