import {RecurringEvent} from './recurring-event';

export class SpecialPointsEvent {
  id: number;
  partner_id: number;
  business_id: number;
  type: 'points_multiplier_event' | 'extra_points_event';
  requires_reservation: boolean;
  multi_usable: boolean;
  multiplier: number;
  extra_points: number;
  extra_points_threshold: number;
  status: 'draft' | 'active';

  recurring_events: Partial<RecurringEvent>[];

  constructor(v: Partial<SpecialPointsEvent> = {}) {
    Object.assign(this, v);
    if (v.recurring_events != null) {
      this.recurring_events = v.recurring_events.map((e) => new RecurringEvent(e));
    }
  }

  get active(): boolean {
    return this.status === 'active';
  }

  set active(isActive: boolean) {
    if (isActive) {
      this.status = 'active';
    } else {
      this.status = 'draft';
    }
  }

  valid(): boolean {
    return (this.extra_points != null || this.multiplier != null)
        && this.requires_reservation != null
        && !!this.recurring_events && this.recurring_events.length > 0
        && this.recurring_events[0].starts_at.getTime() < this.recurring_events[0].ends_at.getTime()
        && (this.recurring_events[0].rule !== 'singular' ||
            (this.recurring_events[0].starts_at.getTime() > new Date().getTime()
                && this.recurring_events[0].ends_at.getTime() > new Date().getTime()));
  }
}
