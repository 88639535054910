import { Component, forwardRef, Input } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PdfUploaderComponent),
    multi: true
  }]
})
export class PdfUploaderComponent implements ControlValueAccessor {

  file: File;

  @Input()
  imageType: 'logo' | 'wallpaper' | 'cover';

  allowedFileTypes = ['application/pdf'];

  isFile = false;

  public onTouched: () => void;
  public onChange: (value: File) => void;

  public onDragEnter($event: DragEvent) {
    const file = $event.dataTransfer.items[0];
    this.isFile = this.isAllowedFileType(file);
    this.onTouched();
  }

  public async onDrop($event: DndDropEvent) {
    $event.event.preventDefault();

    const file = $event.event.dataTransfer.files[0];
    await this.onFile(file);
  }

  public async onInputFile($event: any) {
    const file = $event.target.files[0];
    await this.onFile(file);
  }

  private async onFile(file: File) {
    if (!this.isAllowedFileType(file)) {
      console.log('File is not allowed', file);
      return;
    }

    this.file = file;
    this.onChange(this.file);
    this.onTouched();
  }

  private isAllowedFileType(file: { type: string }) {
    return file ? this.allowedFileTypes.includes(file.type) : false;
  }

  public writeValue(value: File): void {
    if (value) {
      this.file = value;
    }
  }

  public registerOnChange(fn: (value: File) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
