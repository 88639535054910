import { Injectable } from '@angular/core';
import {BaseService} from '../../../../base/base.service';
import {Bookmark, BookmarkList} from './bookmark';
import {HttpClient} from '@angular/common/http';
import {BookmarkSerializer} from './bookmark.serializer';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService extends BaseService<Bookmark, BookmarkList> {

  bookmarks = new BehaviorSubject<any>({
    edit: false,
    favorites: []
  });
  editMode = new BehaviorSubject(false);

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'bookmarks';
    this.serializer = new BookmarkSerializer();
    this.refetch();
  }

  toggleEdit() {
    this.editMode.next(!this.editMode.value);
  }

  refetch() {
    this.fetchAll().subscribe(res => {
      const tmp = {favorites: []};
      res.data.forEach(bookmark => {
        if (bookmark.favorite) {
          tmp.favorites.push(bookmark);
        } else {
          const base_path = bookmark.base_path.replace('/', '');
          tmp[base_path] ? tmp[base_path].push(bookmark) : tmp[base_path] = [bookmark];
        }
      });
      tmp['edit'] = this.bookmarks.value.edit;
      this.bookmarks.next(tmp);
    });
  }

  create(obj: Bookmark): Observable<Bookmark> {
    const tmp = this.bookmarks.value;
    if (obj.favorite) {
      tmp['favorites'] = [...tmp['favorites'], obj];
    } else {
      const base_path = obj.base_path.replace('/', '');
      tmp[base_path] ? tmp[base_path] = [...tmp[base_path], obj] : tmp[base_path] = [obj];
    }
    this.bookmarks.next(tmp);
    return super.create(obj).pipe(map(res => {
      obj.id = res.id;
      return res;
    }));
  }

  favorite(obj: Bookmark): Observable<Bookmark> {
    this.delete(obj.id);
    obj.favorite = !obj.favorite;
    this.create(obj);
    return this.update(obj.id, obj);
  }

  delete(id: number): Observable<Bookmark> {
    Object.keys(this.bookmarks.value).forEach((key) => {
      if (Array.isArray(this.bookmarks.value[key] )) {
        const matches = this.bookmarks.value[key].filter(x => x.id === id);
        if (matches.length > 0) {
          const i = this.bookmarks.value[key].indexOf(matches[0]);
          if (i > -1) {
            this.bookmarks.value[key].splice(i, 1);
          }
        }
      }
    });
    return super.delete(id);
  }
}
