import {DunningProcess} from './dunning_process';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DunningProcessSerializer extends BaseSerializer<DunningProcess> {
  fromJson(json: any): DunningProcess {
    const deserialized = new DunningProcess(json);
    if (json.client) {
      deserialized.client = new ClientSerializer().fromJson(json.client);
    }
    return deserialized;
  }

  toJson(obj: DunningProcess): string {
    let params: any = {dunning_process: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
