import {Filter} from './filter';
import * as rison from 'rison';
import {Predicate} from '../shared/enums/predicate.enum';
import {Column} from '../shared/models/column';

export class Group {
  m: string;
  filter: Filter[] = [];

  /**
   * Reformats a Group array to a usable object array for list service
   * @param {Array.<Group>} group
   * @return {Array.<Object>} q
   */
  static filterToUrl(group: Group[]): Object[] {
    const q: Object[] = []; // array of formatted objects that will be returned for list service
    for (let i = 0; i < group.length; i++) { // iterate through groups
      const g: Group = group[i];
      q[i] = {}; // create empty object at current position in q to fill
      q[i]['m'] = g.m; // set 'm' for current object position
      for (const f of g.filter) {
        // check if subject & predicate are val#
        if (f.subject !== undefined) {
          const isEmpty = (f.predicate === Predicate.NOT_EMPTY || f.predicate === Predicate.EMPTY);

          const subjectFilterPrefix = `${f.subject}_${Column.getPredicateString(f.predicate)}`;

          const subjectFilterPrefixCount = Object.keys(q[i]).filter(k => k.startsWith(subjectFilterPrefix)).length;

          // Check if filter property already exists to avoid duplicate keys getting overwritten
          if (subjectFilterPrefixCount > 0) {
            q[i][`${subjectFilterPrefix}_${subjectFilterPrefixCount}`] = isEmpty ? '1' : f.object1;
          } else {
            // if empty predicates, set object to 1 (ransack needs a value)
            q[i][subjectFilterPrefix] = isEmpty ? '1' : f.object1;
          }
        }
      }
    }
    return q;
  }

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class QuickFilter {
  active = false;
  standard = false;
  name: string;
  group: Group;
  groupRison: string;

  constructor(props) {
    Object.assign(this, props);
    this.recalculculateRison();
  }

  private recalculculateRison() {
    if (this.group) {
      this.groupRison = rison.encode(Group.filterToUrl([this.group]));
      if (this.groupRison && this.groupRison.length > 3) {
        this.groupRison = this.groupRison.substr(2, this.groupRison.length - 3);
      }
    }
  }
}
