import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Client } from '../clients/client';
import { Token } from '../tokens/token';
import { Email } from '../emails/email';

export class PersonalizedCardOrder {
  static PRINT_FINISHES = {
    'matt': 'Standard matt',
    'gold': 'Veredelung: Gold (angelegt als 100% Cyan Vollton)',
    'silver': 'Veredelung: Silber (angelegt als 100% Cyan Vollton)',
    'uv_lack': 'Veredelung: Partieller UV-Lack (angelegt als 100% Cyan Vollton)'
  };
  static PRINT_FINISH_OPTIONS = Object.values(PersonalizedCardOrder.PRINT_FINISHES);
  static DEFAULT_PRINT_FINISH = PersonalizedCardOrder.PRINT_FINISHES.matt;

  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: 'ID', prop: 'id', type: ColumnType.Number, flexGrow: 1, standard: true}),
    new Column({name: 'Status', prop: 'status', type: ColumnType.String, standard: true, flexGrow: 2}),
    new Column({name: 'Druckvorlage', prop: 'print_template', type: ColumnType.String, standard: false}),
    new Column({name: 'Anhang 1', prop: 'attachment_one', type: ColumnType.String, standard: false}),
    new Column({name: 'Anhang 2', prop: 'attachment_two', type: ColumnType.String, standard: false}),
    new Column({name: 'Firmenname', prop: 'company_name', flexGrow: 4, type: ColumnType.String, standard: true}),
    new Column({name: 'Kontaktperson', prop: 'contact_person', flexGrow: 4, type: ColumnType.String, standard: true}),
    new Column({name: 'Adresse', prop: 'address', type: ColumnType.String, standard: false}),
    new Column({name: 'ZIP', prop: 'zip', type: ColumnType.String}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String}),
    new Column({name: 'Land', prop: 'country', type: ColumnType.String}),
    new Column({name: 'Text', prop: 'text', type: ColumnType.String}),
    new Column({name: 'Anzahl', prop: 'quantity', type: ColumnType.Number}),
    new Column({name: 'Veredelung', prop: 'print_finish', type: ColumnType.String}),
    new Column({name: 'Erstallt', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'client', prop: 'client', association: 'belongs_to', columns: Client.allColumns}),
  ];

  // Simple Columns
  id: number;
  client_id: number;
  status: string;
  print_template: { url: string, file: File, identifier: string };
  attachment_one: { url: string, file: File, identifier: string };
  attachment_two: { url: string, file: File, identifier: string };
  company_name: string;
  contact_person: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  text: string;
  quantity: number;
  print_finish: string;
  created_at: boolean;
  updated_at: boolean;
  deleted_at: boolean;
  // Associations
  client: Client;
  tokens: Token[];
  emails: Email[];

  constructor(values: Partial<PersonalizedCardOrder> = {}) {
    this.id = values.id;
    this.status = values.status;
    this.client_id = values.client_id;
    this.print_template = values.print_template;
    this.attachment_one = values.attachment_one;
    this.attachment_two = values.attachment_two;
    this.company_name = values.company_name;
    this.contact_person = values.contact_person;
    this.address = values.address;
    this.zip = values.zip;
    this.city = values.city;
    this.country = values.country;
    this.text = values.text;
    this.quantity = values.quantity;
    this.print_finish = values.print_finish;

    this.client = values.client;
    this.tokens = values.tokens;
    this.emails = values.emails;


    this.created_at = values.created_at;
    this.updated_at = values.updated_at;
    this.deleted_at = values.deleted_at;
  }

  humanize() {
    return this.client_id;
  }

  emailSubject(): string {
    return `Kartenbestellung: ${this.company_name || this.contact_person || '[Firmenname/Kontaktperson]'}`;
  }

  humanStatus() {
    // enum :status, [:created, :prepared, :sent, :delivered, :delivery_failed, :token_generation_failed]
    switch (this.status) {
      case 'created':
        return 'Erstellt';
      case 'prepared':
        return 'Vorbereitet';
      case 'sent':
        return 'Gesendet';
      case 'delivered':
        return 'Geliefert';
      case 'delivery_failed':
        return 'Versand fehlgeschlagen';
      case 'token_generation_failed':
        return 'Token Generierung fehlgeschlagen';
      default:
        return this.status;
    }
  }
}

export class PersonalizedCardOrderList {
  count: number;
  data: PersonalizedCardOrder[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
