import {User} from '../users/user';
import {DealAutomation} from '../deal_automations/deal_automation';
import {Membership} from '../memberships/membership';

export class Automation {
  user: User;
  type: AutomationType;
  deal_automations: DealAutomation[];
  aes: number; // Automations received since last 30 days
  sent: boolean;

  // Optional, returned only if membership is present
  membership?: Membership;
  visits = 0;
  atg = 0;
  elapsed = 0;
  deltas = [0, 0];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export enum AutomationType {
  birthday =    'Automations::BirthdayAutomation',
  loyalty =     'Automations::LoyaltyAutomation',
  risk =        'Automations::AtRiskAutomation',
  lapsed =      'Automations::LapsedAutomation',
  lost =        'Automations::LostAutomation',
  network =     'Automations::NetworkAutomation',
  firstvisit =  'Automations::FirstVisitAutomation',
  growth =      'Automations::GrowthAutomation',
  inactive =    'Automations::InactivityAutomation'
}
