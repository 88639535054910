import { Injectable } from '@angular/core';
import {Client, ClientList} from './client';
import {BaseService} from '../base/base.service';
import {ClientSerializer} from 'src/app/clients/client.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {filter, map, take} from 'rxjs/operators';
import {InvoiceSerializer} from '../invoices/invoice.serializer';
import {Observable} from 'rxjs';
import {Invoice} from '../invoices/invoice';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService<Client, ClientList> {

  constructor(http: HttpClient, private invoiceSerializer: InvoiceSerializer) {
    super(http);
    this.endpoint = 'clients' ;
    this.serializer = new ClientSerializer();
  }

  fetchNextInvoice(id: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.base_url + this.endpoint}/${id}/next_invoice`).pipe(
      filter(x => !!x),
      take(1),
      map((res) => this.invoiceSerializer.fromJson(res))
    );
  }

  send_welcome_mail(id: number) {
    return this.http.post<void>(`${environment.base_url + this.endpoint}/${id}/send_welcome_mail`, null);
  }

}
