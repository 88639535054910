import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { User } from '../users/user';

export class Activity {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Aktion', prop: 'action', type: ColumnType.String, standard: true, order: 2, flexGrow: .4}),
    new Column({name: 'Daten', prop: 'data', type: ColumnType.String}),
    new Column({name: 'Objekt', prop: 'object_data', type: ColumnType.Polymorphic, standard: true, order: 3, flexGrow: .5}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true, order: 4, flexGrow: .5}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Benutzer', prop: 'user', association: 'belongs_to', columns: User.allColumns,
      standard: true, standardProps: 'email', order: 1}),
  ];

  // Simple Columns
  id: number;
  user_id: number;
  action: string;
  data: string;
  created_at: boolean;
  deleted_at: boolean;
  // Associations
  user: User;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.data;
  }
}

export class ActivityList {
  count: number;
  data: Activity[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
