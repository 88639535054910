<nav class="navbar navbar-dark bg-dark flex-md-nowrap flex-grow-1 p-0">
  <img (click)="panelState.toggleState('left'); false;" alt="" height="60" src="/assets/mankido.svg" style="margin: 0 !important; height: 35px; width: 50px;" width="60">
  <a class="navbar-brand" *ngIf="panelState.panelStates.value.left == 'full'" href (click)="panelState.toggleState('left'); false;" style="width: 150px; flex-shrink: 0; margin-right: 0;">Mankido Admin</a>
  <app-search *ngIf="searchShown" class="w-100"></app-search>
  <ul class="navbar-nav px-3">
    <li class="nav-item dropdown text-nowrap" *ngIf="admin?.id" dropdown container="body" placement="bottom right">
      <a class="nav-link dropdown-toggle" dropdownToggle id="userMenu" aria-controls="userDropdown" aria-haspopup="true" aria-expanded="false">
        {{admin.name ? admin.name : admin.email}}
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="userMenu" role="menu" id="userDropdown">
        <a class="dropdown-item" (click)="authService.logout()" href="#">Abmelden <i class="fa fa-sign-out"></i></a>
      </div>
    </li>
  </ul>
</nav>
