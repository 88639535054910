import {PosSession} from './pos_session';
import {BaseSerializer} from '../base/base.serializer';
import {TerminalSerializer} from '../terminals/terminal.serializer';
import {BusinessSerializer} from '../businesses/business.serializer';
import {TokenSerializer} from '../tokens/token.serializer';
import {UserSerializer} from '../users/user.serializer';
import {MembershipSerializer} from '../memberships/membership.serializer';

export class PosSessionSerializer extends BaseSerializer<PosSession> {
  fromJson(json: any): PosSession {
    const serialized = new PosSession(json);
    if (json.terminal) {
      serialized.terminal = new TerminalSerializer().fromJson(json.terminal);
    }
    if (json.business) {
      serialized.business = new BusinessSerializer().fromJson(json.business);
    }
    if (json.token) {
      serialized.token = new TokenSerializer().fromJson(json.token);
    }
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    if (json.membership) {
      serialized.membership = new MembershipSerializer().fromJson(json.membership);
    }
    return serialized;
  }

  toJson(obj: PosSession): string {
    let params: any = {pos_session: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
