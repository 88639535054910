import {User} from './user';
import {BaseSerializer} from '../base/base.serializer';

export class UserSerializer extends BaseSerializer<User> {
  fromJson(json: any): User {
    const serialized = new User(json);
    return serialized;
  }

  toJson(obj: User): string {
    let params: any = {user: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
