import {MerchantAccess} from './merchant-access';
import {BaseSerializer} from '../base/base.serializer';
import {MerchantSerializer} from '../merchants/merchant.serializer';

export class MerchantAccessSerializer extends BaseSerializer<MerchantAccess> {
  fromJson(json: any): MerchantAccess {
    const serialized = new MerchantAccess(json);
    if (json.merchant) {
      serialized.merchant = new MerchantSerializer().fromJson(json.merchant);
    }
    return serialized;
  }

  toJson(obj: MerchantAccess): string {
    let params: any = {merchant_access: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
