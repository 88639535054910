import {MessageStat} from './message_stat';
import {BaseSerializer} from '../base/base.serializer';

export class MessageStatSerializer extends BaseSerializer<MessageStat> {
  fromJson(json: any): MessageStat {
    const serialized = new MessageStat(json);
    return serialized;
  }

  toJson(obj: MessageStat): string {
    let params: any = {message_stat: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
