import { Component, OnInit } from '@angular/core';
import { ColumnType } from '../../shared/enums/column-type.enum';
import { PersonalizedCardOrder } from '../personalized-card-order';
import { Sort } from '../../shared/models/sort';
import { QuickFilter } from '../../filter/group';

@Component({
  selector: 'app-personalized-card-order-list',
  templateUrl: './personalized-card-order-list.component.html',
  styleUrls: ['./personalized-card-order-list.component.scss']
})
export class PersonalizedCardOrderListComponent implements OnInit {

  columnTypes = ColumnType;

  allColumns = PersonalizedCardOrder.allColumns;

  quickFilters = this.buildQuickFilter();
  defaultSort: Sort[] = [{name: 'id', dir: 'desc'}];

  constructor() { }

  ngOnInit() {
  }

  private buildQuickFilter(): QuickFilter[] {
    return [];
  }

}
