import {Reward} from './reward';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class RewardSerializer extends BaseSerializer<Reward> {
  fromJson(json: any): Reward {
    const serialized = new Reward(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: Reward): string {
    let params: any = {reward: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
