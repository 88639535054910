import { Injectable } from '@angular/core';
import {MobileSession, MobileSessionList} from './mobile_session';
import {BaseService} from '../base/base.service';
import {MobileSessionSerializer} from 'src/app/mobile_sessions/mobile_session.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MobileSessionService extends BaseService<MobileSession, MobileSessionList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'mobile_sessions' ;
    this.serializer = new MobileSessionSerializer();
  }
}
