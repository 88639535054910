import {AddonSubscription} from './addon_subscription';
import {BaseSerializer} from '../base/base.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class AddonSubscriptionSerializer extends BaseSerializer<AddonSubscription> {
  fromJson(json: any): AddonSubscription {
    const serialized = new AddonSubscription(json);
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: AddonSubscription): string {
    let params: any = {addon_subscription: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
