import {ClientDocument} from './client_document';
import {BaseSerializer} from '../base/base.serializer';
import {ClientSerializer} from '../clients/client.serializer';

export class ClientDocumentSerializer extends BaseSerializer<ClientDocument> {
  fromJson(json: any): ClientDocument {
    const serialized = new ClientDocument(json);
    if (json.client) {
      serialized.client = new ClientSerializer().fromJson(json.client);
    }
    return serialized;
  }

  toJson(obj: ClientDocument): string {
    let params: any = {client_document: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
