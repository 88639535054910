import { Injectable } from '@angular/core';
import {AffiliateDeal, AffiliateDealList} from './affiliate_deal';
import {BaseService} from '../base/base.service';
import {AffiliateDealSerializer} from 'src/app/affiliate_deals/affiliate_deal.serializer';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AffiliateDealService extends BaseService<AffiliateDeal, AffiliateDealList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'affiliate_deals' ;
    this.serializer = new AffiliateDealSerializer();
  }

  public uploadCover(id: number, file: File): Observable<AffiliateDeal> {
    const formData = new FormData();
    formData.append('affiliate_deal[cover]', file);
    return this.http.put<AffiliateDeal>(`${environment.base_url + this.endpoint}/${id}`, formData);
  }
}
