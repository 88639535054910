import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Category } from '../categories/category';
import { Partner } from '../partners/partner';

export class Categorization {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'weight', prop: 'weight', type: ColumnType.Number, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Kategorie', prop: 'category', association: 'belongs_to', columns: Category.allColumns}),
    new Column({name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns}),
  ];

  // Simple Columns
  id: number;
  weight: number;
  category_id: number;
  partner_id: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  category: Category;
  partner: Partner;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.weight;
  }
}

export class CategorizationList {
  count: number;
  data: Categorization[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
