import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';

export class TransactionFlag {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number}),
    new Column({name: 'Grund', prop: 'reason', type: ColumnType.String}),
    new Column({name: 'score', prop: 'score', type: ColumnType.Number}),
    new Column({name: 'flagged_by', prop: 'flagged_by', type: ColumnType.String}),
    new Column({name: 'decision', prop: 'decision', type: ColumnType.Number}),
    new Column({name: 'decided_at', prop: 'decided_at', type: ColumnType.DateTime}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
  ];

  // Simple Columns
  id: number;
  transaction_id: number;
  reason: string;
  score: number;
  flagged_by: string;
  decision: number;
  decided_at: boolean;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.transaction_id;
  }
}

export class TransactionFlagList {
  count: number;
  data: TransactionFlag[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
