import { Injectable } from '@angular/core';
import {Email, EmailList} from './email';
import {BaseService} from '../base/base.service';
import {EmailSerializer} from 'src/app/emails/email.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseService<Email, EmailList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'emails' ;
    this.serializer = new EmailSerializer();
  }
}
