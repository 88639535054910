import {WebshopRequest} from './webshop_request';
import {BaseSerializer} from '../base/base.serializer';
import {WebshopSerializer} from '../webshops/webshop.serializer';

export class WebshopRequestSerializer extends BaseSerializer<WebshopRequest> {
  fromJson(json: any): WebshopRequest {
    const serialized = new WebshopRequest(json);
    if (json.webshop) {
      serialized.webshop = new WebshopSerializer().fromJson(json.webshop);
    }
    return serialized;
  }

  toJson(obj: WebshopRequest): string {
    let params: any = {webshop_request: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
