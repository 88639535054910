import { Injectable } from '@angular/core';
import { Business, BusinessList, BusinessStats } from './business';
import { BaseService } from '../base/base.service';
import { BusinessSerializer } from 'src/app/businesses/business.serializer';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { filter, map, take } from 'rxjs/operators';
import { Group } from '../filter/group';
import { Observable, Subject } from 'rxjs';
import { MerchantList } from '../merchants/merchant';
import { MerchantSerializer } from '../merchants/merchant.serializer';

@Injectable({
  providedIn: 'root'
})
export class BusinessService extends BaseService<Business, BusinessList> {

  private onIgnoreBusinessSubject: Subject<number> = new Subject<number>();

  constructor(http: HttpClient,
              private merchantSerializer: MerchantSerializer) {
    super(http);
    this.endpoint = 'businesses';
    this.serializer = new BusinessSerializer();
  }

  fetchMerchantCenter(id: number) {
    return this.http.get(`${environment.base_url + this.endpoint}/${id}/merchantcenter_token`).pipe(
      filter(x => !!x),
      take(1),
      map((res: any) => res)
    );
  }

  fetchMap(group: Group[]): Observable<BusinessList> {
    const q = JSON.stringify(this.toRansackMap(group)).replace(/_([0-9]+)/, '');
    const fields = [
      'id', 'name', 'partner.name', 'address', 'zip', 'city', 'phone', 'contact', 'lng', 'lat',
      'visible_radius', 'published_at'
    ];
    return this.http.get<BusinessList>(
      `${environment.base_url + this.endpoint}?page=1&per=1000&fields=${fields.join(',')}&q=${q}`)
      .pipe(
        filter(x => !!x),
        take(1),
      );
  }

  fetchMerchants(id: number): Observable<MerchantList> {
    return this.http.get<MerchantList>(`${environment.base_url + this.endpoint}/${id}/merchants`)
      .pipe(
        filter(x => !!x),
        take(1),
        map((res: MerchantList) => {
          res.data.map(x => this.merchantSerializer.fromJson(x));
          return res;
        })
      );
  }

  updateTerminals(id: number): Observable<{ terminals_informed: number }> {
    return this.http.post<{ terminals_informed: number }>(`${environment.base_url + this.endpoint}/${id}/update_terminals`, null);
  }

  publish(id: number): Observable<Business> {
    return this.http.put(`${environment.base_url + this.endpoint}/${id}/publish`, null).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  hide(id: number): Observable<Business> {
    return this.http.put(`${environment.base_url + this.endpoint}/${id}/hide`, null).pipe(
      filter(x => !!x),
      take(1),
      map(res => this.serializer.fromJson(res))
    );
  }

  stats(id: number): Observable<BusinessStats> {
    return this.http.get(`${environment.base_url + this.endpoint}/${id}/stats`).pipe(
      map(res => new BusinessStats((res as { stats: BusinessStats }).stats))
    );
  }

  ignore_care_warning(id: number, period: number, unit: string): Observable<Date> {
    return this.http.put(`${environment.base_url + this.endpoint}/${id}/ignore_care_warning`, {
      ignore_until: {
        unit: unit,
        period: period
      }
    }).pipe(
      map(res => {
        this.onIgnoreBusinessSubject.next(id);
        return (res as any).ignore_care_warning_until;
      })
    );
  }

  requestPipedriveExport(): Observable<void> {
    return this.http.post<void>(`${environment.base_url + this.endpoint}/request_pipedrive_export`, {});
  }

  get onIgnoreBusiness(): Observable<number> {
    return this.onIgnoreBusinessSubject.asObservable();
  }

}
