import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../auth/auth.service';
import {Administrator} from '../../../administrators/administrator';
import {AdministratorService} from '../../../administrators/administrator.service';
import {PanelStateService} from '../panel-state.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  admin: Administrator;
  searchShown: boolean = false;

  constructor(public authService: AuthService,
              public adminService: AdministratorService,
              public panelState: PanelStateService) { }

  ngOnInit() {
    this.adminService.currentAdministrator.subscribe(admin => this.admin = admin);
    this.searchShown = !window.location.pathname.includes('/serial_number_monitor');
  }
}
