import { Injectable } from '@angular/core';
import {Tablet, TabletList} from './tablet';
import {BaseService} from '../base/base.service';
import {TabletSerializer} from 'src/app/tablets/tablet.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TabletService extends BaseService<Tablet, TabletList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'tablets' ;
    this.serializer = new TabletSerializer();
  }

  assign(terminal_id: number, administrator_id?: number, business_id?: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${terminal_id}/assign`, {
      business_id: business_id ? business_id : null,
      administrator_id: administrator_id ? administrator_id : null
    }).pipe(
      map(res => this.serializer.fromJson(res))
    );
  }

  search(serial_number: string): Observable<TabletList> {
    return this.http
      .get<any>(`${environment.base_url + this.endpoint}/search/${serial_number}`)
      .pipe(
        map(res => {
          return res.tablets.map(obj => this.serializer.fromJson(obj));
        })
      );
  }

  byUuid(uuid: string) {
    return this.http.get(`${environment.base_url + this.endpoint}/by_uuid/${uuid}`).pipe(
      map(res => this.serializer.fromJson(res))
    );
  }

  unlockSetup(tablet_id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${tablet_id}/unlock_setup`, {});
  }

  print(tablet_id: number) {
    return this.http.post(`${environment.base_url + this.endpoint}/${tablet_id}/print`, {});
  }
}
