import {BaseSerializer} from '../base/base.serializer';
import {Administrator} from './administrator';

export class AdminSerializer extends BaseSerializer<Administrator> {
  fromJson(json: any): Administrator {
    return new Administrator(json);
  }

  toJson(obj: Administrator): string {
    let params: any = {administrator: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
