import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Column } from '../../../shared/models/column';
import { Group } from '../../../filter/group';
import { UrlService } from '../../url/url.service';
import { TreeviewConfig } from 'ngx-treeview';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-controls',
  templateUrl: './list-controls.component.html',
  styleUrls: ['./list-controls.component.scss']
})
export class ListControlsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input() showcolumns = true;

  @Input() all: Column[];
  @Input() group?: Group[];

  tree = [];

  currentColumns: string;
  selectedColumnsCount = 0;

  treeviewConfig: TreeviewConfig = {
    hasAllCheckBox: true,
    decoupleChildFromParent: true,
    hasCollapseExpand: true,
    maxHeight: 400,
    hasFilter: false,
    hasDivider: false
  };


  constructor(private route: ActivatedRoute, private urlService: UrlService) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe),
      tap(params => {
      this.currentColumns = this.urlService.urlToColumnString(params, this.all);
      this.tree = this.urlService.urlToColumnsTree(params, this.all);
      this.group = this.urlService.urlToFilter(params, this.all);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get filterCount() {
    let counter = 0;
    this.group.map(el => counter += el.filter.length);
    return counter;
  }

  onColumnsChange($event) {
    this.urlService.columnsToUrl($event, this.currentColumns);
    this.selectedColumnsCount = $event.length;
  }
}
