<div class="row h-100">
  <div class="col-4 col-lg-3 d-flex flex-column h-100">
    <app-personalized-card-order-list></app-personalized-card-order-list>
  </div>
  <div class="col-8 col-lg-9" style="height: 100%; overflow: scroll;">
    <router-outlet></router-outlet>
  </div>
</div>

<app-right-panel-content>
  <button class="btn btn-outline-primary w-100" [routerLink]="['new']">Neue Bestellung</button>
</app-right-panel-content>
