import {Campaign} from './campaign';
import {BaseSerializer} from '../base/base.serializer';
import {AudienceDefinitionSerializer} from '../audience_definitions/audience_definition.serializer';
import {MessageStatSerializer} from '../message_stats/message_stat.serializer';

export class CampaignSerializer extends BaseSerializer<Campaign> {
  fromJson(json: any): Campaign {
    const serialized = new Campaign(json);
    if (json.audience_definition) {
      serialized.audience_definition = new AudienceDefinitionSerializer().fromJson(json.audience_definition);
    }
    if (json.message_stat) {
      serialized.message_stat = new MessageStatSerializer().fromJson(json.message_stat);
    }
    return serialized;
  }

  toJson(campaign: Campaign): string {
    let params: any = {campaign: campaign};
    params = JSON.parse(JSON.stringify(params));
    params.campaign.audience_definition_attributes = params.campaign.audience_definition;
    delete params.campaign.audience_definition;
    params.campaign.content_elements_attributes = [];
    for (let i = 0; i < campaign.content_elements.length; i++) {
      const ce: any = params.campaign.content_elements[i];
      ce.position = i + 1;
      if (ce.asset) {
        ce.asset_id = ce.asset.id;
        delete ce.asset;
      }
      if (ce.deal) {
        ce.deal_attributes = ce.deal;
        if (ce.deal.asset) {
          ce.deal.asset_id = ce.deal.asset.id;
          delete ce.deal.asset;
        }
        delete ce.deal;
      }
      if (ce.special_points_event) {
        ce.special_points_event_attributes = ce.special_points_event;
        ce.special_points_event_attributes.recurring_events_attributes = ce.special_points_event.recurring_events;
        delete ce.special_points_event;
        delete ce.special_points_event_attributes.recurring_events;
      }
      params.campaign.content_elements_attributes.push(ce);
    }
    delete params.campaign.content_elements;
    delete params.campaign.special_points_event;
    delete params.campaign.deal;
    return params;
  }
}
