import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavItemComponent } from '../../navigation/nav-item/nav-item.component';
import { Bookmark } from '../bookmark';
import { BookmarkService } from '../bookmark.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkComponent extends NavItemComponent {
  private _bookmark: Bookmark;

  @Input('bookmark')
  set bookmark(value: Bookmark) {
    this._bookmark = value;
    this.name = this._bookmark.name;
    this.target = this.sanitizer.bypassSecurityTrustUrl(this._bookmark.path);
  }

  get bookmark() {
    return this._bookmark;
  }

  info = false;

  constructor(private bookmarkService: BookmarkService,
              private toastr: ToastrService,
              private sanitizer: DomSanitizer,
              private router: Router) {
    super();
  }

  favorite() {
    this.bookmarkService.favorite(this.bookmark).subscribe(() => {
    }, (err) => {
      this.toastr.error('Fav fehlgeschlagen\n' + err, 'Fehler!');
    });
  }

  clickDeleteBookmark() {
    if (!this.info) {
      this.toastr.info('Doppelklicke auf das Müll-Symbol, um ein Lesezeichen zu löschen.', 'Löschen');
      this.info = true;
      setTimeout(() => {
        this.info = false;
      }, 5000);
    }
  }

  onEditBookmark() {
    this.bookmarkService.update(this.bookmark.id, {name: this.bookmark.name} as Bookmark).subscribe(() => {
      this.toastr.success('Deine Änderungen wurden gespeichert', 'Speichern');
    });
  }

  deleteBookmark() {
    this.toastr.warning('Lösche Lesezeichen...', 'Lesezeichen löschen');
    this.bookmarkService.delete(this.bookmark.id).subscribe(() => {
      this.toastr.success('Du hast das Lesezeichen erfolgreich gelöscht', 'Löschen');
    });
  }

  open(event) {
    event.preventDefault();
    this.router.navigateByUrl(this.bookmark.path);
  }
}
