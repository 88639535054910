import {Injectable} from '@angular/core';
import {DealAutomation, DealAutomationList} from './deal_automation';
import {BaseService} from '../base/base.service';
import {DealAutomationSerializer} from 'src/app/deal_automations/deal_automation.serializer';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, take} from 'rxjs/operators';
import {AutomationSerializer} from '../automations/automation.serializer';
import {Observable} from 'rxjs';
import {Automation} from '../automations/automation';

@Injectable({
  providedIn: 'root'
})
export class DealAutomationService extends BaseService<DealAutomation, DealAutomationList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'deal_automations';
    this.serializer = new DealAutomationSerializer();
  }

  deliver(deal_automation_ids: number[], user_id: number) {
    return this.http.post(
      `${environment.base_url + this.endpoint}/deliver`,
      {deal_automation: {deal_automation_ids: deal_automation_ids, user_id: user_id}})
      .pipe(take(1));
  }

  candidates(): Observable<Automation[]> {
    return this.http.get<Automation[]>(`${environment.base_url + this.endpoint}/candidates`).pipe(
      map(automations => {
        return automations.map(x => new AutomationSerializer().fromJson(x));
      })
    );
  }
}
