import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Business } from '../businesses/business';
import { Asset } from '../assets/asset';

export class BusinessPhoto {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'position', prop: 'position', type: ColumnType.Number, standard: true}),
    new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, standard: true}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'Standort', prop: 'business', association: 'belongs_to', columns: Business.allColumns}),
    new Column({name: 'asset', prop: 'asset', association: 'belongs_to', columns: Asset.allColumns}),
  ];

  // Simple Columns
  id: number;
  business_id: number;
  asset_id: number;
  position: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  business: Business;
  asset: Asset;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.business && this.business.name ? this.business.name : this.id;
  }
}

export class BusinessPhotoList {
  count: number;
  data: BusinessPhoto[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
