import {Category} from './category';
import {BaseSerializer} from '../base/base.serializer';

export class CategorySerializer extends BaseSerializer<Category> {
  fromJson(json: any): Category {
    const serialized = new Category(json);
    return serialized;
  }

  toJson(obj: Category): string {
    let params: any = {category: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
