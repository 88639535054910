import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
// import { Campaign } from '../campaigns/campaign';
// import { DealAutomation } from '../deal_automations/deal_automation';
import {Message} from '../messages/message';
// import { Email } from '../emails/email';
import {EmailEvent} from '../email_events/email_event';

export class MessageStat {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'emails_count', prop: 'emails_count', type: ColumnType.Number, standard: true}),
    new Column({name: 'email_events_count', prop: 'email_events_count', type: ColumnType.Number, standard: true}),
    new Column({name: 'email_processed_count', prop: 'email_processed_count', type: ColumnType.Number, standard: true}),
    new Column({name: 'email_dropped_count', prop: 'email_dropped_count', type: ColumnType.Number, standard: true}),
    new Column({name: 'email_delivered_count', prop: 'email_delivered_count', type: ColumnType.Number, flexGrow: .2}),
    new Column({name: 'email_deferred_count', prop: 'email_deferred_count', type: ColumnType.Number}),
    new Column({name: 'email_bounce_count', prop: 'email_bounce_count', type: ColumnType.Number}),
    new Column({name: 'Opens', prop: 'email_open_count', type: ColumnType.Number, flexGrow: .2}),
    new Column({name: 'Clicks', prop: 'email_click_count', type: ColumnType.Number, flexGrow: .2}),
    new Column({name: 'email_spamreport_count', prop: 'email_spamreport_count', type: ColumnType.Number}),
    new Column({name: 'email_unsubscribe_count', prop: 'email_unsubscribe_count', type: ColumnType.Number}),
    new Column({name: 'email_group_unsubscribe_count', prop: 'email_group_unsubscribe_count', type: ColumnType.Number}),
    new Column({name: 'email_group_resubscribe_count', prop: 'email_group_resubscribe_count', type: ColumnType.Number}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime}),
    new Column({name: 'messages_count', prop: 'messages_count', type: ColumnType.Number, flexGrow: .2}),
    new Column({name: 'mobile_messages_count', prop: 'mobile_messages_count', type: ColumnType.Number}),
    // Associated Columns
    // new Column({name: 'Kampagne', prop: 'campaign', association: 'has_one', columns: Campaign.allColumns}),
    // new Column({name: 'Dealautomatisierung', prop: 'deal_automation', association: 'has_one', columns: DealAutomation.allColumns}),
  ];

  // Simple Columns
  id: number;
  emails_count: number;
  email_events_count: number;
  email_processed_count: number;
  email_dropped_count: number;
  email_delivered_count: number;
  email_deferred_count: number;
  email_bounce_count: number;
  email_open_count: number;
  email_click_count: number;
  email_spamreport_count: number;
  email_unsubscribe_count: number;
  email_group_unsubscribe_count: number;
  email_group_resubscribe_count: number;
  created_at: boolean;
  updated_at: boolean;
  messages_count: number;
  mobile_messages_count: number;
  visiting_users_after_open: {email: number, mobile: number, total: number};
  // Associations
  // campaign: Campaign;
  // deal_automation: DealAutomation;
  message: Message[];
  // email: Email[];
  email_event: EmailEvent[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.emails_count;
  }
}

export class MessageStatList {
  count: number;
  data: MessageStat[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
