import {Client} from './client';
import {BaseSerializer} from '../base/base.serializer';

export class ClientSerializer extends BaseSerializer<Client> {
  fromJson(json: any): Client {
    const serialized = new Client(json);
    return serialized;
  }

  toJson(obj: Client): string {
    let params: any = {client: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
