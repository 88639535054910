import {Membership} from './membership';
import {BaseSerializer} from '../base/base.serializer';
import {UserSerializer} from '../users/user.serializer';
import {PartnerSerializer} from '../partners/partner.serializer';

export class MembershipSerializer extends BaseSerializer<Membership> {
  fromJson(json: any): Membership {
    const serialized = new Membership(json);
    if (json.user) {
      serialized.user = new UserSerializer().fromJson(json.user);
    }
    if (json.partner) {
      serialized.partner = new PartnerSerializer().fromJson(json.partner);
    }
    return serialized;
  }

  toJson(obj: Membership): string {
    let params: any = {membership: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
