import {DeviceLocation} from './device_location';
import {BaseSerializer} from '../base/base.serializer';
import {DeviceSerializer} from '../devices/device.serializer';
import {AreaSerializer} from '../areas/area.serializer';

export class DeviceLocationSerializer extends BaseSerializer<DeviceLocation> {
  fromJson(json: any): DeviceLocation {
    const serialized = new DeviceLocation(json);
    if (json.device) {
      serialized.device = new DeviceSerializer().fromJson(json.device);
    }
    if (json.area) {
      serialized.area = new AreaSerializer().fromJson(json.area);
    }
    return serialized;
  }

  toJson(obj: DeviceLocation): string {
    let params: any = {device_location: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
