import { Injectable } from '@angular/core';
import {Teaser, TeaserList} from './teaser';
import {BaseService} from '../base/base.service';
import {TeaserSerializer} from 'src/app/teasers/teaser.serializer';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeaserService extends BaseService<Teaser, TeaserList> {

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'teasers' ;
    this.serializer = new TeaserSerializer();
  }
}
