import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import { Webshop } from '../webshops/webshop';
import { Membership } from '../memberships/membership';
import { Transaction } from '../transactions/transaction';

export class WebshopRequest {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: 'id', prop: 'id', type: ColumnType.Number, standard: true}),
    new Column({name: 'status', prop: 'status', type: ColumnType.Number, standard: true}),
    new Column({name: 'api_key', prop: 'api_key', type: ColumnType.String, standard: true}),
    new Column({name: 'flavor', prop: 'flavor', type: ColumnType.String, standard: true}),
    new Column({name: 'body', prop: 'body', type: ColumnType.String, standard: true}),
    new Column({name: 'order_status', prop: 'order_status', type: ColumnType.Number}),
    new Column({name: 'email', prop: 'email', type: ColumnType.String}),
    new Column({name: 'amount', prop: 'amount', type: ColumnType.Number}),
    new Column({name: 'deleted_at', prop: 'deleted_at', type: ColumnType.DateTime}),
    new Column({name: 'created_at', prop: 'created_at', type: ColumnType.DateTime}),
    new Column({name: 'updated_at', prop: 'updated_at', type: ColumnType.DateTime}),
    // Associated Columns
    new Column({name: 'webshop', prop: 'webshop', association: 'belongs_to', columns: Webshop.allColumns}),
  ];

  // Simple Columns
  id: number;
  status: number;
  api_key: string;
  webshop_id: number;
  flavor: string;
  body: string;
  order_status: number;
  unique_order_id: number;
  email: string;
  amount: number;
  membership_id: number;
  transaction_id: number;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  // Associations
  webshop: Webshop;
  memberships: Membership[];
  derived_transactions: Transaction[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  humanize() {
    return this.status;
  }
}

export class WebshopRequestList {
  count: number;
  data: WebshopRequest[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
